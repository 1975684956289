.main-career {
}

.career-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.career-item {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 0 15px 30px;
  background-color: $krona-gray--light;

  &-content {
    padding: 15px;
    display: flex;
    flex-direction: column;
  }

  &-image {
    @include bg--cover;
    background-color: $krona-green;
    width: 100%;
    height: 255px;
  }

  &-title {
    @include PTSansNarrow-font;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &-text {
    font-size: 14px;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &-link {
    font-size: 14px;
    width: 180px;
    color: white;
    @include PTSansNarrow-font;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.career-text {
  margin-bottom: 10px;
  line-height: 1.2;
  font-size: 18px;
  line-height: 1.7;
}

.career-features {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 20px;

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 250px;
    margin: 0 10px 20px;

    &-icon {
      width: 164px;
      height: 164px;
      background-color: $krona-gray--dark;
      margin-bottom: 20px;
    }

    &-title {
      font-size: 14px;
      text-align: center;
      line-height: 1.5;
    }
  }
}

.page-form {
  display: flex;
  margin-top: 40px;

  &-block {
    display: flex;
    flex-direction: column;
    width: calc(50% - 10px);
    margin-right: auto;

    &:last-child {
      margin-right: 0;
      align-items: flex-end;
    }
  }

  &-input {
    padding: 11px 20px 9px;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    font-size: 15px;
    border: none;
    margin-bottom: 20px;
    width: 100%;

    &::placeholder {
      color: $text-gray--light;
    }
  }

  .btn {
    @include PTSansNarrow-font;
    font-weight: bold;
    width: 120px;
  }
}
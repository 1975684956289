.search-btn {
  @include bg--contain;
  background-size: 16px;
  width: 16px;
  height: 16px;
  background-image: url('../assets/icons/components/search/search-white.svg');
  background-color: transparent;
  margin: 0 20px 0 10px;
  padding: 11px 15px;
  cursor: pointer;
  transition-duration: .3s;
  height: 43px;
  width: 43px;

  @include for-980 {
    margin: 0 10px;
    padding: 11px;
    height: 33px;
    width: 23px;
  }

  // @include for-400 {
  //   margin: 0 10px;
  //   padding: 11px;
  //   height: 33px;
  //   width: 23px;
  // }

  &:hover {
    transition-duration: .3s;
    box-shadow: 0 0 12.6px 8.4px rgb(79 210 98 / 30%);
    border-color: rgba(79, 210, 98, .3);

    @include for-768 {
      box-shadow: none;
    }
  }
}

#searchOnSite {
  color: white;
  background-color: rgba($color: black, $alpha: 0.777);

  .modal-dialog {
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 20px 0;
    overflow-y: scroll;
  }

  .modal-content {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    position: relative;

    @media screen and (max-width: 980px) {
      max-width: 600px;
    }

    @media screen and (max-width: 768px) {
      justify-content: flex-start;
    }

    .modal__cross {
      right: 0px;
      top: -30px;
      .icon-base--cross {
        width: 30px;
        height: 30px;
      }
    }

    .modal-header {
      padding: 0;

      .modal-title {
        margin-bottom: 50px;
        font-size: 45px;

        @media screen and (max-width: 768px) {
          margin-top: 20px;
          margin-bottom: 20px;
          font-size: 35px;
          padding-right: 0;
        }
      }

      .close {
        top: 0;
        right: 0;

        @media screen and (max-width: 768px) {
          top: 0px;
          right: 10px;
        }
      }
    }

    .modal-body {
      width: 100%;
      padding: 0 0 0 100px;
      margin-bottom: 15px;

      @media screen and (max-width: 768px) {
        flex: 0 0 auto;
        padding: 0 10px 0 10px;
      }

      .search-wrapper {
        display: flex;
        align-items: center;
        position: relative;

        .search-input {
          width: 768px;
          border: none;
          border-bottom: 2px solid white;
          background-color: transparent;
          color: white;
          width: 100%;
          font-size: 20px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          outline: none;
          transition-duration: .3s;

          &::placeholder {
            color: rgba($color: white, $alpha: 0.6);
          }

          &:focus {
            border-bottom: 2px solid $krona-green;
            transition-duration: .3s;
          }
        }

        .search-clear {
          @include bg--contain;
          width: 33px;
          height: 33px;
          background-color: transparent;
          border: none;
          transition: .3s;
          position: absolute;
          top: 0;
          right: 0;

          &:hover {
            path {
              fill: $krona-green;
              transition: .3s;
            }
          }
        }
      }

      .search-message {
        font-size: 20px;
        width: 100%;
        text-align: left;
        margin-top: 15px;
      }
    }

    .modal-footer>* {
      margin: 0;
    }

    .modal-footer {
      padding: 0;
      justify-content: flex-start;
      width: 100%;
      &::before {
        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      .search-row-result--wrapper {
        width: 100%;
      }

      .search-row-result {
        display: flex;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
        }

        &-title {
          font-size: 16px;
          min-width: 100px;
          text-align: right;
          padding: 8px 10px 0 0;

          @media screen and (max-width: 768px) {
            font-size: 14px;
            text-align: left;
            padding: 0px 10px 10px 10px;
          }
        }

        &-list-more {
          position: relative;

          &::before {
            position: absolute;
            top: 0;
            right: -60px;
            width: 60px;
            cursor: pointer;
            content: "Еще";
            background-color: white;
            border: 1px solid $krona-green;
            color: black;
            padding: 8px 10px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: 768px) {
              right: 10px;
              top: -40px;
              padding: 5px 8px;
            }
          }

          &--active {
            &::before {
              content: "Скрыть";
              right: -80px;
              width: 80px;

              @media screen and (max-width: 768px) {
                right: 10px;
              }
            }
          }
        }

        &-list {
          padding: 0;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          max-width: 700px;
          width: 100%;
          position: relative;

          @media screen and (max-width: 980px) {
            max-width: 500px;
          }

          @media screen and (max-width: 768px) {
            padding: 0 10px;
            max-width: none;
          }

          &-item {
            font-size: 16px;
            list-style: none;
            white-space: nowrap;

            &-link {
              padding: 7px 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid white;
              border-radius: 20px;
              margin-right: 10px;
              margin-bottom: 10px;
              color: white;

              &:hover {
                color: $krona-green;
                border: 1px solid $krona-green;
                background-color: white;
              }
            }
          }
        }
      }
    }
  }

  .search-form-wrapper {
    max-width: 500px;
    background-color: white;
    color: $text-gray--dark;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    transition: opacity .6s;
    text-align: center;

    a {
      color: $krona-green;
      transition: .3s;

      &:hover {
        color: $krona-green--dark;
        transition: .3s;
        text-decoration: underline;
      }
    }

    .fc-agreement {
      color: white;
    }

    .search-form {
      max-width: 340px;
      width: 100%;
    }

    .search-form-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
      line-height: 1.2;
    }

    .search-form-subtitle {
      font-size: 14px;
      margin-bottom: 20px;
      line-height: 1.2;
    }

    small {
      color: $text-gray--dark;
    }
  }

  .search-form-wrapper--active {
    opacity: 1;
    transition: opacity .6s;
  }
}

.show {
  opacity: 1;
  visibility: visible;
}

.fade {
  transition: opacity .15s linear;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
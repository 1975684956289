.menu-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include for-768 {
    margin-right: 15px;
    padding: 7px;
  }

  @include for-400 {
    margin-right: 5px;
    padding: 5px;
  }
}

.menu-bar {
  width: 22px;
  height: 3px;
  background-color: white;
  margin: 2px auto;
  transition: transform 0.3s;

  @include for-768 {
    width: 17px;
    height: 2px;
    margin: 1.5px auto;
  }
}

.menu-button.menu-button--cross .menu-bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);

  @include for-768 {
    transform: rotate(45deg) translate(2.5px, 2.5px);
  }
}

.menu-button.menu-button--cross .menu-bar:nth-child(2) {
  opacity: 0;
}

.menu-button.menu-button--cross .menu-bar:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.logo {
  @include bg--contain;
  width: 158px;
  height: 50px;
  background-image: url('../assets/icons/logo-krona.svg');
  margin-right: 10px;

  @include for-768 {
    width: 52px;
  }

  @include for-400 {
    width: 48px;
  }
}

.lk {
  padding: 11px 15px;
  font-size: 18px;
  font-weight: bold;
  margin-right: 30px;
  display: flex;
  align-items: center;
  @include PTSansNarrow-font;
  transition-duration: .3s;

  @include for-980 {
    margin-right: 15px;
  }

  @include for-768 {
    font-size: 0;
    margin-right: 0;
  }

  @include for-400 {
    padding: 11px 10px;
  }

  &:hover {
    transition-duration: .3s;
    box-shadow: 0 0 12.6px 8.4px rgb(79 210 98 / 30%);
    border-color: rgba(79, 210, 98, .3);
  }

  .icon-base--user {
    fill: white;
    margin-right: 10px;

    @include for-768 {
      margin-right: 0;
    }
  }
}

.phone {
  font-size: 20px;
  font-weight: bold;
  padding: 11px 35px 11px 15px;
  position: relative;
  @include PTSansNarrow-font;

  @include for-980 {
    font-size: 18px;
    padding: 11px 35px 11px 5px;
  }

  @include for-768 {
    font-size: 0;
    padding: 11px 15px;
  }

  @include for-400 {
    padding: 11px 10px;
  }

  &-time {
    background-color: $krona-green;
    padding: 3px;
    font-size: 14px;
    line-height: 1;
    position: absolute;
    top: 0;
    right: 0;

    @include for-768 {
      display: none;
    }
  }

  .icon-base--phone {
    display: none;

    @include for-768 {
      display: block;
      fill: white;
    }
  }
}

// .whatsapp {
//   background-color: $krona-green--wa;
//   width: 95px;
//   height: 60px;
//   text-align: right;
//   padding: 8px;
//   color: white;
//   font-size: 14px;
//   font-weight: 700;
//   @include PTSansNarrow-font;
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
// }

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: white;
  z-index: 1000;

  a {
    color: white;
  }

  .icon-menu {
    display: none;
  }

  .cities-block {
    margin-right: auto;
  }
}

.header--fixed {
  position: fixed;
  background-color: white;
  box-shadow: 0 2px 20px rgba(140, 154, 185, 0.25);

  .header-bottom {
    display: none;
  }
}

.header-top {
  display: flex;
  align-items: center;
  margin: 20px auto;
}

.header-bottom {
  width: 100%;
  display: flex;
  position: relative;

  @include for-980 {
    display: none;
  }

  @include for-768 {
    flex-wrap: wrap;
  }

  .header-bottom-item {
    padding: 28px 14px;
    text-transform: uppercase;
    border: 1px solid transparent;
    width: calc(100% / 6);
    text-align: center;
    font-size: 16px;
    transition-duration: 0.3s;
    @include PTSansNarrow-font;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 72px;

    @include for-768 {
      width: 100%;
      justify-content: flex-start;
    }

    &:hover {
      background-color: white;
      color: $krona-green;
      transition-duration: 0.3s;
    }

    &--more {
      cursor: default;

      @include for-768 {
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          right: 30px;
          transform: translate(-50%, -75%) rotate(45deg);
          border-right: solid 2px #fff;
          border-bottom: solid 2px #fff;
          height: 10px;
          width: 10px;
          transition: .3s;
        }
      }

      &:hover {
        .header-bottom-sublist {
          z-index: 100;
        }
      }
    }

    &--more-active {
      &:hover {
        &::before {
          transform: translate(-50%, -75%) rotate(-135deg);
          transition: .3s;
        }
      }
    }

    .header-bottom-sublist--active {
      display: flex !important;
    }

    .header-bottom-sublist {
      display: none;
      background-color: #f9f9fa;
      padding: 20px;
      position: absolute;
      left: 0;
      top: 62px;
      width: 100%;
      flex-wrap: wrap;
      z-index: -1;


      &-item {
        width: calc(20% - 2px);
        margin: 1px;
        height: 120px;
        font-size: 14px;
        padding: 20px;
        background-color: #b1bbcc;
        transition: .3s;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        color: white;
        text-align: left;

        &:hover {
          background-color: #c0c8d6;
          color: white;
          transition: .3s;
        }
      }

      .icon-submenu {
        margin-bottom: auto;
      }
    }

    .icon {
      margin-right: auto;
    }
  }
}

.header--alt {

  .search-btn {
    background-image: url('../assets/icons/components/search/search-gray.svg');
  }

  .dropdown {
    color: black;

    &::before {
      border-right: solid 2px black;
      border-bottom: solid 2px black;
    }
  }

  .menu-bar {
    background-color: black;
  }

  .icon-menu {
    display: block;
  }

  .icon-base--user {
    fill: black;
  }

  .phone {
    font-size: 18px;
    color: $text-gray--dark;
    @include Helvetica-font;

    span {
      color: white;
    }
  }

  .lk {
    @include Helvetica-font;
    background-color: white;
    font-size: 14px;
    box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    padding: 8px 20px 7px;
    color: $text-gray--dark;

    @include for-980 {
      padding: 8px 10px 7px;
    }

    &:hover {
      background-color: $krona-gray--light;
    }

    &-icon {
      background-image: url('../assets/icons/logo-lk--alt.svg');
    }

    @include for-768 {
      font-size: 0;
      box-shadow: none;

      .icon-base--user {
        margin-right: 0;
      }
    }
  }
  
  .phone {
    @include for-768 {
      font-size: 0;
      padding: 11px 15px;

      .icon-base--phone {
        fill: black;
      }

      .phone-time {
        display: none;
      }
    }

    @include for-400 {
      padding: 11px 10px;
    }
  }

  .header-bottom-item {
    justify-content: flex-end;
    background-color: white;
    box-shadow: 0 0 5px 0 rgb(89 181 107);
    font-size: 14px;
    color: $text-gray--dark;
    padding: 12px 14px 20px;
    text-align: right;
    font-weight: bold;

    @include for-768 {
      justify-content: flex-start;

      .icon-menu {
        margin-right: 10px;
      }
    }


    &:hover {
      background-color: $krona-gray--light;
      color: $krona-green--dark;
      box-shadow: none;
    }
  }

  .header-bottom-item.header-bottom-item--more {
    
  }

  .header-bottom-with-list {
    width: 100%;
    display: flex;
    align-items: center;
  }
}

.header-menu {
  width: 100%;
  background-color: #7f7f7f;
  color: white;
  padding: 40px 15px;
  font-size: 14px;
  display: none;

  @include for-480 {
    padding: 25px 5px;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
  }

  .link {
    color: white;
  }

  &__col {
    display: flex;
    margin-bottom: 20px;

    &:nth-child(3) {
      .header-menu__list {
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        .link {
          margin-right: 15px;
          opacity: .7;
          transition-duration: .3s;
          cursor: pointer;

          .icon {
            fill: white;
          }

          &:hover {
            transition-duration: .3s;
            opacity: 1;
          }
        }
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
  }

  &__item {
    margin-bottom: 10px;
  }
}

.open-menu {
  overflow: hidden;

  .phone {
    color: white;
  }

  .header-menu {
    display: flex;
  }

  .header {
    background-color: #7f7f7f;
    color: white;
  }
}

.header.header--fixed {
  .icon-base--user,
  .icon-base--phone {
    fill: black;
  }

  .lk {
    color: black;
  }

  .phone {
    color: black;
  }

}

.header.header--fixed:not(.header--alt){
  .icon-base--user,
  .icon-base--phone {
    fill: black;
  }

  .menu-bar {
    background-color: $krona-green;
  }

  .dropdown {
    &::before {
      border-right: solid 2px black;
      border-bottom: solid 2px black;
    }
  }

  .dropdown__choose {
    color: black;
  }
}

.header.header--open {
  background-color: #7f7f7f;
  overflow-y: scroll;
  max-height: 100vh;

  @include for-768 {
    min-height: 100vh;
    height: 100%;
  }

  .header-bottom {
    display: flex !important;
  }

  .header-menu {
    display: flex;
  }

  .menu-bar {
    background-color: white;
  }

  .phone {
    color: white;
  }

  @include for-980 {
    .header-bottom {
      display: flex;

      &-item {
        padding: 14px;

        &:hover {
          background-color: transparent;
        }

        &--more {
          position: relative;

          &::before {
            border-color: white;
          }
        }
      }

      .header-bottom-sublist-item {
        padding: 14px;
      }
    }
  }

  @include for-768 {

    .header-bottom-item--more {
      flex-wrap: wrap;
      max-height: none;

      &::before {
        top: 22px;
      }

      .header-bottom-with-list {
        width: 100%;
        text-align: left;
      }
    }

    .header-bottom-sublist {
      position: static;
      top: auto;
      background-color: transparent;
      padding: 0;

      &-item {
        width: 100%;
        background-color: transparent;
        height: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 14px 0;

        .icon {
          margin-right: 10px;
          max-height: 25px;

          &-submenu {
            &--skud {
              max-width: 38px;
            }
          }
        }
      }
    }

  }

  .dropdown.dropdown--city {
    &::before {
      border-right: solid 2px white;
      border-bottom: solid 2px white;
    }
  }

  .icon.icon-base--user,
  .icon.icon-base--phone {
    fill: white;
  }

  .dropdown > .dropdown__choose {
    color: white;
  }

  .search-btn {
    background-image: url('../assets/icons/components/search/search-white.svg');
  }
}

.header.header--alt.header--open {
  .icon-base--user {
    fill: black;
  }

  @include for-768 {
    .header-bottom-item {
      background-color: transparent;
      color: white;
      box-shadow: none;
  
      .icon-menu {
        display: none;
      }
    }

    .lk {
      background-color: transparent;
  
      .icon-base--user {
        fill: white;
      }
    }
  }

  @include for-480 {
    .header-menu {
      padding: 25px 5px;
    }
  }
}

.header.header--fixed.header--open {
  .lk {
    color: white;
  }
}
@charset "UTF-8";
@font-face {
  font-family: "PTSansNarrow";
  src: url("../assets/fonts/PTSans-Narrow.woff2") format("woff2");
  font-weight: normal;
}
@font-face {
  font-family: "PTSansNarrow";
  src: url("../assets/fonts/PTSans-NarrowBold.woff2") format("woff2");
  font-weight: bold;
}
@font-face {
  font-family: "OpenSans";
  src: url("../assets/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "OpenSans";
  src: url("../assets/fonts/OpenSans-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "OpenSans";
  src: url("../assets/fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
}
body {
  font-family: Helvetica, sans-serif;
  background-color: white;
  color: #3b434f;
  font-size: 17px;
  overflow-x: hidden;
  margin: 0 auto;
  min-height: 100vh;
}

.main {
  min-height: 100vh;
  padding-top: 154px;
}
@media (max-width: 980px) {
  .main {
    padding-top: 84px;
  }
}
.main--mob {
  min-height: auto;
}

a {
  cursor: pointer;
  color: #3b434f;
  text-decoration: none;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.d-flex {
  display: flex;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
.btn {
  background-color: #4fd262;
  color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition-duration: 0.3s;
  padding: 11px 20px 10px;
  text-align: center;
  border: none;
}
.btn:hover {
  background-color: #2ba93e;
  transition-duration: 0.3s;
}

input, button {
  outline: none;
  border: none;
}

.link, .product-dops__more {
  color: #4fd262;
  transition-duration: 0.3s;
}
.link:hover, .product-dops__more:hover {
  color: #2ba93e;
  text-decoration: underline;
  transition-duration: 0.3s;
}

.link--green, .product-dops__more {
  color: #4fd262 !important;
}
.link--green:hover, .product-dops__more:hover {
  color: #2ba93e !important;
}

.text-bold {
  font-weight: bold;
}

.page-title {
  font-size: 46px;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .page-title {
    font-size: 36px;
  }
}
@media (max-width: 480px) {
  .page-title {
    font-size: 30px;
  }
}

.page-date {
  display: inline-block;
  font-size: 12px;
  color: #999999;
  margin-bottom: 20px;
}

ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}

.no-scroll {
  overflow: hidden;
}

.d-none {
  display: none !important;
}

.w-100 {
  width: 100%;
}

.pointer-event--none {
  pointer-events: none;
}

.mob {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0 100px;
}
.mob h1 {
  text-align: center;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  max-width: 1280px;
}
@media screen and (max-width: 980px) {
  .container {
    padding: 0 20px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    padding: 0 15px;
  }
}
@media screen and (max-width: 480px) {
  .container {
    padding: 0 10px;
  }
}
.container--width {
  max-width: 1360px;
}

.section {
  padding-bottom: 60px;
}
.section:last-child {
  padding-bottom: 0;
}

svg.icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
svg.icon-product__features--1 {
  width: 25px;
  height: 47px;
}
svg.icon-product__features--2 {
  width: 36px;
  height: 42px;
}
svg.icon-product__features--3 {
  width: 32px;
  height: 33px;
}
svg.icon-product__features--4 {
  width: 24px;
  height: 40px;
}
svg.icon-product__features--5 {
  width: 26px;
  height: 28px;
}
svg.icon-product__features--6 {
  width: 38px;
  height: 39px;
}
svg.icon-product__features--7 {
  width: 40px;
  height: 34px;
}
svg.icon-product__features--32 {
  width: 34px;
  height: 33px;
}
svg.icon-product__seven--1 {
  width: 62px;
  height: 65px;
}
svg.icon-product__seven--2 {
  width: 56px;
  height: 45px;
}
svg.icon-product__seven--3 {
  width: 62px;
  height: 47px;
}
svg.icon-product__seven--4 {
  width: 57px;
  height: 57px;
}
svg.icon-product__seven--5 {
  width: 63px;
  height: 60px;
}
svg.icon-product__seven--6 {
  width: 88px;
  height: 74px;
}
svg.icon-product__seven--7 {
  width: 57px;
  height: 64px;
}
svg.icon-product__system {
  min-height: 70px;
}
svg.icon-product__system--1 {
  width: 94px;
  height: 64px;
}
svg.icon-product__system--2 {
  width: 85px;
  height: 63px;
}
svg.icon-product__system--3 {
  width: 64px;
  height: 70px;
}
svg.icon-product__system--4 {
  width: 62px;
  height: 65px;
}
svg.icon-product__system--5 {
  width: 63px;
  height: 60px;
}
svg.icon-product__system--6 {
  width: 68px;
  height: 70px;
}
svg.icon-product__system--7 {
  width: 65px;
  height: 70px;
}
svg.icon-product__system--8 {
  width: 58px;
  height: 70px;
}
svg.icon-product__system--9 {
  width: 53px;
  height: 70px;
}
svg.icon-product__system--10 {
  width: 75px;
  height: 70px;
}
svg.icon-product__system--11 {
  width: 91px;
  height: 70px;
}
svg.icon-product__system--12 {
  width: 70px;
  height: 70px;
}
svg.icon-product__system--13 {
  width: 70px;
  height: 70px;
}
svg.icon-product__system--adv-32 {
  width: 60px;
  height: 60px;
}
.icon-png {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 70px;
  min-width: 70px;
  height: 70px;
  z-index: 2;
}
.icon-png-product__system--23 {
  background-image: url("../assets/icons/index-system/ic_system-23.png");
}
.icon-png-product__system--24 {
  background-image: url("../assets/icons/index-system/ic_system-24.png");
}
.icon-png-product__system--25 {
  background-image: url("../assets/icons/index-system/ic_system-25.png");
}
.icon-png-product__system--26 {
  width: 60px;
  min-width: 60px;
  background-image: url("../assets/icons/index-system/ic_system-26.png");
}
.icon-png-product__system--27 {
  background-image: url("../assets/icons/index-system/ic_system-27.png");
}
.icon-png-product__system--28 {
  background-image: url("../assets/icons/index-system/ic_system-28.png");
}
.icon-png-product__system--29 {
  background-image: url("../assets/icons/index-system/ic_system-29.png");
}
.icon-png-product__system--30 {
  width: 90px;
  background-image: url("../assets/icons/index-system/ic_system-30.png");
}
.icon-png-product__type-work--1 {
  background-image: url("../assets/icons/products/product-type-work/ic_type-work-1.webp");
}
.icon-png-product__type-work--2 {
  background-image: url("../assets/icons/products/product-type-work/ic_type-work-2.webp");
}
.icon-png-product__type-work--3 {
  background-image: url("../assets/icons/products/product-type-work/ic_type-work-3.webp");
}
.icon-png-product__type-work--4 {
  background-image: url("../assets/icons/products/product-type-work/ic_type-work-4.webp");
}
.icon-png-product__type-work--5 {
  background-image: url("../assets/icons/products/product-type-work/ic_type-work-5.webp");
}

svg.icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
}
svg.icon-base--arrow {
  width: 14px;
  height: 14px;
}
svg.icon-base--phone {
  width: 20px;
  height: 20px;
}
svg.icon-base--check {
  width: 20px;
  height: 20px;
}
svg.icon-base--cross {
  width: 24px;
  height: 24px;
}
svg.icon-base--user {
  width: 16px;
  height: 14px;
}
svg.icon-link--appstore {
  width: 130px;
  height: 50px;
}
svg.icon-link--playstore {
  width: 130px;
  height: 50px;
}
svg.icon-link--vk {
  width: 50px;
  height: 50px;
}
svg.icon-menu {
  width: 28px;
  height: 28px;
}
svg.icon-submenu--office {
  width: 27px;
  height: 32px;
}
svg.icon-submenu--banks {
  width: 33px;
  height: 32px;
}
svg.icon-submenu--shop {
  width: 32px;
  height: 32px;
}
svg.icon-submenu--skud {
  width: 62px;
  height: 32px;
}
svg.icon-submenu--fog {
  width: 39px;
  height: 32px;
}
svg.icon-submenu--btn {
  width: 40px;
  height: 40px;
}
svg.icon-submenu--alarm {
  width: 39px;
  height: 32px;
}
svg.icon-submenu--btn_alarm {
  width: 39px;
  height: 32px;
}
svg.icon-submenu--fire {
  width: 40px;
  height: 40px;
}

.index {
  position: relative;
}

.index-bg {
  display: block;
  min-height: 670px;
  background: #fff;
  background-image: url("../assets/images/index/bg--main.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.index-first {
  position: relative;
  z-index: 10;
  display: flex;
  color: white;
  margin-bottom: 20px;
  margin-top: 80px;
  align-items: center;
}
@media (max-width: 980px) {
  .index-first {
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .index-first {
    margin-top: 50px;
  }
}
.index-first__text {
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  text-align: left;
  height: 300px;
}
@media (max-width: 980px) {
  .index-first__text {
    order: 1;
    flex-wrap: nowrap;
    flex-direction: column;
    height: auto;
    width: 100%;
    padding-left: 0;
    align-items: center;
    max-width: 680px;
    margin: 0 auto 40px;
    align-items: flex-start;
    height: auto;
  }
}
.index-first__title {
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 10px;
  margin-bottom: auto;
  text-transform: uppercase;
}
@media (max-width: 980px) {
  .index-first__title {
    font-size: 40px;
    padding-bottom: 40px;
  }
}
@media (max-width: 480px) {
  .index-first__title {
    font-size: 30px;
  }
}
.index-first__list {
  font-size: 21px;
  line-height: 24px;
  font-weight: 500;
  padding-left: 20px;
  padding-bottom: 10px;
  margin-bottom: auto;
}
@media (max-width: 980px) {
  .index-first__list {
    font-size: 28px;
    line-height: 32px;
    padding-bottom: 40px;
  }
}
@media (max-width: 480px) {
  .index-first__list {
    font-size: 20px;
    line-height: 24px;
  }
}
.index-first__list-item {
  list-style: disc;
  margin-bottom: 5px;
}
.index-first__btn {
  max-width: 360px;
  font-size: 24px;
  padding: 15px 10px;
}
@media (max-width: 980px) {
  .index-first__btn {
    padding: 15px 30px;
  }
}
@media (max-width: 480px) {
  .index-first__btn {
    font-size: 20px;
    max-width: 320px;
  }
}
.index-first__image {
  max-width: 680px;
  width: 100%;
  height: 380px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  overflow: hidden;
  flex-shrink: 0;
}
@media (max-width: 1200px) {
  .index-first__image {
    max-width: 600px;
  }
}
@media (max-width: 980px) {
  .index-first__image {
    max-width: 680px;
    height: 265px;
    order: 2;
    margin: 0 auto;
  }
}
.index-first__image-img {
  object-fit: cover;
  width: calc(100% + 10px);
  height: 100%;
  margin-left: -5px;
}

.index-block-top {
  position: relative;
  margin-bottom: 30px;
  margin-left: 120px;
}
@media (max-width: 980px) {
  .index-block-top {
    margin-left: 60px;
  }
}
.index-block-top--left {
  margin-right: auto;
}
.index-block-top--center {
  margin: 0 auto 30px;
}
.index-block-top--center:after {
  position: absolute;
  content: "";
  left: 100%;
  top: 0px;
  width: 8px;
  height: 40px;
  margin-left: 40px;
  background-color: #4fd262;
  transform: skew(-30deg);
}
.index-block-top:before {
  position: absolute;
  content: "";
  right: 100%;
  top: 0px;
  width: 8px;
  height: 40px;
  margin-right: 40px;
  background-color: #4fd262;
  transform: skew(-30deg);
}
.index-block-top-title {
  font-size: 36px;
  margin-bottom: 10px;
  font-weight: 700;
}
@media (max-width: 768px) {
  .index-block-top-title {
    font-size: 26px;
  }
}
.index-block-top-subtitle {
  font-size: 24px;
  font-weight: 300;
  opacity: 0.7;
}
@media (max-width: 768px) {
  .index-block-top-subtitle {
    font-size: 18px;
  }
}

.index-tarif {
  margin-bottom: 20px;
}
.index-tarif__list {
  display: flex;
  margin: 0 auto;
  max-width: 1000px;
}
@media (max-width: 768px) {
  .index-tarif__list {
    flex-wrap: wrap;
  }
}
.index-tarif__list-item {
  border-radius: 5px;
  margin: 0 10px 10px;
  padding: 25px 20px;
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
  width: calc(33.3% - 20px);
}
@media (max-width: 768px) {
  .index-tarif__list-item {
    width: calc(50% - 20px);
  }
}
@media (max-width: 480px) {
  .index-tarif__list-item {
    width: calc(100% - 20px);
  }
}
.index-tarif__list-title {
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 10px;
}
.index-tarif__list-feature {
  padding-left: 15px;
  margin-bottom: auto;
}
.index-tarif__list-feature-item {
  list-style: disc;
  margin-bottom: 5px;
  font-size: 14px;
}
.index-tarif__list-tarif {
  margin-top: 20px;
  margin-bottom: 20px;
}
.index-tarif__list-tarif-item {
  margin-bottom: 10px;
}
.index-tarif__list-tarif-price {
  font-weight: 700;
}
.index-tarif__btn {
  width: 100%;
  box-shadow: none;
}
.index-tarif__description {
  max-width: 980px;
  margin: 0 auto;
  padding-top: 20px;
}

.index-about {
  display: flex;
  justify-content: flex-start;
  margin: 50px 0;
}
@media (max-width: 768px) {
  .index-about {
    flex-direction: column;
  }
}
.index-about__info {
  max-width: 50%;
  margin-right: 10px;
  overflow: hidden;
}
@media (max-width: 768px) {
  .index-about__info {
    max-width: 100% !important;
    width: 100%;
  }
}
.index-about__text {
  max-height: 270px;
  padding-left: 120px;
  padding-right: 10px;
}
@media (max-width: 980px) {
  .index-about__text {
    padding-left: 0;
  }
}
.index-about__text ul {
  margin-left: 40px;
}
.index-about__text ul li {
  list-style: disc;
}
.index-about__image {
  width: 100%;
  height: 400px;
}
@media (max-width: 768px) {
  .index-about__image {
    margin: 20px auto;
  }
}
.index-about__image-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 500px;
}
.index-about__image-img--index {
  height: 400px;
  background-image: url("../assets/images/block-about/index-content-right.webp");
}
.index-about__image-img--skud {
  height: 500px;
  background-image: url("../assets/images/block-about/skud-content-right.webp");
}
.index-about__image-img--fog {
  height: 500px;
  background-image: url("../assets/images/block-about/fog-content-right.webp");
}
.index-about__image-img--alarm-button {
  height: 500px;
  background-image: url("../assets/images/block-about/alarm_button-content-right.webp");
}
.index-about__image-img--alarm-security {
  height: 500px;
  background-image: url("../assets/images/block-about/alarm_security-content-right.webp");
}
.index-about__image-img--fire {
  height: 500px;
  background-image: url("../assets/images/block-about/fire-content-right.webp");
}
.index-about__image-img--intercoms_start {
  height: 500px;
  background-image: url("../assets/images/block-about/intercoms_start-content-right.webp");
}
.index-about--gray .index-about__info {
  max-width: 50%;
  background-color: #f9f9fa;
  margin-right: 0;
  padding: 20px 10px 20px 20px;
  height: 500px;
  max-height: 500px;
}
@media (max-width: 768px) {
  .index-about--gray .index-about__info {
    height: auto;
  }
}
.index-about--gray .index-about__text {
  max-height: 310px;
}
@media (max-width: 1200px) {
  .index-about--gray .index-about__text {
    max-height: 260px;
  }
}

.index-app {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.index-app__content {
  display: flex;
}
@media (max-width: 980px) {
  .index-app__content {
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .index-app__content {
    width: 100%;
  }
}
.index-app__features {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  width: 530px;
}
@media (max-width: 1200px) {
  .index-app__features {
    width: 480px;
  }
}
@media (max-width: 980px) {
  .index-app__features {
    margin-top: 20px;
    order: 2;
  }
}
@media (max-width: 768px) {
  .index-app__features {
    width: 100%;
    max-width: 480px;
  }
}
@media (max-width: 480px) {
  .index-app__features {
    margin-top: 15px;
  }
}
.index-app__features-item {
  padding: 20px 25px;
  height: 180px;
  width: calc(50% - 20px);
  margin: 0 10px 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.index-app__features-item:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;
}
@media (max-width: 768px) {
  .index-app__features-item {
    padding: 20px 20px 20px 30px;
    height: 160px;
  }
}
@media (max-width: 480px) {
  .index-app__features-item {
    padding: 10px;
  }
}
.index-app__features-icon {
  width: 70px;
  height: 70px;
  margin-bottom: 10px;
  flex-shrink: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.index-app__features-icon--1 {
  background-image: url("../assets/icons/index-app/ic_app-1.svg");
}
.index-app__features-icon--2 {
  width: 90px;
  background-image: url("../assets/icons/index-app/ic_app-2.svg");
}
.index-app__features-icon--3 {
  background-image: url("../assets/icons/index-app/ic_app-3.svg");
}
.index-app__features-icon--4 {
  width: 60px;
  background-image: url("../assets/icons/index-app/ic_app-4.svg");
}
.index-app__features-name {
  color: #3b434f;
  font-size: 24px;
  line-height: 30px;
  font-weight: 300;
}
@media (max-width: 768px) {
  .index-app__features-name {
    font-size: 18px;
    line-height: 24px;
  }
}
@media (max-width: 480px) {
  .index-app__features-name {
    font-size: 16px;
    line-height: 18px;
  }
}
.index-app__features-text {
  width: 100%;
  display: none;
  font-size: 14px;
}
.index-app__features-item--active {
  padding: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;
}
@media (max-width: 480px) {
  .index-app__features-item--active {
    padding: 10px;
  }
}
.index-app__features-item--active .index-app__features-icon {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}
.index-app__features-item--active .index-app__features-icon--2 {
  width: 45px;
}
.index-app__features-item--active .index-app__features-icon--4 {
  width: 30px;
}
.index-app__features-item--active .index-app__features-name {
  font-size: 18px;
  line-height: 18px;
}
@media (max-width: 480px) {
  .index-app__features-item--active .index-app__features-name {
    font-size: 13px;
    line-height: 15px;
  }
}
.index-app__features-item--active .index-app__features-text {
  display: block;
}
@media (max-width: 480px) {
  .index-app__features-item--active .index-app__features-text {
    font-size: 12px;
  }
}
.index-app-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 300px;
  height: 400px;
  margin-left: 50px;
  background-color: #4fd262;
}
.index-app__mockup {
  margin-left: 20px;
  width: 500px;
  height: 460px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.index-app__mockup-links {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.index-app__mockup-links .link, .index-app__mockup-links .product-dops__more {
  margin: 5px;
  cursor: pointer;
}
.index-app__mockup-links .link .icon, .index-app__mockup-links .product-dops__more .icon {
  transition-duration: 0.3s;
  width: 160px;
  height: 50px;
}
@media (max-width: 400px) {
  .index-app__mockup-links .link .icon, .index-app__mockup-links .product-dops__more .icon {
    width: 140px;
  }
}
.index-app__mockup-links .link:hover .icon, .index-app__mockup-links .product-dops__more:hover .icon {
  fill: #2ba93e;
  transition-duration: 0.3s;
}
@media (max-width: 980px) {
  .index-app__mockup {
    order: 1;
    margin-left: 0;
  }
}
@media (max-width: 768px) {
  .index-app__mockup {
    width: 100%;
    overflow: hidden;
  }
}
.index-app__mockup-circle {
  position: absolute;
  z-index: 1;
  width: 500px;
  height: 500px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../assets/images/index/app-circle.webp");
}
@media (max-width: 980px) {
  .index-app__mockup-circle {
    height: 460px;
  }
}
.index-app__mockup-device {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 500px;
  height: 570px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../assets/images/index/app-phone.png");
}
@media (max-width: 980px) {
  .index-app__mockup-device {
    height: 460px;
  }
}
@media (max-width: 480px) {
  .index-app__mockup-device {
    width: 300px;
  }
}

.index-defend {
  padding: 80px 0;
}
.index-defend__content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media (max-width: 768px) {
  .index-defend__content {
    flex-wrap: wrap;
  }
}
.index-defend__content-item {
  width: 190px;
  height: 200px;
  padding: 15px 0 0 20px;
  background-color: #f9f9fa;
  border: 1px solid white;
  transition: 0.3s;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-position: right bottom;
  cursor: pointer;
}
@media (max-width: 768px) {
  .index-defend__content-item {
    width: 170px;
  }
}
.index-defend__content-item--1 {
  background-size: 140px;
  background-image: url("../assets/images/index/feat-icon-1.png");
}
.index-defend__content-item--1:hover {
  background-image: url("../assets/images/index/feat-icon-1--hover.png");
}
.index-defend__content-item--2 {
  background-size: 105px;
  background-image: url("../assets/images/index/feat-icon-2.png");
}
.index-defend__content-item--2:hover {
  background-image: url("../assets/images/index/feat-icon-2--hover.png");
}
.index-defend__content-item--3 {
  background-size: 100px;
  background-image: url("../assets/images/index/feat-icon-3.png");
}
.index-defend__content-item--3:hover {
  background-image: url("../assets/images/index/feat-icon-3--hover.png");
}
.index-defend__content-item--4 {
  background-size: 115px;
  background-image: url("../assets/images/index/feat-icon-4.png");
}
.index-defend__content-item--4:hover {
  background-image: url("../assets/images/index/feat-icon-4--hover.png");
}
.index-defend__content-item--5 {
  background-size: 130px;
  background-image: url("../assets/images/index/feat-icon-5.png");
}
.index-defend__content-item--5:hover {
  background-image: url("../assets/images/index/feat-icon-5--hover.png");
}
.index-defend__content-title {
  font-family: "PTSansNarrow", sans-serif;
  font-size: 16px;
  color: #3b434f;
  text-transform: uppercase;
  font-weight: bold;
}
.index-defend__hover {
  transition: 0.3s;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  max-width: 950px;
  height: 400px;
  opacity: 0;
  z-index: -1;
  display: flex;
}
.index-defend__hover-image {
  min-width: 20%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media (max-width: 768px) {
  .index-defend__hover-image {
    min-width: 35%;
  }
}
@media (max-width: 480px) {
  .index-defend__hover-image {
    display: none;
  }
}
.index-defend__hover-image--1 {
  background-image: url("../assets/images/index/feat-1.webp");
}
.index-defend__hover-image--2 {
  background-image: url("../assets/images/index/feat-2.webp");
}
.index-defend__hover-image--3 {
  background-image: url("../assets/images/index/feat-3.webp");
}
.index-defend__hover-image--4 {
  background-image: url("../assets/images/index/feat-4.webp");
}
.index-defend__hover-image--5 {
  background-image: url("../assets/images/index/feat-5.webp");
}
.index-defend__hover-text {
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  width: 60%;
  height: 100%;
  padding: 50px 40px 40px;
  overflow: auto;
  font-size: 14px;
  line-height: 24px;
}
@media (max-width: 980px) {
  .index-defend__hover-text {
    padding: 20px;
  }
}
@media (max-width: 768px) {
  .index-defend__hover-text {
    min-width: 65%;
    width: 100%;
  }
}
@media (max-width: 480px) {
  .index-defend__hover-text {
    min-width: 100%;
    width: 100%;
    padding: 10px;
  }
}
.index-defend__hover .index-defend__content-item {
  background-color: white;
  min-width: 20%;
}
@media (max-width: 768px) {
  .index-defend__hover .index-defend__content-item {
    display: none;
  }
}

.index-eto {
  padding: 50px 0;
  background-color: #f9f9fa;
}
.index-eto__content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.index-eto__content-icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.index-eto__content-icon--1 {
  width: 63px;
  height: 64px;
  background-image: url("../assets/icons/index-eto/ic_eto-1.svg");
}
@media (max-width: 480px) {
  .index-eto__content-icon--1 {
    width: 53px;
    height: 54px;
  }
}
.index-eto__content-icon--2 {
  width: 61px;
  height: 63px;
  background-image: url("../assets/icons/index-eto/ic_eto-2.svg");
}
@media (max-width: 480px) {
  .index-eto__content-icon--2 {
    width: 51px;
    height: 53px;
  }
}
.index-eto__content-icon--3 {
  width: 50px;
  height: 62px;
  background-image: url("../assets/icons/index-eto/ic_eto-3.svg");
}
@media (max-width: 480px) {
  .index-eto__content-icon--3 {
    width: 40px;
    height: 52px;
  }
}

.index-system {
  padding: 50px 0;
}
.index-system__content {
  display: flex;
  flex-wrap: wrap;
  padding-left: 90px;
}
@media (max-width: 980px) {
  .index-system__content {
    overflow-x: scroll;
    justify-content: flex-start;
    flex-wrap: nowrap;
    padding-left: 0;
  }
}
.index-system__content-item {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 40px 10px 10px;
  flex-shrink: 0;
  width: calc(33% - 40px);
  margin: 0 20px;
}
.index-system__content-num {
  font-size: 150px;
  color: #dce0e8;
  position: absolute;
  top: -10px;
  right: -15px;
  font-weight: bold;
  line-height: 1;
  z-index: -1;
}
.index-system__content-info {
  margin-top: 40px;
}
.index-system__content-info-title {
  font-size: 17px;
  font-weight: bold;
  font-family: "PTSansNarrow", sans-serif;
  margin-bottom: 8px;
}
.index-system__content-info-text {
  font-size: 12px;
  max-width: 200px;
}

.index-partners {
  padding: 50px 0;
}
.index-partners__content {
  display: flex;
  flex-wrap: wrap;
  padding-left: 120px;
  align-items: center;
}
@media (max-width: 980px) {
  .index-partners__content {
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-left: 0;
  }
}
.index-partners__content-item {
  min-width: 133px;
  width: 133px;
  height: 128px;
  border: 1px solid #f9f9fa;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0px -1px -1px 0;
}
.index-partners__content-item--1 {
  background-image: url("../assets/images/index/logo-1.webp");
}
.index-partners__content-item--2 {
  background-image: url("../assets/images/index/logo-2.webp");
}
.index-partners__content-item--3 {
  background-image: url("../assets/images/index/logo-3.webp");
}
.index-partners__content-item--4 {
  background-image: url("../assets/images/index/logo-4.webp");
}
.index-partners__content-item--5 {
  background-image: url("../assets/images/index/logo-5.webp");
}
.index-partners__content-item--6 {
  background-image: url("../assets/images/index/logo-6.webp");
}
.index-partners__content-item--7 {
  background-image: url("../assets/images/index/logo-7.webp");
}
.index-partners__content-item--8 {
  background-image: url("../assets/images/index/logo-8.webp");
}
.index-partners__content-item--9 {
  background-image: url("../assets/images/index/logo-9.webp");
}
.index-partners__content-item--10 {
  background-image: url("../assets/images/index/logo-10.webp");
}
.index-partners__content-item--11 {
  background-image: url("../assets/images/index/logo-11.webp");
}
.index-partners__content-item--12 {
  background-image: url("../assets/images/index/logo-12.webp");
}
.index-partners__content-item--13 {
  background-image: url("../assets/images/index/logo-13.webp");
}
.index-partners__content-item--14 {
  background-image: url("../assets/images/index/logo-14.webp");
}
.index-partners__content-item--15 {
  background-image: url("../assets/images/index/logo-15.webp");
}
.index-partners__content-item--16 {
  background-image: url("../assets/images/index/logo-16.webp");
}
.index-partners__content-item--17 {
  background-image: url("../assets/images/index/logo-17.webp");
}
.index-partners__content-item--18 {
  background-image: url("../assets/images/index/logo-18.webp");
}
.index-partners__content-item--19 {
  background-image: url("../assets/images/index/logo-19.webp");
}
.index-partners__content-item--20 {
  background-image: url("../assets/images/index/logo-20.webp");
}

.hexa-features {
  position: relative;
}
.hexa-features__item {
  cursor: pointer;
}
.hexa-features__info {
  position: absolute;
}
.hexa-features__info--show {
  opacity: 1;
  z-index: 5;
}
.hexa-features__info--hide {
  opacity: 0;
  z-index: -1;
}
.hexa-features__info-cross {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../assets/icons/cross-gray.svg");
}

.scrollbar-y {
  overflow-y: scroll;
}
.scrollbar-y::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-clip: padding-box;
  background: rgba(0, 0, 0, 0.2);
}
.scrollbar-y::-webkit-scrollbar {
  width: 8px;
}
.scrollbar-y:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
}

.doc-list {
  display: flex;
  flex-wrap: wrap;
}

.doc-item {
  width: 33%;
  padding: 5px;
  margin-bottom: 20px;
}
.doc-item__image {
  width: 50px;
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../assets/icons/block-docs/ic_doc--green.webp");
  margin: 0 auto 15px;
}
.doc-item__title {
  text-align: center;
  margin-bottom: auto;
  font-size: 15px;
  text-decoration: underline;
  margin-bottom: 10px;
}
.doc-item__info {
  text-align: center;
}
.doc-item__link {
  max-width: 240px;
  margin: 0 auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  transition-duration: 0.3s;
}
.doc-item__link:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition-duration: 0.3s;
}
.doc-item__link:hover .doc-item__title {
  color: #4fd262;
}

.pay {
  display: flex;
  flex-direction: column;
  font-family: "PTSansNarrow", sans-serif;
}
.pay__row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.pay__title {
  width: 100%;
  padding: 10px 15px;
  background-color: #2ba93e;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  color: white;
}
.pay__item {
  height: 200px;
  width: 20%;
  border: 1px solid white;
  background-color: #f9f9fa;
  display: flex;
  padding: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.pay__item-title {
  font-weight: bold;
}
.pay__item--1 {
  background-image: url("../assets/icons/block-pay/pay-1.webp");
}
.pay__item--2 {
  background-image: url("../assets/icons/block-pay/pay-2.webp");
}
.pay__item--3 {
  background-image: url("../assets/icons/block-pay/pay-3.webp");
}
.pay__item--4 {
  background-image: url("../assets/icons/block-pay/pay-4.webp");
}
.pay__item--5 {
  background-image: url("../assets/icons/block-pay/pay-5.webp");
}
.pay__item--6 {
  width: 50%;
  background-position: 100%;
  background-image: url("../assets/icons/block-pay/pay-6.webp");
}

.main-clients .page-title {
  margin-bottom: 60px;
}
.main-clients .subs-section {
  padding: 30px 0;
}

.main-index .index-block {
  padding-top: 60px;
}

.product {
  display: flex;
  margin-bottom: 60px;
}
@media (max-width: 1200px) {
  .product {
    flex-wrap: wrap;
  }
}
.product-content {
  width: 100%;
}
.product-content--left {
  max-width: 750px;
  margin-right: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1200px) {
  .product-content--left {
    margin-bottom: 30px;
    max-width: none;
    width: 100%;
  }
}
.product-content--left-moved {
  width: 100%;
}
.product-content--right {
  max-width: 460px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
@media (max-width: 1200px) {
  .product-content--right {
    margin: 0 auto 30px;
    width: 500px;
    width: 100%;
  }
}
.product-content__top {
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 40px;
}
.product-content__top-list {
  display: flex;
  flex-wrap: wrap;
}
.product-content__top-list-item {
  padding: 10px 0;
}
.product-content__top-item {
  font-family: "PTSansNarrow", sans-serif;
  margin-right: 15px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  white-space: nowrap;
}
.product-content__top-item--active {
  color: #4fd262;
  padding-bottom: 9px;
  border-bottom: 3px solid #4fd262;
}
.product-content__image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 250px;
  margin-bottom: 20px;
}
.product-content__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.product-content__switch {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.product-content__switch-text {
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
  font-weight: bold;
}
.product-content__switch-item {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #dce0e8;
  margin: 0 3px;
  cursor: pointer;
  transition-duration: 0.3s;
}
.product-content__switch-item:hover {
  box-shadow: 0 2px 20px rgba(140, 154, 185, 0.25);
  transition-duration: 0.3s;
}
.product-content__switch-item::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  border-radius: 15px;
}
.product-content__switch-item--active {
  background-color: #4fd262;
}
.product-content__switch-item--white::before {
  background-color: white;
}
.product-content__switch-item--black::before {
  background-color: black;
}
.product-content__desc {
  font-size: 14px;
  text-align: center;
  color: #999999;
  margin-bottom: 40px;
}
.product-content__text {
  font-size: 14px;
  margin: 0 auto 40px;
  max-width: 800px;
}
@media (max-width: 1200px) {
  .product-content__text {
    text-align: center;
  }
}
.product-content__features {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1200px) {
  .product-content__features {
    justify-content: center;
    max-width: 760px;
    margin: 0 auto;
  }
}
.product-content__features-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95px;
  margin: 0 5px 20px;
}
.product-content__features-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  min-width: 80px;
  margin-bottom: 10px;
  position: relative;
}
.product-content__features-icon--image {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.product-content__features-icon--hexagon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.product-content__features-icon--hexagon-gray {
  background-image: url("../assets/icons/hexagon--gray.svg");
}
.product-content__features-icon--hexagon-white {
  background-image: url("../assets/icons/hexagon--white.svg");
}
.product-content__features-title {
  font-size: 11px;
  text-align: center;
}
.product-content__features-text {
  font-size: 14px;
  max-height: 100px;
  margin-right: 20px;
}
.product-content__features-info {
  height: 130px;
  background-color: white;
}
.product-content__features-info {
  display: flex;
  align-items: center;
  border: 1px solid #dce0e8;
  border-radius: 3px;
  padding: 10px;
  width: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
}
.product-content__features-info .product-content__features-icon {
  margin-bottom: 0;
  margin-right: 10px;
}
.product-content__features-info .product-content__features-icon--hexagon {
  background-image: url("../assets/icons/hexagon--green.svg");
}
.product-content__features-info .icon {
  fill: white;
}
.product-content__seven {
  display: flex;
  width: 100%;
  font-family: "PTSansNarrow", sans-serif;
  margin-top: 20px;
}
@media (max-width: 768px) {
  .product-content__seven {
    flex-wrap: wrap;
  }
}
.product-content__seven-block {
  padding: 15px 15px 0;
  position: relative;
  height: 200px;
}
.product-content__seven-block .icon {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.product-content__seven-block:nth-child(1) {
  background-color: #f9f9fa;
}
.product-content__seven-block:nth-child(2) {
  background-color: #dce0e8;
}
.product-content__seven-block:nth-child(3) {
  background-color: #4fd262;
  color: white;
}
.product-content__seven-block:nth-child(4) {
  background-color: #89959e;
  color: white;
}
.product-content__seven-block:nth-child(5) {
  background-color: #dce0e8;
}
.product-content__seven-block:nth-child(6) {
  background-color: #acd659;
  color: white;
}
.product-content__seven-block--1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  background-color: white !important;
}
.product-content__seven-block--1__num {
  font-weight: 900;
  margin-right: 50px;
  font-size: 288px;
  height: 290px;
  position: relative;
}
.product-content__seven-block--1__num span {
  font-size: 50px;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
  position: absolute;
  bottom: 85px;
  right: -120px;
  transform: rotate(90deg);
}
.product-content__seven-block--2 {
  background-color: #acd659 !important;
  height: 200px;
}
.product-content__seven-block--2__text {
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
}
.product-content__seven-title {
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
}
.product-content__seven--side {
  display: flex;
  min-width: 50%;
  height: 600px;
}
.product-content__seven--side-left {
  background-color: rgba(255, 0, 0, 0.173);
  flex-direction: column;
}
.product-content__seven--side-left .product-content__seven-block {
  width: 100%;
}
.product-content__seven--side-right {
  background-color: rgba(0, 68, 255, 0.173);
  display: flex;
  flex-wrap: wrap;
}
.product-content__seven--side-right .product-content__seven-block {
  width: 50%;
}
.product-content__bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 20px;
}
.product-content__bottom-link {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transition-duration: 0.3s;
  background-color: #3c3c3c;
  color: white;
  font-family: "PTSansNarrow", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  padding: 13px 20px 10px 35px;
  position: relative;
  cursor: pointer;
}
.product-content__bottom-link:hover {
  background-color: #3b434f;
  transition-duration: 0.3s;
}
.product-content__bottom-link:nth-child(1)::before {
  content: "<";
  position: absolute;
  top: 12px;
  left: 15px;
}
.product-content__bottom-link:nth-child(2) {
  padding: 13px 35px 10px 20px;
}
.product-content__bottom-link:nth-child(2)::before {
  content: ">";
  position: absolute;
  top: 12px;
  right: 15px;
}
.product-dops {
  max-width: 460px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin: 20px auto;
}
.product-dops__more {
  margin: 20px 0 0 0;
  display: none;
  cursor: pointer;
}
.product-dops__btn {
  padding: 13px 35px 10px 20px;
  font-family: "PTSansNarrow", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-dops__btn .icon-base--arrow {
  margin-left: 10px;
  fill: white;
}
.product-dops__top {
  padding: 30px;
  background-color: #f9f9fa;
}
@media (max-width: 768px) {
  .product-dops__top {
    padding: 20px;
  }
}
@media (max-width: 480px) {
  .product-dops__top {
    padding: 15px;
  }
}
.product-dops__title {
  font-weight: 700;
  margin-bottom: 10px;
}
.product-dops__subtitle {
  font-size: 13px;
  margin-bottom: 20px;
}
.product-dops__prices {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.product-dops__prices-item:last-child .products-dops-prices-item-title {
  text-align: right;
}
.product-dops__prices-title {
  font-size: 9px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: -0.03em;
}
.product-dops__prices-price {
  font-size: 36px;
  font-weight: 700;
}
@media (max-width: 768px) {
  .product-dops__prices-price {
    font-size: 30px;
  }
}
@media (max-width: 480px) {
  .product-dops__prices-price {
    font-size: 24px;
  }
}
.product-dops__list {
  padding: 30px;
  /* Изменение внешнего вида флажка при выборе */
}
@media (max-width: 768px) {
  .product-dops__list {
    padding: 20px;
  }
}
@media (max-width: 480px) {
  .product-dops__list {
    padding: 15px;
  }
}
.product-dops__list-top {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 7px;
}
.product-dops__list-top-title {
  font-size: 9px;
  margin-right: 5px;
  max-width: 45px;
  width: 100%;
  font-weight: 300;
  text-align: right;
  text-transform: uppercase;
}
.product-dops__list-top-title:last-child {
  margin-right: 0;
}
.product-dops__list-top-title--setup {
  max-width: 50px;
}
.product-dops__list-title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}
.product-dops__list ul {
  padding: 0;
}
.product-dops__list ul li {
  list-style: none;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}
.product-dops__list-label {
  display: inline-block;
  position: relative;
  border: 1px solid #b1bbcc;
  border-radius: 1px;
  box-shadow: none;
  vertical-align: -4px;
  width: 20px;
  height: 20px;
  margin: 0 5px 0 0;
  background: white;
  cursor: pointer;
  flex-shrink: 0;
}
.product-dops__list-checkbox {
  display: none;
}
.product-dops__list-check {
  width: 12px;
  height: 8px;
  margin: 3px 0 0 3px;
  border-left: 3px solid #4fd262;
  border-bottom: 3px solid #4fd262;
  transform: rotate(-50deg);
  display: none;
}
.product-dops__list .product-dops__list-checkbox:checked + .product-dops__list-check {
  display: block;
}
.product-dops__list-name {
  margin-right: auto;
}
.product-dops__list-price {
  margin-right: 5px;
  max-width: 45px;
  width: 100%;
  text-align: right;
}
.product-dops__list-price:last-child {
  margin-right: 0;
}
.product-dops__list-price--setup {
  max-width: 50px;
}
.product-dops__list--hidden {
  height: 0;
  overflow: hidden;
  padding: 0;
}
.product-dops--sticky {
  top: 100px;
  position: sticky;
  margin: 0;
}

.product-content--left .index-system {
  padding: 20px 0;
  margin-bottom: 20px;
}
.product-content--left .index-system .index-block-top {
  margin-bottom: 30px;
  margin-left: 30px;
}
.product-content--left .index-system .index-block-top::before {
  height: 30px;
  margin-right: 20px;
}
.product-content--left .index-system .index-block-top-title {
  font-size: 26px;
}
.product-content--left .index-system .index-block-top-subtitle {
  font-size: 16px;
}
.product-content--left .index-system .index-system__content {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 10px;
}
.product-content--left .index-system .index-system__content-item {
  width: calc(33% - 20px);
  margin: 0 10px;
}
@media (max-width: 768px) {
  .product-content--left .index-system .index-system__content {
    flex-wrap: nowrap;
  }
  .product-content--left .index-system .index-system__content .index-system__content-item {
    width: 200px;
  }
}

.index-system--wework .index-system__content {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.index-system--wework .index-system__content-item {
  width: calc(25% - 20px);
  margin: 0 10px;
}
@media (max-width: 980px) {
  .index-system--wework .index-system__content {
    flex-wrap: nowrap;
  }
}

.product {
  display: flex;
}
.product-content__features {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}
.product-content__features-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95px;
  margin: 0 5px 20px;
}
.product-content__features-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  margin-bottom: 10px;
  position: relative;
}
.product-content__features-icon--image {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.product-content__features-icon--hexagon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.product-content__features-icon--hexagon-gray {
  background-image: url("../assets/icons/hexagon--gray.svg");
}
.product-content__features-icon--hexagon-white {
  background-image: url("../assets/icons/hexagon--white.svg");
}
.product-content__features-title {
  font-size: 11px;
  text-align: center;
}
.product-content__bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 20px;
}
.product-content__bottom-link {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transition-duration: 0.3s;
  background-color: #3c3c3c;
  color: white;
  font-family: "PTSansNarrow", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  padding: 13px 20px 10px 35px;
  position: relative;
  cursor: pointer;
}
.product-content__bottom-link:hover {
  background-color: #3b434f;
  transition-duration: 0.3s;
}
.product-content__bottom-link:nth-child(1)::before {
  content: "<";
  position: absolute;
  top: 12px;
  left: 15px;
}
.product-content__bottom-link:nth-child(2) {
  padding: 13px 35px 10px 20px;
}
.product-content__bottom-link:nth-child(2)::before {
  content: ">";
  position: absolute;
  top: 12px;
  right: 15px;
}

.product--hexagray {
  display: flex;
}
.product--hexagray-content__features {
  display: flex;
  flex-wrap: wrap;
}
.product--hexagray-content__features-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95px;
  margin: 0 5px 20px;
}
.product--hexagray-content__features-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  margin-bottom: 10px;
  position: relative;
}
.product--hexagray-content__features-icon--image {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.product--hexagray-content__features-icon--hexagon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 80px;
  height: 80px;
  background-image: url("../assets/icons/hexagon--gray.svg");
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.product--hexagray-content__features-title {
  font-size: 11px;
  text-align: center;
}
.product--hexagray-content__bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 20px;
}
.product--hexagray-content__bottom-link {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transition-duration: 0.3s;
  background-color: #3c3c3c;
  color: white;
  font-family: "PTSansNarrow", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  padding: 13px 20px 10px 35px;
  position: relative;
  cursor: pointer;
}
.product--hexagray-content__bottom-link:hover {
  background-color: #3b434f;
  transition-duration: 0.3s;
}
.product--hexagray-content__bottom-link:nth-child(1)::before {
  content: "<";
  position: absolute;
  top: 12px;
  left: 15px;
}
.product--hexagray-content__bottom-link:nth-child(2) {
  padding: 13px 35px 10px 20px;
}
.product--hexagray-content__bottom-link:nth-child(2)::before {
  content: ">";
  position: absolute;
  top: 12px;
  right: 15px;
}

.product-content__type-work {
  background-color: #f9f9fa;
  padding: 60px 0;
}
.product-content__type-work-title {
  margin-bottom: 40px;
  font-size: 24px;
  text-align: center;
  font-weight: bold;
}
.product-content__type-work .hexa-features {
  display: flex;
  justify-content: center;
}
.product-content__type-work .hexa-features .product-content__features-icon {
  width: 140px;
  height: 140px;
}
.product-content__type-work .hexa-features .product-content__features-icon--hexagon {
  width: 140px;
  height: 140px;
}
.product-content__type-work .hexa-features .product-content__features-item {
  width: 200px;
}
.product-content__type-work .hexa-features .product-content__features-title {
  font-size: 15px;
}
.product-content__type-work .hexa-features .product-content__features-info {
  width: 100%;
  height: 100%;
  padding: 10px 30px;
}
.product-content__type-work .hexa-features__info .icon-png {
  width: 90px;
  min-width: 90px;
  height: 90px;
}
.product-content__type-work .hexa-features__info .product-content__features-text {
  font-size: 16px;
}
.product-content__type-work .hexa-features__info-cross {
  width: 20px;
  height: 20px;
  top: 15px;
  right: 15px;
}

.product-content__tech-skud {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../assets/images/call-action-techskud.webp");
  padding: 50px 0 150px;
  color: white;
  margin-bottom: 60px;
}
.product-content__tech-skud-title {
  font-size: 36px;
  margin-bottom: 30px;
  font-weight: bold;
  text-align: center;
}
@media (max-width: 768px) {
  .product-content__tech-skud-title {
    font-size: 30px;
  }
}
@media (max-width: 480px) {
  .product-content__tech-skud-title {
    font-size: 24px;
  }
}
.product-content__tech-skud-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.product-content__tech-skud-item {
  width: 285px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.product-content__tech-skud-item-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 82px;
  height: 82px;
  margin-bottom: 10px;
}
.product-content__tech-skud-item-image--1 {
  background-image: url("../assets/icons/products/product-tech-skud/ic_tech-skud-1.png");
}
.product-content__tech-skud-item-image--2 {
  background-image: url("../assets/icons/products/product-tech-skud/ic_tech-skud-2.png");
}
.product-content__tech-skud-item-image--3 {
  background-image: url("../assets/icons/products/product-tech-skud/ic_tech-skud-3.png");
}
.product-content__tech-skud-item-image--4 {
  background-image: url("../assets/icons/products/product-tech-skud/ic_tech-skud-4.png");
}
.product-content__tech-skud-item-title {
  text-align: center;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 20px;
}
.product-content__tech-skud .hexa-features__info--block {
  width: 100%;
  margin-top: 50px;
  position: relative;
}
.product-content__tech-skud .hexa-features__info {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

.smart-house {
  position: relative;
  max-width: 1366px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.smart-house__info {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  font-size: 14px;
  line-height: 1.4;
  top: 0;
  transition: 0.3s;
}
@media (max-width: 768px) {
  .smart-house__info {
    display: none;
  }
}
.smart-house__info-cross {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../assets/icons/cross-white.svg");
}
.smart-house__title {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
  padding: 20px 5px;
  min-width: 170px;
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
  transition: 0.3s ease;
  backface-visibility: hidden;
  will-change: transform;
  background-color: rgba(79, 210, 98, 0.9);
}
.smart-house__cover {
  right: 0;
  height: 100%;
  left: 0;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 2;
  transform: scale(0);
  transition: 0.3s;
  background-color: rgba(79, 210, 98, 0.9);
}
.smart-house__block {
  position: relative;
  width: 25%;
  height: 219px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media (max-width: 768px) {
  .smart-house__block {
    width: 50%;
  }
}
.smart-house__block--vertical {
  height: 438px;
  flex: 0 0 auto;
}
@media (max-width: 768px) {
  .smart-house__block--vertical {
    height: 219px;
  }
}
.smart-house__block--square {
  position: relative;
  flex: 0 0 auto;
}
.smart-house__block--horizontal {
  position: absolute;
  left: 25%;
  top: 219px;
  height: 219px;
  width: 50%;
}
@media (max-width: 768px) {
  .smart-house__block--horizontal {
    position: relative;
    left: 0;
    top: 0;
    height: 219px;
    width: 100%;
  }
}
.smart-house__block--big {
  height: 498px;
  width: 50%;
}
@media (max-width: 768px) {
  .smart-house__block--big {
    height: 219px;
    width: 100%;
  }
}
.smart-house__block--big .smart-house__title {
  font-size: 25px;
}
.smart-house__block--big .smart-house__info {
  font-size: 16px;
}
.smart-house__block--1 {
  background-image: url("../assets/images/smart-house/smart-house--1.webp");
}
.smart-house__block--1 .smart-house__info {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.smart-house__block--2 {
  background-image: url("../assets/images/smart-house/smart-house--2.webp");
}
.smart-house__block--2 .smart-house__info {
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
}
.smart-house__block--3 {
  background-image: url("../assets/images/smart-house/smart-house--3.webp");
}
.smart-house__block--3 .smart-house__info {
  top: 0;
  right: 0;
  width: 200%;
  height: 100%;
}
.smart-house__block--4 {
  background-image: url("../assets/images/smart-house/smart-house--4.webp");
}
.smart-house__block--4 .smart-house__info {
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.smart-house__block--5 {
  background-image: url("../assets/images/smart-house/smart-house--5.webp");
}
.smart-house__block--5 .smart-house__info {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.smart-house__block--6 {
  background-image: url("../assets/images/smart-house/smart-house--6.webp");
}
.smart-house__block--6 .smart-house__info {
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
}
.smart-house__block--7 {
  background-image: url("../assets/images/smart-house/smart-house--7.webp");
}
.smart-house__block--7 .smart-house__info {
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
}
.smart-house__block--8 {
  background-image: url("../assets/images/smart-house/smart-house--8.webp");
}
.smart-house__block--8 .smart-house__info {
  top: 0;
  right: 0;
  width: 200%;
  height: 100%;
}
.smart-house__block--9 {
  background-image: url("../assets/images/smart-house/smart-house--9.webp");
}
.smart-house__block--9 .smart-house__info {
  top: 0;
  right: 0;
  width: 200%;
  height: 100%;
}
.smart-house__block--10 {
  background-image: url("../assets/images/smart-house/smart-house--10.webp");
}
.smart-house__block--10 .smart-house__info {
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.smart-house__block--11 {
  background-image: url("../assets/images/smart-house/smart-house--11.webp");
}
.smart-house__block--11 .smart-house__info {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.smart-house__block--active .smart-house__cover {
  transform: scale(1);
  transition: 0.3s;
}
.smart-house__block--active .smart-house__title {
  background-color: transparent;
}
.smart-house__block--active .smart-house__info {
  transition: 0.3s;
  z-index: 4;
  opacity: 1;
}
.smart-house__features {
  display: flex;
  width: 100%;
  margin: 30px auto;
}
@media (max-width: 480px) {
  .smart-house__features {
    flex-wrap: wrap;
  }
}
.smart-house__features-item {
  width: 33.3%;
  display: flex;
  align-items: center;
  font-weight: bold;
}
@media (max-width: 768px) {
  .smart-house__features-item {
    font-size: 14px;
  }
}
@media (max-width: 480px) {
  .smart-house__features-item {
    width: 100%;
    margin-bottom: 10px;
  }
}
.smart-house__icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 82px;
  height: 82px;
  margin-right: 10px;
  flex-shrink: 0;
}
@media (max-width: 980px) {
  .smart-house__icon {
    width: 60px;
    height: 60px;
  }
}
.smart-house__icon--1 {
  background-image: url("../assets/icons/components/smart-house/ic_smart-house--1.webp");
}
.smart-house__icon--2 {
  background-image: url("../assets/icons/components/smart-house/ic_smart-house--2.webp");
}
.smart-house__icon--3 {
  background-image: url("../assets/icons/components/smart-house/ic_smart-house--3.webp");
}
.smart-house__solo {
  width: 100%;
  position: relative;
}
.smart-house__solo-img {
  width: 100%;
}
.smart-house__solo-icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 50px;
  background-color: rgb(79, 210, 98);
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
}
@media (max-width: 768px) {
  .smart-house__solo-icon {
    width: 30px;
    height: 30px;
  }
}
.smart-house__solo-icon:after {
  content: "";
  width: 65px;
  height: 65px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(79, 210, 98, 0.3);
}
@media (max-width: 768px) {
  .smart-house__solo-icon:after {
    width: 40px;
    height: 40px;
  }
}
.smart-house__solo-icon--1 {
  left: 87%;
  top: 23%;
  background-image: url("../assets/icons/components/smart-house/ic_smart-house__solo--1.webp");
}
.smart-house__solo-icon--2 {
  left: 83.6% !important;
  top: 52% !important;
  background-image: url("../assets/icons/components/smart-house/ic_smart-house__solo--2.webp");
}
.smart-house__solo-icon--3 {
  left: 68.6% !important;
  top: 17% !important;
  background-image: url("../assets/icons/components/smart-house/ic_smart-house__solo--3.webp");
}
.smart-house__solo-icon--4 {
  left: 69.9% !important;
  top: 57% !important;
  background-image: url("../assets/icons/components/smart-house/ic_smart-house__solo--4.webp");
}
.smart-house__solo-icon--5 {
  left: 54% !important;
  top: 71% !important;
  background-image: url("../assets/icons/components/smart-house/ic_smart-house__solo--5.webp");
}
.smart-house__solo-icon--6 {
  left: 53% !important;
  top: 39% !important;
  background-image: url("../assets/icons/components/smart-house/ic_smart-house__solo--6.webp");
}
.smart-house__solo-icon--7 {
  left: 37% !important;
  top: 37% !important;
  background-image: url("../assets/icons/components/smart-house/ic_smart-house__solo--7.webp");
}
.smart-house__solo-icon--8 {
  left: 18.6% !important;
  top: 56% !important;
  background-image: url("../assets/icons/components/smart-house/ic_smart-house__solo--8.webp");
}

.mobile-app {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 980px) {
  .mobile-app {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (max-width: 480px) {
  .mobile-app {
    flex-direction: column;
  }
}
.mobile-app__block {
  height: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.mobile-app__block:nth-child(1) {
  width: 345px;
}
.mobile-app__block:nth-child(1) .mobile-app__title,
.mobile-app__block:nth-child(1) .mobile-app__text {
  text-align: right;
}
@media (max-width: 980px) {
  .mobile-app__block:nth-child(1) {
    order: 3;
    width: calc(50% - 20px);
    margin-right: 15px;
    height: auto;
    align-items: flex-end;
  }
}
@media (max-width: 480px) {
  .mobile-app__block:nth-child(1) {
    order: 2;
    width: 100%;
    margin: 0 auto 20px;
    align-items: center;
  }
  .mobile-app__block:nth-child(1) .mobile-app__title,
  .mobile-app__block:nth-child(1) .mobile-app__text {
    text-align: center;
  }
}
.mobile-app__block:nth-child(2) {
  width: 170px;
  flex-shrink: 0;
}
@media (max-width: 980px) {
  .mobile-app__block:nth-child(2) {
    order: 1;
    width: calc(50% - 20px);
    margin-right: 15px;
    align-items: flex-end;
  }
}
@media (max-width: 480px) {
  .mobile-app__block:nth-child(2) {
    order: 1;
    width: 100%;
    margin: 0 auto 20px;
  }
  .mobile-app__block:nth-child(2) .mobile-app__image {
    margin: 0 auto;
  }
}
.mobile-app__block:nth-child(3) {
  width: 170px;
  flex-shrink: 0;
}
@media (max-width: 980px) {
  .mobile-app__block:nth-child(3) {
    order: 2;
    width: calc(50% - 20px);
    margin-left: 15px;
    align-items: flex-start;
  }
}
@media (max-width: 480px) {
  .mobile-app__block:nth-child(3) {
    order: 3;
    width: 100%;
    margin: 0 auto 20px;
  }
  .mobile-app__block:nth-child(3) .mobile-app__image {
    margin: 0 auto;
  }
}
.mobile-app__block:nth-child(4) {
  width: 345px;
  align-items: flex-start;
}
.mobile-app__block:nth-child(4) .mobile-app__title {
  text-align: left;
}
.mobile-app__block:nth-child(4) .mobile-app__text {
  text-align: left;
}
@media (max-width: 980px) {
  .mobile-app__block:nth-child(4) {
    order: 4;
    width: calc(50% - 20px);
    margin-left: 15px;
    height: auto;
    align-items: flex-start;
  }
}
@media (max-width: 480px) {
  .mobile-app__block:nth-child(4) {
    order: 4;
    width: 100%;
    margin: 0 auto 20px;
    align-items: center;
  }
  .mobile-app__block:nth-child(4) .mobile-app__title,
  .mobile-app__block:nth-child(4) .mobile-app__text {
    text-align: center;
  }
}
.mobile-app__title {
  font-size: 28px;
  margin-bottom: 20px;
}
.mobile-app__image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  max-width: 170px;
}
.mobile-app__image--1 {
  background-image: url("../assets/images/mobile-app/mobile-app--1.webp");
}
.mobile-app__image--2 {
  background-image: url("../assets/images/mobile-app/mobile-app--2.webp");
}

.smart-house__block--active.smart-house__block--1 .smart-house__info {
  left: 100%;
}

.smart-house__block--active.smart-house__block--2 .smart-house__info,
.smart-house__block--active.smart-house__block--3 .smart-house__info {
  top: 100%;
}

.smart-house__block--active.smart-house__block--4 .smart-house__info {
  right: 100%;
}

.smart-house__block--active.smart-house__block--5 .smart-house__info,
.smart-house__block--active.smart-house__block--6 .smart-house__info,
.smart-house__block--active.smart-house__block--7 .smart-house__info,
.smart-house__block--active.smart-house__block--8 .smart-house__info,
.smart-house__block--active.smart-house__block--9 .smart-house__info {
  top: -100%;
}

.smart-house__block--active.smart-house__block--10 .smart-house__info {
  right: -100%;
}

.smart-house__block--active.smart-house__block--11 .smart-house__info {
  left: -100%;
}

.product-content__type-work.product-content__type-work--eto .product-content__type-work-title {
  font-size: 34px;
}
.product-content__type-work.product-content__type-work--eto .product-content__features-item {
  width: calc(33% - 10px);
}
@media (max-width: 768px) {
  .product-content__type-work.product-content__type-work--eto .product-content__features-item {
    width: calc(50% - 10px);
  }
}
@media (max-width: 480px) {
  .product-content__type-work.product-content__type-work--eto .product-content__features-item {
    width: calc(100% - 10px);
  }
}
.product-content__type-work.product-content__type-work--eto .product-content__features-icon {
  width: 200px;
  height: 200px;
}
.product-content__type-work.product-content__type-work--eto .product-content__features-icon--hexagon {
  width: 200px;
  height: 200px;
}
@media (max-width: 768px) {
  .product-content__type-work.product-content__type-work--eto .product-content__features-icon {
    width: 180px;
    height: 180px;
  }
  .product-content__type-work.product-content__type-work--eto .product-content__features-icon--hexagon {
    width: 180px;
    height: 180px;
  }
}
@media (max-width: 480px) {
  .product-content__type-work.product-content__type-work--eto .product-content__features-icon {
    width: 160px;
    height: 160px;
  }
  .product-content__type-work.product-content__type-work--eto .product-content__features-icon--hexagon {
    width: 160px;
    height: 160px;
  }
}
.product-content__type-work.product-content__type-work--eto .product-content__features-title {
  font-size: 18px;
  max-width: 270px;
  margin-top: 20px;
}

.main-products .index-block-top {
  margin-left: 60px;
}
.main-products .index-system__content {
  padding-left: 40px;
}
@media (max-width: 980px) {
  .main-products .index-system__content {
    flex-wrap: nowrap;
    padding-left: 0;
  }
}
.main-products .index-about__text {
  padding-left: 60px;
}

.main-contacts .map {
  width: 100%;
  height: 400px;
  background-color: #dce0e8;
  margin-top: -100px;
  z-index: 1;
  position: relative;
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .main-contacts .map {
    height: 300px;
    margin-top: 30px;
  }
}
.main-contacts .map-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.contacts-block {
  position: relative;
  font-size: 13px;
  line-height: 1.5;
}
.contacts-block .text-bold {
  font-size: 16px;
  margin-bottom: 10px;
}
.contacts-block .contacts-block__info {
  z-index: 10;
  padding: 30px 30px 15px;
  max-width: 440px;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  font-size: 13px;
  position: relative;
  z-index: 10;
  background-color: white;
}
@media (max-width: 768px) {
  .contacts-block .contacts-block__info {
    padding: 15px;
  }
}
.contacts-block .contacts-block__info-cols {
  display: flex;
}
.contacts-block .contacts-block__info-col {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.contacts-block .contacts-block__info-link {
  margin-bottom: 10px;
  cursor: pointer;
}
.contacts-block .contacts-block__info-text {
  max-height: 300px;
  overflow: scroll;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: 15px 30px 10px;
  background-color: #4fd262;
  height: auto;
  color: white;
  top: 0;
  opacity: 0;
  z-index: -1;
  transition-duration: 0.3s;
}
.contacts-block .contacts-block__info-text .btn {
  background-color: white;
  color: #4fd262;
  font-weight: bold;
  font-family: "PTSansNarrow", sans-serif;
  margin-bottom: 10px;
  display: inline-block;
  box-shadow: none;
}
.contacts-block .contacts-block__info-text .btn:hover {
  background-color: #dce0e8;
}
.contacts-block .contacts-block__info-text h2 {
  margin-top: 0;
  margin-bottom: 10px;
}
.contacts-block .contacts-block__info-text--active {
  top: 100%;
  opacity: 1;
  z-index: 1;
  transition-duration: 0.3s;
}

.contacts-disclaimer {
  max-width: 760px;
  margin: 0 auto;
  padding-bottom: 40px;
}
@media (max-width: 768px) {
  .contacts-disclaimer {
    font-size: 14px;
  }
}

.main-blog-item {
  font-size: 14px;
}
.main-blog-item .page-title {
  margin-bottom: 0;
}

.article-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.article-item {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 0 15px 30px;
  background-color: #f9f9fa;
  transition-duration: 0.3s;
}
.article-item:hover {
  transition-duration: 0.3s;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
.article-item-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #4fd262;
  width: 100%;
  height: 210px;
}
.article-item-title {
  font-size: 14px;
  border: 10px solid transparent;
  min-height: 70px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.article-text {
  line-height: 1.7;
  margin-bottom: 30px;
}

.article-recomandations {
  display: flex;
  margin: 0 -20px 20px;
  flex-wrap: wrap;
}
.article-recomandations-title {
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  font-family: "PTSansNarrow", sans-serif;
  text-transform: uppercase;
  width: 100%;
  margin: 0 20px 20px;
}
.article-recomandations-item {
  margin: 0 20px 20px;
  width: calc(50% - 40px);
  height: 150px;
  padding: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  font-family: "PTSansNarrow", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.article-recomandations-item-title {
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.article-recomandations-item-price {
  font-size: 24px;
  color: #4fd262;
}
.article-recomandations-item-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #4fd262;
  position: absolute;
  width: 150px;
  height: 150px;
  top: 0;
  right: 0;
}

.career-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.career-item {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 0 15px 30px;
  background-color: #f9f9fa;
}
.career-item-content {
  padding: 15px;
  display: flex;
  flex-direction: column;
}
.career-item-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #4fd262;
  width: 100%;
  height: 255px;
}
.career-item-title {
  font-family: "PTSansNarrow", sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.career-item-text {
  font-size: 14px;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.career-item-link {
  font-size: 14px;
  width: 180px;
  color: white;
  font-family: "PTSansNarrow", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

.career-text {
  margin-bottom: 10px;
  line-height: 1.2;
  font-size: 18px;
  line-height: 1.7;
}

.career-features {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 20px;
}
.career-features-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 250px;
  margin: 0 10px 20px;
}
.career-features-item-icon {
  width: 164px;
  height: 164px;
  background-color: #7f7f7f;
  margin-bottom: 20px;
}
.career-features-item-title {
  font-size: 14px;
  text-align: center;
  line-height: 1.5;
}

.page-form {
  display: flex;
  margin-top: 40px;
}
.page-form-block {
  display: flex;
  flex-direction: column;
  width: calc(50% - 10px);
  margin-right: auto;
}
.page-form-block:last-child {
  margin-right: 0;
  align-items: flex-end;
}
.page-form-input {
  padding: 11px 20px 9px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  font-size: 15px;
  border: none;
  margin-bottom: 20px;
  width: 100%;
}
.page-form-input::placeholder {
  color: #999999;
}
.page-form .btn {
  font-family: "PTSansNarrow", sans-serif;
  font-weight: bold;
  width: 120px;
}

.pz-main--wrapper {
  height: 550px;
  background-color: #f9f9fa;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
@media (max-width: 768px) {
  .pz-main--wrapper {
    height: 400px;
  }
}
@media (max-width: 480px) {
  .pz-main--wrapper {
    height: 300px;
  }
}
.pz-main--left {
  display: flex;
  flex-direction: column;
  padding: 40px;
  height: 100%;
  width: 50%;
}
@media (max-width: 768px) {
  .pz-main--left {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .pz-main--left {
    padding: 20px;
  }
}
.pz-main__title {
  font-size: 70px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-family: "PTSansNarrow", sans-serif;
}
@media (max-width: 768px) {
  .pz-main__title {
    font-size: 60px;
    margin-top: 0;
  }
}
@media (max-width: 480px) {
  .pz-main__title {
    font-size: 45px;
  }
}
.pz-main__subtitle {
  margin-bottom: auto;
}
.pz-main--right {
  width: 50%;
  height: 100%;
  flex-shrink: 0;
}
@media (max-width: 768px) {
  .pz-main--right {
    display: none;
  }
}
.pz-main__img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/physical-security/physical-security__main.webp");
  background-position: center left;
}
.pz-main__stats {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .pz-main__stats {
    flex-wrap: wrap;
  }
}
.pz-main__stats-item {
  background-color: #f9f9fa;
  border-radius: 8px;
  width: 24%;
  padding: 16px;
}
@media (max-width: 768px) {
  .pz-main__stats-item {
    width: 49%;
    margin-bottom: 10px;
  }
}
@media (max-width: 480px) {
  .pz-main__stats-item {
    padding: 10px;
  }
}
@media (max-width: 768px) {
  .pz-main__stats .pz-main__title {
    font-size: 40px;
  }
  .pz-main__stats .pz-main__subtitle {
    font-size: 16px;
  }
}
@media (max-width: 480px) {
  .pz-main__stats .pz-main__title {
    font-size: 30px;
  }
  .pz-main__stats .pz-main__subtitle {
    font-size: 13px;
  }
}
.pz-main .btn--pz {
  max-width: 230px;
}
.pz-main .btn--pz .icon-base--arrow {
  margin-left: 10px;
  fill: white;
}

.swiper-button-prev, .pz-decisions .swiper-button-next {
  background-color: #F9F9FA;
  left: 50%;
  width: 56px;
  height: 56px;
  transform: translate(-110%, 0);
  border-radius: 50%;
  top: auto;
  bottom: 0px;
}
.swiper-button-prev::after, .pz-decisions .swiper-button-next::after {
  font-size: 21px;
  color: #54B367;
}

.pz-decisions {
  padding: 120px 0 0;
}
@media (max-width: 980px) {
  .pz-decisions {
    padding: 90px 0 0;
  }
}
@media (max-width: 768px) {
  .pz-decisions {
    padding: 60px 0 0;
  }
}
.pz-decisions__top {
  display: flex;
}
@media (max-width: 980px) {
  .pz-decisions__top {
    flex-wrap: wrap;
  }
}
.pz-decisions__top .pz-main__title {
  font-size: 52px;
  line-height: 1em;
  flex-shrink: 0;
}
@media (max-width: 980px) {
  .pz-decisions__top .pz-main__title {
    font-size: 46px;
    flex-shrink: 1;
  }
}
@media (max-width: 768px) {
  .pz-decisions__top .pz-main__title {
    font-size: 42px;
  }
}
@media (max-width: 480px) {
  .pz-decisions__top .pz-main__title {
    font-size: 34px;
  }
}
.pz-decisions__top .pz-main__subtitle {
  max-width: 325px;
  margin-left: auto;
  color: #89959E;
}
@media (max-width: 768px) {
  .pz-decisions__top .pz-main__subtitle {
    margin-left: 0;
  }
}
.pz-decisions__slider {
  padding: 60px 0 80px !important;
}
.pz-decisions__slider-btn {
  background-color: white;
  color: #54B367;
}
.pz-decisions__slider-btn:hover {
  background-color: #22272E;
  color: white;
}
.pz-decisions__slider-item {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pz-decisions__slider-plus {
  border-radius: 50%;
  width: 56px;
  height: 56px;
  background-color: rgba(255, 255, 255, 0.2);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../assets/icons/ic_plus--white.svg");
  background-size: 24px 24px;
  position: relative;
  z-index: 5;
  transition-duration: 0.3s;
  cursor: pointer;
}
.pz-decisions__slider-title {
  font-size: 28px;
  font-weight: bold;
  font-family: "PTSansNarrow", sans-serif;
}
.pz-decisions__slider-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 16px;
  background-color: #54B367;
  color: white;
  transition-duration: 0.3s;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pz-decisions__slider-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 352px;
  width: 100%;
  position: relative;
  padding: 20px;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  border-radius: 8px;
}
.pz-decisions__slider-img--1 {
  background-image: url("../assets/images/physical-security/physical-security__decisions--1.webp");
}
.pz-decisions__slider-img--2 {
  background-image: url("../assets/images/physical-security/physical-security__decisions--2.webp");
}
.pz-decisions__slider-img--3 {
  background-image: url("../assets/images/physical-security/physical-security__decisions--3.webp");
}
.pz-decisions__slider-img--4 {
  background-image: url("../assets/images/physical-security/physical-security__decisions--1.webp");
}
.pz-decisions__slider-img--show .pz-decisions__slider-cover {
  top: 0;
}
.pz-decisions__slider-img--show .pz-decisions__slider-plus {
  transform: rotate(-45deg);
}
.pz-decisions .btn--pz {
  max-width: 160px;
  background-color: white;
  color: #54B367;
}
.pz-decisions .btn--pz .icon-base--arrow {
  fill: #54B367;
  margin-left: 10px;
  transition-duration: 0.3s;
}
.pz-decisions .btn--pz:hover {
  background-color: #22272E;
  color: white;
}
.pz-decisions .btn--pz:hover .icon-base--arrow {
  fill: white;
}
.pz-decisions .swiper-button-next {
  left: auto;
  right: 50%;
  transform: translate(110%, 0);
}
.pz-decisions .swiper-button-disabled {
  opacity: 0.7;
}

.btn--pz {
  height: 56px;
  padding: 5px 32px;
  border-radius: 4px;
  max-width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pz-call {
  padding: 120px 0;
  color: white;
}
@media (max-width: 980px) {
  .pz-call {
    padding: 90px 0;
  }
}
@media (max-width: 768px) {
  .pz-call {
    padding: 60px 0;
  }
}
.pz-call__pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
  object-fit: fill;
  z-index: 1;
}
.pz-call__bg {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.pz-call .container--width {
  position: relative;
  min-height: 511px;
  padding: 60px;
}
@media (max-width: 768px) {
  .pz-call .container--width {
    min-height: 400px;
  }
}
@media (max-width: 480px) {
  .pz-call .container--width {
    padding: 30px;
    min-height: 340px;
  }
}
.pz-call__content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
  height: 100%;
  min-height: 391px;
}
@media (max-width: 768px) {
  .pz-call__content {
    min-height: 400px;
  }
}
@media (max-width: 480px) {
  .pz-call__content {
    min-height: 340px;
  }
}
.pz-call__title {
  font-family: "PTSansNarrow", sans-serif;
  font-weight: bold;
  font-size: 52px;
  margin-bottom: 10px;
  text-align: center;
  max-width: 900px;
}
@media (max-width: 768px) {
  .pz-call__title {
    font-size: 42px;
  }
}
@media (max-width: 480px) {
  .pz-call__title {
    font-size: 30px;
  }
}
.pz-call__subtitle {
  text-align: center;
  margin-bottom: auto;
}
.pz-call .btn--pz {
  background-color: white;
  color: #54B367;
  margin-top: 10px;
}
.pz-call .btn--pz .icon-base--phone {
  fill: #54B367;
  margin-left: 10px;
}
.pz-call .btn--pz:hover {
  background-color: #22272E;
  color: white;
}
.pz-call .btn--pz:hover .icon-base--phone {
  fill: white;
}

.pz-prices {
  padding-bottom: 120px;
}
@media (max-width: 980px) {
  .pz-prices {
    padding-bottom: 90px;
  }
}
@media (max-width: 768px) {
  .pz-prices {
    padding-bottom: 60px;
  }
}
.pz-prices .pz-main__title {
  text-align: center;
  margin: 0 auto 60px;
}
.pz-prices__list {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .pz-prices__list {
    flex-wrap: wrap;
  }
}
.pz-prices__item {
  padding: 24px;
  background-color: #F9F9FA;
  display: flex;
  flex-direction: column;
  width: 24%;
  border-radius: 8px;
}
@media (max-width: 768px) {
  .pz-prices__item {
    width: 49%;
    margin-bottom: 20px;
  }
}
@media (max-width: 480px) {
  .pz-prices__item {
    width: 100%;
    margin-bottom: 20px;
  }
}
.pz-prices__item-title {
  font-family: "PTSansNarrow", sans-serif;
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom: 1px solid #DCE0E8;
  text-align: center;
  min-height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pz-prices__item-list {
  font-family: "Open Sans", sans-serif;
  padding-bottom: 40px;
  margin-bottom: auto;
}
.pz-prices__item-list-item {
  display: flex;
}
.pz-prices__item-list-item span {
  white-space: nowrap;
}
.pz-prices__item .icon-base--check {
  fill: #89959E;
  margin-right: 10px;
}
.pz-prices__item .btn--pz {
  max-width: 100%;
  max-height: 44px;
}
.pz-prices__item--green {
  position: relative;
  background-color: #54B367;
  color: white;
}
.pz-prices__item--green::before {
  content: "популярный";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 28px;
  padding: 8px 16px;
  color: white;
  background-color: #54B367;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 100px;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
}
.pz-prices__item--green .icon-base--check {
  fill: white;
}
.pz-prices__item--green .btn--pz {
  background-color: white;
  color: #54B367;
}
.pz-prices__item--green .btn--pz:hover {
  background-color: #22272E;
  color: white;
}

.pz-stages__top {
  color: white;
}

.stock-list {
  display: flex;
  flex-wrap: wrap;
}

.stock-item__wrapper {
  width: calc(33% - 20px);
  margin: 0 10px 20px;
}
@media (max-width: 768px) {
  .stock-item__wrapper {
    width: calc(50% - 10px);
    margin: 0 5px 10px;
  }
}
@media (max-width: 480px) {
  .stock-item__wrapper {
    width: 100%;
    margin: 0 0 10px;
  }
}

.stock-item {
  max-width: 380px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  border: 1px solid #f9f9fa;
  height: 100%;
}
.stock-item:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
.stock-item__title {
  margin-bottom: 10px;
  font-size: 20px;
  text-transform: uppercase;
  font-family: "PTSansNarrow", sans-serif;
  margin-bottom: 15px;
}
.stock-item__image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  max-height: 150px;
  margin-bottom: 15px;
  overflow: hidden;
}
.stock-item__image-img {
  object-fit: contain;
  width: 100%;
}
.stock-item__btn {
  margin-top: auto;
}

.stock-article {
  margin-bottom: 40px;
}
.stock-article__image-img {
  max-width: 100%;
  margin-bottom: 20px;
}

.crumbs {
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.crumbs-item {
  color: #3b434f;
}
.crumbs-item--link {
  color: #999999;
}
.crumbs-item--link:hover {
  text-decoration: underline;
}
.crumbs-arrow {
  margin: 0 5px;
  color: #999999;
}
.crumbs-arrow--active {
  color: #3b434f;
}

.form-callback {
  width: 100%;
  padding: 60px 0 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../assets/images/index/call-action-home.webp");
}
.form-callback--product {
  background-image: url("../assets/images/call-action-skud.webp");
}
.form-callback .fc-title {
  text-align: center;
  margin-bottom: 60px;
}
.form-callback .fc-title span {
  font-size: 30px;
  position: relative;
  font-weight: bold;
  color: white;
}
.form-callback .fc-title span::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 40px;
  background-color: #fff;
  opacity: 0.5;
  transform: skew(-30deg);
  left: -30px;
}
.form-callback .fc-title span::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 40px;
  background-color: #fff;
  opacity: 0.5;
  transform: skew(-30deg);
  right: -30px;
}
.form-callback .fc-subtitle {
  text-align: center;
  margin-bottom: 20px;
  color: white;
}
.form-callback .fc-form {
  display: flex;
  flex-direction: column;
}
.form-callback .fc-form .fc-form-block {
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
}
@media (max-width: 768px) {
  .form-callback .fc-form .fc-form-block {
    flex-wrap: wrap;
  }
}
.form-callback .fc-form .fc-form-block:nth-child(2) {
  flex-direction: column;
}
.form-callback .fc-form .fc-form-block .form-control {
  margin: 0 auto;
}
.form-callback .fc-form .fc-input {
  width: 260px;
  border-radius: 0;
  padding: 11px 20px;
  margin: 0 15px 15px;
  border: none;
  font-size: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: 1px solid transparent;
}
.form-callback .fc-form .fc-input--unvalid {
  border: 1px solid rgb(186, 61, 61);
}
.form-callback .fc-form .fc-submit {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: "PTSansNarrow", sans-serif;
  font-weight: bold;
  background-color: #4fd262;
  padding: 11px 20px 10px;
  transition-duration: 0.3s;
  color: white;
  margin: 20px auto;
  border-radius: 4px;
}
.form-callback .fc-form .fc-submit:hover {
  background-color: #2ba93e;
  transition-duration: 0.3s;
}
.form-callback .fc-form .fc-submit:disabled {
  opacity: 0.75;
  pointer-events: none;
}
.form-callback .fc-form .fc-agreement {
  color: white;
  text-align: center;
}
.form-callback .fc-form .fc-loader {
  margin: 10px auto;
  text-align: center;
  color: white;
}
.form-callback .fc-message {
  text-align: center;
  max-width: 260px;
  width: 100%;
  margin: 0 auto 20px;
  border-radius: 4px;
  background-color: white;
  padding: 8px;
  display: none;
}
.form-callback .fc-message--success {
  color: #4fd262;
  border: 1px solid #4fd262;
  display: block;
}
.form-callback .fc-message--error {
  color: rgb(241, 62, 62);
  border: 1px solid rgb(241, 62, 62);
  display: block;
}
.form-callback .fc-checkbox--label {
  color: white;
}

#fc-form--layout .fc-form-block {
  margin-bottom: 0;
}

.menu-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .menu-button {
    margin-right: 15px;
    padding: 7px;
  }
}
@media (max-width: 400px) {
  .menu-button {
    margin-right: 5px;
    padding: 5px;
  }
}

.menu-bar {
  width: 22px;
  height: 3px;
  background-color: white;
  margin: 2px auto;
  transition: transform 0.3s;
}
@media (max-width: 768px) {
  .menu-bar {
    width: 17px;
    height: 2px;
    margin: 1.5px auto;
  }
}

.menu-button.menu-button--cross .menu-bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}
@media (max-width: 768px) {
  .menu-button.menu-button--cross .menu-bar:nth-child(1) {
    transform: rotate(45deg) translate(2.5px, 2.5px);
  }
}

.menu-button.menu-button--cross .menu-bar:nth-child(2) {
  opacity: 0;
}

.menu-button.menu-button--cross .menu-bar:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.logo {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 158px;
  height: 50px;
  background-image: url("../assets/icons/logo-krona.svg");
  margin-right: 10px;
}
@media (max-width: 768px) {
  .logo {
    width: 52px;
  }
}
@media (max-width: 400px) {
  .logo {
    width: 48px;
  }
}

.lk {
  padding: 11px 15px;
  font-size: 18px;
  font-weight: bold;
  margin-right: 30px;
  display: flex;
  align-items: center;
  font-family: "PTSansNarrow", sans-serif;
  transition-duration: 0.3s;
}
@media (max-width: 980px) {
  .lk {
    margin-right: 15px;
  }
}
@media (max-width: 768px) {
  .lk {
    font-size: 0;
    margin-right: 0;
  }
}
@media (max-width: 400px) {
  .lk {
    padding: 11px 10px;
  }
}
.lk:hover {
  transition-duration: 0.3s;
  box-shadow: 0 0 12.6px 8.4px rgba(79, 210, 98, 0.3);
  border-color: rgba(79, 210, 98, 0.3);
}
.lk .icon-base--user {
  fill: white;
  margin-right: 10px;
}
@media (max-width: 768px) {
  .lk .icon-base--user {
    margin-right: 0;
  }
}

.phone {
  font-size: 20px;
  font-weight: bold;
  padding: 11px 35px 11px 15px;
  position: relative;
  font-family: "PTSansNarrow", sans-serif;
}
@media (max-width: 980px) {
  .phone {
    font-size: 18px;
    padding: 11px 35px 11px 5px;
  }
}
@media (max-width: 768px) {
  .phone {
    font-size: 0;
    padding: 11px 15px;
  }
}
@media (max-width: 400px) {
  .phone {
    padding: 11px 10px;
  }
}
.phone-time {
  background-color: #4fd262;
  padding: 3px;
  font-size: 14px;
  line-height: 1;
  position: absolute;
  top: 0;
  right: 0;
}
@media (max-width: 768px) {
  .phone-time {
    display: none;
  }
}
.phone .icon-base--phone {
  display: none;
}
@media (max-width: 768px) {
  .phone .icon-base--phone {
    display: block;
    fill: white;
  }
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: white;
  z-index: 1000;
}
.header a {
  color: white;
}
.header .icon-menu {
  display: none;
}
.header .cities-block {
  margin-right: auto;
}

.header--fixed {
  position: fixed;
  background-color: white;
  box-shadow: 0 2px 20px rgba(140, 154, 185, 0.25);
}
.header--fixed .header-bottom {
  display: none;
}

.header-top {
  display: flex;
  align-items: center;
  margin: 20px auto;
}

.header-bottom {
  width: 100%;
  display: flex;
  position: relative;
}
@media (max-width: 980px) {
  .header-bottom {
    display: none;
  }
}
@media (max-width: 768px) {
  .header-bottom {
    flex-wrap: wrap;
  }
}
.header-bottom .header-bottom-item {
  padding: 28px 14px;
  text-transform: uppercase;
  border: 1px solid transparent;
  width: 16.6666666667%;
  text-align: center;
  font-size: 16px;
  transition-duration: 0.3s;
  font-family: "PTSansNarrow", sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 72px;
}
@media (max-width: 768px) {
  .header-bottom .header-bottom-item {
    width: 100%;
    justify-content: flex-start;
  }
}
.header-bottom .header-bottom-item:hover {
  background-color: white;
  color: #4fd262;
  transition-duration: 0.3s;
}
.header-bottom .header-bottom-item--more {
  cursor: default;
}
@media (max-width: 768px) {
  .header-bottom .header-bottom-item--more::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translate(-50%, -75%) rotate(45deg);
    border-right: solid 2px #fff;
    border-bottom: solid 2px #fff;
    height: 10px;
    width: 10px;
    transition: 0.3s;
  }
}
.header-bottom .header-bottom-item--more:hover .header-bottom-sublist {
  z-index: 100;
}
.header-bottom .header-bottom-item--more-active:hover::before {
  transform: translate(-50%, -75%) rotate(-135deg);
  transition: 0.3s;
}
.header-bottom .header-bottom-item .header-bottom-sublist--active {
  display: flex !important;
}
.header-bottom .header-bottom-item .header-bottom-sublist {
  display: none;
  background-color: #f9f9fa;
  padding: 20px;
  position: absolute;
  left: 0;
  top: 62px;
  width: 100%;
  flex-wrap: wrap;
  z-index: -1;
}
.header-bottom .header-bottom-item .header-bottom-sublist-item {
  width: calc(20% - 2px);
  margin: 1px;
  height: 120px;
  font-size: 14px;
  padding: 20px;
  background-color: #b1bbcc;
  transition: 0.3s;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  color: white;
  text-align: left;
}
.header-bottom .header-bottom-item .header-bottom-sublist-item:hover {
  background-color: #c0c8d6;
  color: white;
  transition: 0.3s;
}
.header-bottom .header-bottom-item .header-bottom-sublist .icon-submenu {
  margin-bottom: auto;
}
.header-bottom .header-bottom-item .icon {
  margin-right: auto;
}

.header--alt .search-btn {
  background-image: url("../assets/icons/components/search/search-gray.svg");
}
.header--alt .dropdown {
  color: black;
}
.header--alt .dropdown::before {
  border-right: solid 2px black;
  border-bottom: solid 2px black;
}
.header--alt .menu-bar {
  background-color: black;
}
.header--alt .icon-menu {
  display: block;
}
.header--alt .icon-base--user {
  fill: black;
}
.header--alt .phone {
  font-size: 18px;
  color: #3c3c3c;
  font-family: Helvetica, sans-serif;
}
.header--alt .phone span {
  color: white;
}
.header--alt .lk {
  font-family: Helvetica, sans-serif;
  background-color: white;
  font-size: 14px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  padding: 8px 20px 7px;
  color: #3c3c3c;
}
@media (max-width: 980px) {
  .header--alt .lk {
    padding: 8px 10px 7px;
  }
}
.header--alt .lk:hover {
  background-color: #f9f9fa;
}
.header--alt .lk-icon {
  background-image: url("../assets/icons/logo-lk--alt.svg");
}
@media (max-width: 768px) {
  .header--alt .lk {
    font-size: 0;
    box-shadow: none;
  }
  .header--alt .lk .icon-base--user {
    margin-right: 0;
  }
}
@media (max-width: 768px) {
  .header--alt .phone {
    font-size: 0;
    padding: 11px 15px;
  }
  .header--alt .phone .icon-base--phone {
    fill: black;
  }
  .header--alt .phone .phone-time {
    display: none;
  }
}
@media (max-width: 400px) {
  .header--alt .phone {
    padding: 11px 10px;
  }
}
.header--alt .header-bottom-item {
  justify-content: flex-end;
  background-color: white;
  box-shadow: 0 0 5px 0 rgb(89, 181, 107);
  font-size: 14px;
  color: #3c3c3c;
  padding: 12px 14px 20px;
  text-align: right;
  font-weight: bold;
}
@media (max-width: 768px) {
  .header--alt .header-bottom-item {
    justify-content: flex-start;
  }
  .header--alt .header-bottom-item .icon-menu {
    margin-right: 10px;
  }
}
.header--alt .header-bottom-item:hover {
  background-color: #f9f9fa;
  color: #2ba93e;
  box-shadow: none;
}
.header--alt .header-bottom-with-list {
  width: 100%;
  display: flex;
  align-items: center;
}

.header-menu {
  width: 100%;
  background-color: #7f7f7f;
  color: white;
  padding: 40px 15px;
  font-size: 14px;
  display: none;
}
@media (max-width: 480px) {
  .header-menu {
    padding: 25px 5px;
  }
}
.header-menu .container {
  display: flex;
  flex-wrap: wrap;
}
.header-menu .link, .header-menu .product-dops__more {
  color: white;
}
.header-menu__col {
  display: flex;
  margin-bottom: 20px;
}
.header-menu__col:nth-child(3) .header-menu__list {
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.header-menu__col:nth-child(3) .header-menu__list .link, .header-menu__col:nth-child(3) .header-menu__list .product-dops__more {
  margin-right: 15px;
  opacity: 0.7;
  transition-duration: 0.3s;
  cursor: pointer;
}
.header-menu__col:nth-child(3) .header-menu__list .link .icon, .header-menu__col:nth-child(3) .header-menu__list .product-dops__more .icon {
  fill: white;
}
.header-menu__col:nth-child(3) .header-menu__list .link:hover, .header-menu__col:nth-child(3) .header-menu__list .product-dops__more:hover {
  transition-duration: 0.3s;
  opacity: 1;
}
.header-menu__list {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}
.header-menu__item {
  margin-bottom: 10px;
}

.open-menu {
  overflow: hidden;
}
.open-menu .phone {
  color: white;
}
.open-menu .header-menu {
  display: flex;
}
.open-menu .header {
  background-color: #7f7f7f;
  color: white;
}

.header.header--fixed .icon-base--user,
.header.header--fixed .icon-base--phone {
  fill: black;
}
.header.header--fixed .lk {
  color: black;
}
.header.header--fixed .phone {
  color: black;
}

.header.header--fixed:not(.header--alt) .icon-base--user,
.header.header--fixed:not(.header--alt) .icon-base--phone {
  fill: black;
}
.header.header--fixed:not(.header--alt) .menu-bar {
  background-color: #4fd262;
}
.header.header--fixed:not(.header--alt) .dropdown::before {
  border-right: solid 2px black;
  border-bottom: solid 2px black;
}
.header.header--fixed:not(.header--alt) .dropdown__choose {
  color: black;
}

.header.header--open {
  background-color: #7f7f7f;
  overflow-y: scroll;
  max-height: 100vh;
}
@media (max-width: 768px) {
  .header.header--open {
    min-height: 100vh;
    height: 100%;
  }
}
.header.header--open .header-bottom {
  display: flex !important;
}
.header.header--open .header-menu {
  display: flex;
}
.header.header--open .menu-bar {
  background-color: white;
}
.header.header--open .phone {
  color: white;
}
@media (max-width: 980px) {
  .header.header--open .header-bottom {
    display: flex;
  }
  .header.header--open .header-bottom-item {
    padding: 14px;
  }
  .header.header--open .header-bottom-item:hover {
    background-color: transparent;
  }
  .header.header--open .header-bottom-item--more {
    position: relative;
  }
  .header.header--open .header-bottom-item--more::before {
    border-color: white;
  }
  .header.header--open .header-bottom .header-bottom-sublist-item {
    padding: 14px;
  }
}
@media (max-width: 768px) {
  .header.header--open .header-bottom-item--more {
    flex-wrap: wrap;
    max-height: none;
  }
  .header.header--open .header-bottom-item--more::before {
    top: 22px;
  }
  .header.header--open .header-bottom-item--more .header-bottom-with-list {
    width: 100%;
    text-align: left;
  }
  .header.header--open .header-bottom-sublist {
    position: static;
    top: auto;
    background-color: transparent;
    padding: 0;
  }
  .header.header--open .header-bottom-sublist-item {
    width: 100%;
    background-color: transparent;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 14px 0;
  }
  .header.header--open .header-bottom-sublist-item .icon {
    margin-right: 10px;
    max-height: 25px;
  }
  .header.header--open .header-bottom-sublist-item .icon-submenu--skud {
    max-width: 38px;
  }
}
.header.header--open .dropdown.dropdown--city::before {
  border-right: solid 2px white;
  border-bottom: solid 2px white;
}
.header.header--open .icon.icon-base--user,
.header.header--open .icon.icon-base--phone {
  fill: white;
}
.header.header--open .dropdown > .dropdown__choose {
  color: white;
}
.header.header--open .search-btn {
  background-image: url("../assets/icons/components/search/search-white.svg");
}

.header.header--alt.header--open .icon-base--user {
  fill: black;
}
@media (max-width: 768px) {
  .header.header--alt.header--open .header-bottom-item {
    background-color: transparent;
    color: white;
    box-shadow: none;
  }
  .header.header--alt.header--open .header-bottom-item .icon-menu {
    display: none;
  }
  .header.header--alt.header--open .lk {
    background-color: transparent;
  }
  .header.header--alt.header--open .lk .icon-base--user {
    fill: white;
  }
}
@media (max-width: 480px) {
  .header.header--alt.header--open .header-menu {
    padding: 25px 5px;
  }
}

.header.header--fixed.header--open .lk {
  color: white;
}

.footer {
  border-top: 1px solid #dce0e8;
  padding: 40px 0;
}
.footer-top {
  margin-bottom: 40px;
  display: flex;
  line-height: 20px;
  font-size: 14px;
}
.footer-links {
  display: flex;
  flex-direction: column;
  margin-right: auto;
}
@media (max-width: 768px) {
  .footer-links {
    margin-right: 20px;
    flex-shrink: 0;
  }
}
.footer-links-item {
  margin-bottom: 10px;
}
.footer .footer-contacts-top {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 480px) {
  .footer .footer-contacts-top {
    flex-direction: column;
    justify-content: flex-start;
  }
}
@media (max-width: 480px) {
  .footer .footer-contacts-top .text-bold {
    order: 2;
  }
}
@media (max-width: 480px) {
  .footer .footer-contacts-top .footer-phone {
    order: 1;
    margin-bottom: 20px;
  }
}
.footer-bottom {
  font-size: 10px;
  line-height: 14px;
}
.footer .footer-phone {
  font-weight: bold;
  font-size: 20px;
}

.checkbox-label {
  display: inline-block;
  position: relative;
  border: 1px solid #b1bbcc;
  border-radius: 1px;
  box-shadow: none;
  vertical-align: -4px;
  width: 20px;
  height: 20px;
  margin: 0 5px 0 0;
  background: white;
  cursor: pointer;
}

.checkbox-input {
  display: none;
}

.checkbox-check {
  width: 12px;
  height: 8px;
  margin: 3px 0 0 3px;
  border-left: 3px solid #4fd262;
  border-bottom: 3px solid #4fd262;
  transform: rotate(-50deg);
  display: none;
}

/* Изменение внешнего вида флажка при выборе */
.checkbox-input + .checkbox-check {
  display: block;
}

.checkbox-block {
  display: flex;
  color: white;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  z-index: 1000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal__container {
  position: relative;
  max-width: 500px;
  margin: auto;
  background: white;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-40px);
  transition-property: transform;
  transition-duration: 0.3s;
  cursor: default;
}
.modal__cross, .contacts-block .contacts-block__info-text-cross {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  transition-duration: 0.3s;
}
.modal__cross .icon-base--cross, .contacts-block .contacts-block__info-text-cross .icon-base--cross {
  fill: white;
}
.modal__cross:hover, .contacts-block .contacts-block__info-text-cross:hover {
  transition-duration: 0.3s;
  transform: rotate(-90deg);
}
.modal .form-callback {
  position: relative;
}
.modal .fc-title {
  margin-bottom: 30px;
}
.modal .fc-form-block {
  margin-bottom: 0 !important;
}
.modal .fc-form-block--column {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-store {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  z-index: 1000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-store__container {
  position: relative;
  max-width: 500px;
  margin: auto;
  background: white;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-40px);
  transition-property: transform;
  transition-duration: 0.3s;
  cursor: default;
  padding: 40px;
}
@media screen and (max-width: 480px) {
  .modal-store__container {
    width: calc(100% - 20px);
    padding: 20px;
  }
}
.modal-store__cross {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  transition-duration: 0.3s;
}
.modal-store__cross .icon-base--cross {
  fill: #4fd262;
}
.modal-store__cross:hover {
  transition-duration: 0.3s;
  transform: rotate(-90deg);
}
.modal-store__title {
  font-size: 28px;
  position: relative;
  font-weight: bold;
  color: #3c3c3c;
  margin-bottom: 20px;
}
@media screen and (max-width: 480px) {
  .modal-store__title {
    font-size: 22px;
    margin-bottom: 14px;
  }
}
.modal-store__info {
  font-size: 16px;
  margin-bottom: 12px;
}
.modal-store__link {
  color: #4fd262;
  text-decoration: underline;
  cursor: pointer;
}
.modal-store__link:hover {
  color: #2ba93e;
}
.modal-store__btn {
  margin: 20px auto 0;
  border-radius: 5px;
  display: block;
  max-width: 280px;
}

.is-visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s 0s, visibility 0s 0s;
}
.is-visible .modal__container {
  transform: translateY(0);
}
.is-visible .modal-store__container {
  transform: translateY(0);
}

.modal-event {
  z-index: 1100;
}
.modal-event .modal__container {
  display: flex;
  max-width: none;
  overflow: hidden;
}
@media screen and (max-width: 980px) {
  .modal-event .modal__container {
    flex-direction: column;
  }
}
.modal-event .fc-subtitle {
  font-size: 21px;
}
@media screen and (max-width: 768px) {
  .modal-event .fc-subtitle {
    font-size: 18px;
  }
}
.modal-event .modal-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 566px;
  background-image: url("../assets/images/november-event.jpg");
}
@media screen and (max-width: 980px) {
  .modal-event .modal-image {
    width: 100%;
    height: 250px;
  }
}
@media screen and (max-width: 480px) {
  .modal-event .modal-image {
    height: 200px;
  }
}
.modal-event .form-callback {
  width: 400px;
  padding: 30px 0;
}
@media screen and (max-width: 980px) {
  .modal-event .form-callback {
    padding: 20px 0;
  }
}
@media screen and (max-width: 480px) {
  .modal-event .form-callback {
    width: 320px;
  }
}

.dropdown {
  cursor: pointer;
  color: white;
  position: relative;
  padding-right: 20px;
}
.dropdown::before {
  content: "";
  display: block;
  position: absolute;
  top: 60%;
  right: 0;
  transform: translate(-50%, -75%) rotate(45deg);
  border-right: solid 2px #fff;
  border-bottom: solid 2px #fff;
  height: 8px;
  width: 8px;
  transition-duration: 0.3s;
}
.dropdown--active::before {
  transform: translate(-50%, -75%) rotate(-135deg);
  transition-duration: 0.3s;
}
.dropdown__block {
  transform: translate(0, 10px);
  position: absolute;
  background-color: white;
  left: 0;
  top: 100%;
  border-radius: 5px;
  width: 755px;
  opacity: 0;
  z-index: -1;
  transition-duration: 0.3s;
  display: none;
  box-shadow: 0 2px 20px rgba(140, 154, 185, 0.25);
}
.dropdown__block::after {
  content: "";
  width: 20px;
  height: 20px;
  background-color: white;
  position: absolute;
  top: -5px;
  left: 20px;
  transform: rotate(45deg);
  z-index: -1;
}
@media (max-width: 980px) {
  .dropdown__block {
    width: 385px;
  }
}
@media (max-width: 768px) {
  .dropdown__block {
    left: -105px;
    width: calc(100vw - 30px);
  }
  .dropdown__block::after {
    left: 125px;
  }
}
.dropdown__block--visible {
  opacity: 1;
  z-index: 100;
  transition-duration: 0.3s;
  display: block;
}
.dropdown__list {
  padding: 5px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.dropdown__item {
  margin: 0 2px 2px;
  border-radius: 3px;
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  transition-duration: 0.3s;
  font-size: 12px;
}
.dropdown__item:hover {
  background-color: rgba(79, 210, 99, 0.2470588235);
  transition-duration: 0.3s;
}
@media (max-width: 768px) {
  .dropdown__item {
    width: 105px;
  }
}
.dropdown__link {
  color: black !important;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 5px;
}

.header .dropdown__link {
  color: black;
}
.header .dropdown__link:hover {
  text-decoration: none;
}

.btn-top {
  position: fixed;
  bottom: -100px;
  left: 0;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.3s;
  opacity: 0;
  z-index: -1;
}
.btn-top__btn {
  width: 70px;
  height: 40px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  position: relative;
  background-color: #4fd262;
  cursor: pointer;
  z-index: 100;
  box-shadow: 0 2px 20px rgba(140, 154, 185, 0.25);
}
.btn-top__btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, 0%) rotate(45deg);
  border-left: solid 2px #fff;
  border-top: solid 2px #fff;
  height: 15px;
  width: 15px;
  transition: 0.3s;
}
.btn-top--visible {
  bottom: 0;
  transition-duration: 0.3s;
  opacity: 1;
  z-index: 100;
}

.cookies-message {
  bottom: 10px;
  font-size: 0.8rem;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  transition: 0.25s ease-in-out;
  width: 100%;
  z-index: 100;
  pointer-events: none;
}
.cookies-message.cookies-message--hidden {
  visibility: hidden;
}

.cookies-message_inner {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: calc(100% - 20px);
  background-color: #666;
  border-radius: 5px;
  color: #eee;
  padding: 15px;
  margin-right: 10px;
  margin-left: 10px;
  text-align: left;
  transition: 0.25s ease-in-out;
  pointer-events: all;
  margin: 0 auto;
}
@media screen and (max-width: 640px) {
  .cookies-message_inner {
    display: block;
  }
}
.cookies-message--hidden .cookies-message_inner {
  opacity: 0;
  transform: translateY(20px);
}

.cookies-message__text {
  margin-right: 10px;
  width: 200px;
}
@media screen and (max-width: 480px) {
  .cookies-message__text {
    width: auto;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.cookies-message__link {
  border-bottom-color: rgba(255, 255, 255, 0.4);
  color: #fff;
}
@media (min-width: 400px) {
  .cookies-message__link {
    white-space: nowrap;
  }
}
.cookies-message__link:hover {
  border-bottom-color: transparent;
  color: #fff;
}

.cookies-message__btns {
  display: flex;
}
.cookies-message__btns .btn-primary {
  margin-right: 10px;
}
.cookies-message__btns .btn {
  font-size: 12px;
  padding: 8px 12px;
}

.faq {
  padding: 60px 0;
  background-color: #f9f9fa;
}
.faq .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.faq-item {
  position: relative;
  border: 1px dashed #dce0e8;
  transition-duration: 0.3s;
  max-width: 760px;
  margin-bottom: -1px;
}
.faq-item .faq-question {
  position: relative;
  padding: 35px 65px 35px 25px;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  transition: 0.3s;
  cursor: pointer;
}
@media (max-width: 768px) {
  .faq-item .faq-question {
    padding: 15px 45px 15px 15px;
    font-size: 15px;
    line-height: 16px;
  }
}
.faq-item .faq-question::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translate(-50%, -75%) rotate(45deg);
  border-right: solid 2px #dce0e8;
  border-bottom: solid 2px #dce0e8;
  height: 20px;
  width: 20px;
  transition: 0.3s;
}
@media (max-width: 768px) {
  .faq-item .faq-question::before {
    height: 10px;
    width: 10px;
    right: 15px;
  }
}
.faq-item--active {
  transition-duration: 0.3s;
}
.faq-item--active .faq-question::before {
  transform: translate(-50%, -75%) rotate(-135deg);
  transition-duration: 0.3s;
  top: calc(50% + 10px);
}
.faq-item ul {
  margin-left: 20px;
}
@media (max-width: 768px) {
  .faq-item ul {
    margin-left: 15px;
  }
}
.faq-item ul li {
  list-style: disc;
}

.faq-answer {
  padding: 0 25px;
  font-size: 14px;
  line-height: 17px;
  max-height: 0;
  overflow: hidden;
  transition: 0.3s ease-out;
}
@media (max-width: 768px) {
  .faq-answer {
    padding: 0 15px;
    font-size: 13px;
    line-height: 15px;
  }
}

.section-partners .brands-wrapper {
  display: flex;
  height: 450px;
  margin: 0 auto;
  max-width: 1166px;
  overflow: hidden;
  position: relative;
  transform: translate3d(0, 0, 0);
}
.section-partners .brands-wrapper::after {
  background: linear-gradient(270deg, white 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 80px;
}
.section-partners .brands-wrapper::before {
  background: linear-gradient(90deg, white 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 80px;
  z-index: 1;
}
.section-partners .brands-wrapper .brands-container {
  animation: slide 1286s alternate infinite linear;
  background: url("../assets/images/index/clients-logo.jpg") 0 0 repeat-x;
  display: flex;
  height: 450px;
  min-width: 99999px;
  transform: translate3d(0, 0, 0);
  flex-wrap: wrap;
}

@keyframes slide {
  100% {
    transform: translateX(-100%);
  }
}
.search-btn {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
  width: 16px;
  height: 16px;
  background-image: url("../assets/icons/components/search/search-white.svg");
  background-color: transparent;
  margin: 0 20px 0 10px;
  padding: 11px 15px;
  cursor: pointer;
  transition-duration: 0.3s;
  height: 43px;
  width: 43px;
}
@media (max-width: 980px) {
  .search-btn {
    margin: 0 10px;
    padding: 11px;
    height: 33px;
    width: 23px;
  }
}
.search-btn:hover {
  transition-duration: 0.3s;
  box-shadow: 0 0 12.6px 8.4px rgba(79, 210, 98, 0.3);
  border-color: rgba(79, 210, 98, 0.3);
}
@media (max-width: 768px) {
  .search-btn:hover {
    box-shadow: none;
  }
}

#searchOnSite {
  color: white;
  background-color: rgba(0, 0, 0, 0.777);
}
#searchOnSite .modal-dialog {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 20px 0;
  overflow-y: scroll;
}
#searchOnSite .modal-content {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 980px) {
  #searchOnSite .modal-content {
    max-width: 600px;
  }
}
@media screen and (max-width: 768px) {
  #searchOnSite .modal-content {
    justify-content: flex-start;
  }
}
#searchOnSite .modal-content .modal__cross, #searchOnSite .modal-content .contacts-block .contacts-block__info-text-cross, .contacts-block #searchOnSite .modal-content .contacts-block__info-text-cross {
  right: 0px;
  top: -30px;
}
#searchOnSite .modal-content .modal__cross .icon-base--cross, #searchOnSite .modal-content .contacts-block .contacts-block__info-text-cross .icon-base--cross, .contacts-block #searchOnSite .modal-content .contacts-block__info-text-cross .icon-base--cross {
  width: 30px;
  height: 30px;
}
#searchOnSite .modal-content .modal-header {
  padding: 0;
}
#searchOnSite .modal-content .modal-header .modal-title {
  margin-bottom: 50px;
  font-size: 45px;
}
@media screen and (max-width: 768px) {
  #searchOnSite .modal-content .modal-header .modal-title {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 35px;
    padding-right: 0;
  }
}
#searchOnSite .modal-content .modal-header .close {
  top: 0;
  right: 0;
}
@media screen and (max-width: 768px) {
  #searchOnSite .modal-content .modal-header .close {
    top: 0px;
    right: 10px;
  }
}
#searchOnSite .modal-content .modal-body {
  width: 100%;
  padding: 0 0 0 100px;
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  #searchOnSite .modal-content .modal-body {
    flex: 0 0 auto;
    padding: 0 10px 0 10px;
  }
}
#searchOnSite .modal-content .modal-body .search-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}
#searchOnSite .modal-content .modal-body .search-wrapper .search-input {
  width: 768px;
  border: none;
  border-bottom: 2px solid white;
  background-color: transparent;
  color: white;
  width: 100%;
  font-size: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  transition-duration: 0.3s;
}
#searchOnSite .modal-content .modal-body .search-wrapper .search-input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}
#searchOnSite .modal-content .modal-body .search-wrapper .search-input:focus {
  border-bottom: 2px solid #4fd262;
  transition-duration: 0.3s;
}
#searchOnSite .modal-content .modal-body .search-wrapper .search-clear {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 33px;
  height: 33px;
  background-color: transparent;
  border: none;
  transition: 0.3s;
  position: absolute;
  top: 0;
  right: 0;
}
#searchOnSite .modal-content .modal-body .search-wrapper .search-clear:hover path {
  fill: #4fd262;
  transition: 0.3s;
}
#searchOnSite .modal-content .modal-body .search-message {
  font-size: 20px;
  width: 100%;
  text-align: left;
  margin-top: 15px;
}
#searchOnSite .modal-content .modal-footer > * {
  margin: 0;
}
#searchOnSite .modal-content .modal-footer {
  padding: 0;
  justify-content: flex-start;
  width: 100%;
}
@media screen and (max-width: 768px) {
  #searchOnSite .modal-content .modal-footer::before {
    display: none;
  }
}
#searchOnSite .modal-content .modal-footer .search-row-result--wrapper {
  width: 100%;
}
#searchOnSite .modal-content .modal-footer .search-row-result {
  display: flex;
}
@media screen and (max-width: 768px) {
  #searchOnSite .modal-content .modal-footer .search-row-result {
    flex-direction: column;
    align-items: flex-start;
  }
}
#searchOnSite .modal-content .modal-footer .search-row-result-title {
  font-size: 16px;
  min-width: 100px;
  text-align: right;
  padding: 8px 10px 0 0;
}
@media screen and (max-width: 768px) {
  #searchOnSite .modal-content .modal-footer .search-row-result-title {
    font-size: 14px;
    text-align: left;
    padding: 0px 10px 10px 10px;
  }
}
#searchOnSite .modal-content .modal-footer .search-row-result-list-more {
  position: relative;
}
#searchOnSite .modal-content .modal-footer .search-row-result-list-more::before {
  position: absolute;
  top: 0;
  right: -60px;
  width: 60px;
  cursor: pointer;
  content: "Еще";
  background-color: white;
  border: 1px solid #4fd262;
  color: black;
  padding: 8px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  #searchOnSite .modal-content .modal-footer .search-row-result-list-more::before {
    right: 10px;
    top: -40px;
    padding: 5px 8px;
  }
}
#searchOnSite .modal-content .modal-footer .search-row-result-list-more--active::before {
  content: "Скрыть";
  right: -80px;
  width: 80px;
}
@media screen and (max-width: 768px) {
  #searchOnSite .modal-content .modal-footer .search-row-result-list-more--active::before {
    right: 10px;
  }
}
#searchOnSite .modal-content .modal-footer .search-row-result-list {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 700px;
  width: 100%;
  position: relative;
}
@media screen and (max-width: 980px) {
  #searchOnSite .modal-content .modal-footer .search-row-result-list {
    max-width: 500px;
  }
}
@media screen and (max-width: 768px) {
  #searchOnSite .modal-content .modal-footer .search-row-result-list {
    padding: 0 10px;
    max-width: none;
  }
}
#searchOnSite .modal-content .modal-footer .search-row-result-list-item {
  font-size: 16px;
  list-style: none;
  white-space: nowrap;
}
#searchOnSite .modal-content .modal-footer .search-row-result-list-item-link {
  padding: 7px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  border-radius: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: white;
}
#searchOnSite .modal-content .modal-footer .search-row-result-list-item-link:hover {
  color: #4fd262;
  border: 1px solid #4fd262;
  background-color: white;
}
#searchOnSite .search-form-wrapper {
  max-width: 500px;
  background-color: white;
  color: #3c3c3c;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0.6s;
  text-align: center;
}
#searchOnSite .search-form-wrapper a {
  color: #4fd262;
  transition: 0.3s;
}
#searchOnSite .search-form-wrapper a:hover {
  color: #2ba93e;
  transition: 0.3s;
  text-decoration: underline;
}
#searchOnSite .search-form-wrapper .fc-agreement {
  color: white;
}
#searchOnSite .search-form-wrapper .search-form {
  max-width: 340px;
  width: 100%;
}
#searchOnSite .search-form-wrapper .search-form-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 1.2;
}
#searchOnSite .search-form-wrapper .search-form-subtitle {
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 1.2;
}
#searchOnSite .search-form-wrapper small {
  color: #3c3c3c;
}
#searchOnSite .search-form-wrapper--active {
  opacity: 1;
  transition: opacity 0.6s;
}

.show {
  opacity: 1;
  visibility: visible;
}

.fade {
  transition: opacity 0.15s linear;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  max-width: 1280px;

  @media screen and (max-width: 980px) {
    padding: 0 20px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }

  @media screen and (max-width: 480px) {
    padding: 0 10px;
  }

  &--width {
    max-width: 1360px;
  }
}

.section {
  padding-bottom: 60px;

  &:last-child {
    padding-bottom: 0;
  }
}
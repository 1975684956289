// PT Sans Narrow font
@font-face {
  font-family: 'PTSansNarrow';
  src: url('../assets/fonts/PTSans-Narrow.woff2') format('woff2');
  font-weight: normal;
}

@font-face {
  font-family: 'PTSansNarrow';
  src: url('../assets/fonts/PTSans-NarrowBold.woff2') format('woff2');
  font-weight: bold;
}

// Open Sans font
@font-face {
  font-family: 'OpenSans';
  src: url('../assets/fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../assets/fonts/OpenSans-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../assets/fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
}
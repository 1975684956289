.btn-top {
  position: fixed;
  bottom: -100px;
  left: 0;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: .3s;
  opacity: 0;
  z-index: -1;

  &__btn {
    width: 70px;
    height: 40px;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    position: relative;
    background-color: $krona-green;
    cursor: pointer;
    z-index: 100;
    box-shadow: 0 2px 20px rgba(140, 154, 185, 0.25);

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, 0%) rotate(45deg);
      border-left: solid 2px #fff;
      border-top: solid 2px #fff;
      height: 15px;
      width: 15px;
      transition: .3s;
    }
  }

  &--visible {
    bottom: 0;
    transition-duration: .3s;
    opacity: 1;
    z-index: 100;
  }
}
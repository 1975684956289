.modal {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  z-index: 1000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    position: relative;
    max-width: 500px;
    margin: auto;
    background: white;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-40px);
    transition-property: transform;
    transition-duration: 0.3s;
    cursor: default;
  }

  &__cross {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    transition-duration: .3s;

    .icon-base--cross {
      fill: white;
    }

    &:hover {
      transition-duration: .3s;
      transform: rotate(-90deg);
    }
  }

  .form-callback {
    position: relative;
  }

  .fc-title {
    margin-bottom: 30px;
  }

  .fc-form-block {
    margin-bottom: 0 !important;
  }

  .fc-form-block--column {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}


.modal-store {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  z-index: 1000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    position: relative;
    max-width: 500px;
    margin: auto;
    background: white;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-40px);
    transition-property: transform;
    transition-duration: 0.3s;
    cursor: default;
    padding: 40px;

    @media screen and (max-width: 480px) {
      width: calc(100% - 20px);
      padding: 20px;
    }
  }

  &__cross {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    transition-duration: .3s;

    .icon-base--cross {
      fill: $krona-green;
    }

    &:hover {
      transition-duration: .3s;
      transform: rotate(-90deg);
    }
  }

  &__title {
    font-size: 28px;
    position: relative;
    font-weight: bold;
    color: $text-gray--dark;
    margin-bottom: 20px;

    @media screen and (max-width: 480px) {
      font-size: 22px;
      margin-bottom: 14px;
    }
  }

  &__info {
    font-size: 16px;
    margin-bottom: 12px;
  }

  &__link {
    color: $krona-green;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: $krona-green--dark;
    }
  }

  &__btn {
    margin: 20px auto 0;
    border-radius: 5px;
    display: block;
    max-width: 280px;
  }
}

.is-visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s 0s, visibility 0s 0s;

  .modal__container {
    transform: translateY(0);
  }

  .modal-store__container {
    transform: translateY(0);
  }
}

.modal-event {
  z-index: 1100;
  .modal__container {
    display: flex;
    max-width: none;
    overflow: hidden;

    @media screen and (max-width: 980px) {
      flex-direction: column;
    }
  }

  .fc-subtitle {
    font-size: 21px;

    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }

  .modal-image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 566px;
    background-image: url('../assets/images/november-event.jpg');

    @media screen and (max-width: 980px) {
      width: 100%;
      height: 250px;
    }

    @media screen and (max-width: 480px) {
      height: 200px;
    }
  }

  .form-callback {
    width: 400px;
    padding: 30px 0;

    @media screen and (max-width: 980px) {
      padding: 20px 0;
    }

    @media screen and (max-width: 480px) {
      width: 320px;
    }
  }
}
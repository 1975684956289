.dropdown {
  cursor: pointer;
  color: white;
  position: relative;
  padding-right: 20px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 60%;
    right: 0;
    transform: translate(-50%, -75%) rotate(45deg);
    border-right: solid 2px #fff;
    border-bottom: solid 2px #fff;
    height: 8px;
    width: 8px;
    transition-duration: .3s;
  }
  
  &--active {
    &::before {
      transform: translate(-50%, -75%) rotate(-135deg);
      transition-duration: .3s;
    }
  }

  &__block {
    transform: translate( 0, 10px );
    position: absolute;
    background-color: white;
    left: 0;
    top: 100%;
    border-radius: 5px;
    width: 755px;
    opacity: 0;
    z-index: -1;
    transition-duration: .3s;
    display: none;
    box-shadow: 0 2px 20px rgba(140,154,185,.25);

    &::after {
      content: '';
      width: 20px;
      height: 20px;
      background-color: white;
      position: absolute;
      top: -5px;
      left: 20px;
      transform: rotate(45deg);
      z-index: -1;
    }

    @include for-980 {
      width: 385px;
    }

    @include for-768 {
      left: -105px;
      width: calc(100vw - 30px);

      &::after {
        left: 125px;
      }
    }
  }

  &__block--visible {
    opacity: 1;
    z-index: 100;
    transition-duration: .3s;
    display: block;
  }

  &__list {
    padding: 5px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    margin: 0 2px 2px;
    border-radius: 3px;
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    transition-duration: .3s;
    font-size: 12px;

    &:hover {
      background-color: #4fd2633f;
      transition-duration: .3s;
    }

    @include for-768 {
      width: 105px;
    }
  }

  &__link {
    color: black !important;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 5px;
  }
}

.header {
  .dropdown__link {
    color: black;

    &:hover {
      text-decoration: none;
    }
  }
}
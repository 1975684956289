.pz-main {

  &--wrapper {
    height: 550px;
    background-color: $krona-gray--light;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    @include for-768 {
      height: 400px;
    }

    @include for-480 {
      height: 300px;
    }
  }

  &--left {
    display: flex;
    flex-direction: column;
    padding: 40px;
    height: 100%;
    width: 50%;

    @include for-768 {
      width: 100%;
    }

    @include for-480 {
      padding: 20px;
    }
  }

  &__title {
    font-size: 70px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 15px;
    @include PTSansNarrow-font;

    @include for-768 {
      font-size: 60px;
      margin-top: 0;
    }

    @include for-480 {
      font-size: 45px;
    }
  }

  &__subtitle {
    margin-bottom: auto;
  }

  &--right {
    width: 50%;
    height: 100%;
    flex-shrink: 0;

    @include for-768 {
      display: none;
    }
  }

  &__img {
    @include bg--cover;
    width: 100%;
    height: 100%;
    background-image: url('../assets/images/physical-security/physical-security__main.webp');
    background-position: center left;
  }

  &__stats {
    display: flex;
    justify-content: space-between;

    @include for-768 {
      flex-wrap: wrap;
    }

    &-item {
      background-color: $krona-gray--light;
      border-radius: 8px;
      width: 24%;
      padding: 16px;

      @include for-768 {
        width: 49%;
        margin-bottom: 10px;
      }

      @include for-480 {
        padding: 10px;
      }
    }

    @include for-768 {
      .pz-main__title {
        font-size: 40px;
      }

      .pz-main__subtitle {
        font-size: 16px;
      }
    }

    @include for-480 {
      .pz-main__title {
        font-size: 30px;
      }

      .pz-main__subtitle {
        font-size: 13px;
      }
    }
  }

  .btn--pz {
    max-width: 230px;

    .icon-base--arrow {
      margin-left: 10px;
      fill: white;
    }
  }
}

.swiper-button-prev {
  background-color: #F9F9FA;
  left: 50%;
  width: 56px;
  height: 56px;
  transform: translate(-110%, 0);
  border-radius: 50%;
  top: auto;
  bottom: 0px;

  &::after {
    font-size: 21px;
    color: #54B367; 
  }
}


.pz-decisions {
  padding: 120px 0 0;

  @include for-980 {
    padding: 90px 0 0;
  }

  @include for-768 {
    padding: 60px 0 0;
  }

  &__top {
    display: flex;

    @include for-980 {
      flex-wrap: wrap;
    }

    .pz-main__title {
      font-size: 52px;
      line-height: 1em;
      flex-shrink: 0;

      @include for-980 {
        font-size: 46px;
        flex-shrink: 1;
      }

      @include for-768 {
        font-size: 42px;
      }

      @include for-480 {
        font-size: 34px;
      }
    }

    .pz-main__subtitle {
      max-width: 325px;
      margin-left: auto;
      color: #89959E;

      @include for-768 {
        margin-left: 0;
      }
    }
  }

  &__slider {
    padding: 60px 0 80px !important;

    &-btn {
      background-color: white;
      color: #54B367;

      &:hover {
        background-color: #22272E;
        color: white;
      }
    }
    
    &-item {
      height: 400px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-plus {
      border-radius: 50%;
      width: 56px;
      height: 56px;
      background-color: rgba(255,255,255,.2);
      @include bg--contain;
      background-image: url('../assets/icons/ic_plus--white.svg');
      background-size: 24px 24px;
      position: relative;
      z-index: 5;
      transition-duration: .3s;
      cursor: pointer;
    }

    &-title {
      font-size: 28px;
      font-weight: bold;
      @include PTSansNarrow-font;
    }

    &-cover {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 100%;
      left: 0;
      padding: 16px;
      background-color: #54B367;
      color: white;
      transition-duration: .3s;
      z-index: 3;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-img {
      @include bg--cover;
      height: 352px;
      width: 100%;
      position: relative;
      padding: 20px;
      overflow: hidden;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      border-radius: 8px;

      &--1 {
        background-image: url('../assets/images/physical-security/physical-security__decisions--1.webp')
      }

      &--2 {
        background-image: url('../assets/images/physical-security/physical-security__decisions--2.webp')
      }

      &--3 {
        background-image: url('../assets/images/physical-security/physical-security__decisions--3.webp')
      }

      &--4 {
        background-image: url('../assets/images/physical-security/physical-security__decisions--1.webp')
      }

      &--show {
        .pz-decisions__slider-cover {
          top: 0;
        }

        .pz-decisions__slider-plus {
          transform: rotate(-45deg);
        }
      }
    }
  }

  .btn--pz {
    max-width: 160px;
    background-color: white;
    color: #54B367;

    .icon-base--arrow {
      fill: #54B367;
      margin-left: 10px;
      transition-duration: .3s;
    }

    &:hover {
      background-color: #22272E;
      color: white;
      
      .icon-base--arrow {
        fill: white;
      }
    }
  }

  .swiper-button-next {
    @extend .swiper-button-prev;
    left: auto;
    right: 50%;
    transform: translate(110%, 0);
  }
  .swiper-button-disabled {
    opacity: .7;
  }
}

.btn--pz {
  height: 56px;
  padding: 5px 32px;
  border-radius: 4px;
  max-width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pz-call {
  padding: 120px 0;
  color: white;

  @include for-980 {
    padding: 90px 0;
  }

  @include for-768 {
    padding: 60px 0;
  }

  // &__image {
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   left: 0;
  //   top: 0;

  //   &-img {
  //     object-fit: cover;
  //     width: 100%;
  //     height: 100%;
  //   }
  // }

  &__pic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: fill;
    object-fit: fill;
    z-index: 1;
  }

  &__bg {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }


  .container--width {
    position: relative;
    min-height: 511px;
    padding: 60px;
  
    @include for-768 {
      min-height: 400px;
    }
  
    @include for-480 {
      padding: 30px;
      min-height: 340px;
    }
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 2;
    height: 100%;
    min-height: 391px;
  
    @include for-768 {
      min-height: 400px;
    }
  
    @include for-480 {
      min-height: 340px;
    }
  }

  &__title {
    @include PTSansNarrow-font;
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 10px;
    text-align: center;
    max-width: 900px;
  
    @include for-768 {
      font-size: 42px;
    }
  
    @include for-480 {
      font-size: 30px;
    }
  }

  &__subtitle {
    text-align: center;
    margin-bottom: auto;
  }

  .btn--pz {
    background-color: white;
    color: #54B367;
    margin-top: 10px;

    .icon-base--phone {
      fill: #54B367;
      margin-left: 10px;
    }

    &:hover {
      background-color: #22272E;
      color: white;

      .icon-base--phone {
        fill: white;
      }
    }
  }
}

.pz-prices {
  padding-bottom: 120px;
  
  @include for-980 {
    padding-bottom: 90px;
  }

  @include for-768 {
    padding-bottom: 60px;
  }

  .pz-main__title {
    text-align: center;
    margin: 0 auto 60px;
  }

  &__list {
    display: flex;
    justify-content: space-between;
  
    @include for-768 {
      flex-wrap: wrap;
    }
  }

  &__item {
    padding: 24px;
    background-color: #F9F9FA;
    display: flex;
    flex-direction: column;
    width: 24%;
    border-radius: 8px;
  
    @include for-768 {
      width: 49%;
      margin-bottom: 20px;
    }
  
    @include for-480 {
      width: 100%;
      margin-bottom: 20px;
    }

    &-title {
      @include PTSansNarrow-font;
      font-size: 28px;
      font-weight: bold;
      padding-bottom: 20px;
      margin-bottom: 40px;
      border-bottom: 1px solid #DCE0E8;
      text-align: center;
      min-height: 85px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-list {
      @include Opensans-font;
      padding-bottom: 40px;
      margin-bottom: auto;

      &-item {
        display: flex;

        span {
          white-space: nowrap;
        }
      }
    }

    .icon-base--check {
      fill: #89959E;
      margin-right: 10px;
    }

    .btn--pz {
      max-width: 100%;
      max-height: 44px;
    }

    &--green {
      position: relative;
      background-color: #54B367;
      color: white;

      &::before {
        content: "популярный";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%,-50%);
        height: 28px;
        padding: 8px 16px;
        color: white;
        background-color: #54B367;
        border: 1px solid rgba(255,255,255,.2);
        border-radius: 100px;
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
      }

      .icon-base--check {
        fill: white;
      }

      .btn--pz {
        background-color: white;
        color: #54B367;

        &:hover {
          background-color: #22272E;
          color: white;
        }
      }
    }
  }
}

.pz-stages {

  &__top {
    color: white;
  }
}
.cookies-message {
  bottom: 10px;
  font-size: .8rem;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  transition: .25s ease-in-out;
  width: 100%;
  z-index: 100;
  pointer-events: none;

  &.cookies-message--hidden {
      visibility: hidden;
  }
}

.cookies-message_inner {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: calc(100% - 20px);
  background-color: #666;
  border-radius: 5px;
  color: #eee;
  padding: 15px;
  margin-right: 10px;
  margin-left: 10px;
  text-align: left;
  transition: .25s ease-in-out;
  pointer-events: all;
  margin: 0 auto;

  @media screen and (max-width: 640px) {
      display: block;
  }

  .cookies-message--hidden & {
      opacity: 0;
      transform: translateY(20px);
  }
}

.cookies-message__text {
margin-right: 10px;
width: 200px;

@media screen and (max-width: 480px) {
  width: auto;
  margin-right: 0;
  margin-bottom: 10px;
}
}

.cookies-message__link {
  border-bottom-color: rgba(#fff, .4);
  color: #fff;
  
  @media (min-width: 400px) {
      white-space: nowrap;
  }

  &:hover {
      border-bottom-color: transparent;
      color: #fff;
  }
}

.cookies-message__btns {
display: flex;

.btn-primary {
  margin-right: 10px;
}

.btn {
  font-size: 12px;
  padding: 8px 12px;
}
}
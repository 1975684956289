.checkbox-label {
  display: inline-block;
  position: relative;
  border: 1px solid #b1bbcc;
  border-radius: 1px;
  box-shadow: none;
  vertical-align: -4px;
  width: 20px;
  height: 20px;
  margin: 0 5px 0 0;
  background: white;
  cursor: pointer;
}

.checkbox-input {
  display: none;
}

.checkbox-check {
  width: 12px;
  height: 8px;
  margin: 3px 0 0 3px;
  border-left: 3px solid #4fd262;
  border-bottom: 3px solid #4fd262;
  transform: rotate(-50deg);
  display: none;
}

/* Изменение внешнего вида флажка при выборе */
.checkbox-input + .checkbox-check {
  display: block;
}

.checkbox-block {
  display: flex;
  color: white;
}
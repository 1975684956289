.faq {
  padding: 60px 0;
  background-color: $krona-gray--light;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.faq-item {
  position: relative;
  border: 1px dashed $krona-gray;
  transition-duration: .3s;
  max-width: 760px;
  margin-bottom: -1px;

  .faq-question {
    position: relative;
    padding: 35px 65px 35px 25px;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    transition: .3s;
    cursor: pointer;

    @include for-768 {
      padding: 15px 45px 15px 15px;
      font-size: 15px;
      line-height: 16px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 25px;
      transform: translate(-50%, -75%) rotate(45deg);
      border-right: solid 2px $krona-gray;
      border-bottom: solid 2px $krona-gray;
      height: 20px;
      width: 20px;
      transition: .3s;

      @include for-768 {
        height: 10px;
        width: 10px;
        right: 15px;
      }
    }
  }
  
  &--active {
    transition-duration: .3s;

    .faq-question {
      &::before {
        transform: translate(-50%, -75%) rotate(-135deg);
        transition-duration: .3s;
        top: calc(50% + 10px);
      }
    }
  }

  ul {
    margin-left: 20px;

    @include for-768 {
      margin-left: 15px;
    }

    li {
      list-style: disc;
    }
  }
}

.faq-answer {
  padding: 0 25px;
  font-size: 14px;
  line-height: 17px;
  max-height: 0;
  overflow: hidden;
  transition: .3s ease-out;

  @include for-768 {
    padding: 0 15px;
    font-size: 13px;
    line-height: 15px;
  }
}
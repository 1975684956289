@import './fonts.scss';

// fonts
@mixin Helvetica-font {
    font-family: Helvetica, sans-serif;
}

@mixin PTSansNarrow-font {
    font-family: 'PTSansNarrow', sans-serif;
}

@mixin Opensans-font {
  font-family: 'Open Sans', sans-serif;
}

// colors
$krona-green: #4fd262;
$krona-green--dark: #2ba93e;
$krona-green--wa: #acd659;
$krona-gray--light: #f9f9fa;
$krona-gray: #dce0e8;
$krona-gray--dark: #7f7f7f;

$text-gray--light: #999999;
$text-gray--dark: #3c3c3c;
$text-gray--blue: #3b434f;

// backgrounds
@mixin bg--center {
    background-repeat: no-repeat;
    background-position: center;
}

@mixin bg--cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

@mixin bg--contain {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

// buttons
@mixin btn--standart {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

// media queries
@mixin for-400 {
  @media (max-width: 400px) { @content; }
}

@mixin for-480 {
  @media (max-width: 480px) { @content; }
}

@mixin for-768 {
  @media (max-width: 768px) { @content; }
}

@mixin for-980 {
  @media (max-width: 980px) { @content; }
}

@mixin for-1200 {
  @media (max-width: 1200px) { @content; }
}
svg.icon {
  @include bg--contain;

  &-product__features {
    &--1 {
      width: 25px;
      height: 47px;
    }

    &--2 {
      width: 36px;
      height: 42px;
    }

    &--3 {
      width: 32px;
      height: 33px;
    }

    &--4 {
      width: 24px;
      height: 40px;
    }

    &--5 {
      width: 26px;
      height: 28px;
    }

    &--6 {
      width: 38px;
      height: 39px;
    }

    &--7 {
      width: 40px;
      height: 34px;
    }

    &--32 {
      width: 34px;
      height: 33px;
    }
  }

  &-product__seven {
    &--1 {
      width: 62px;
      height: 65px;
    }

    &--2 {
      width: 56px;
      height: 45px;
    }

    &--3 {
      width: 62px;
      height: 47px;
    }

    &--4 {
      width: 57px;
      height: 57px;
    }

    &--5 {
      width: 63px;
      height: 60px;
    }

    &--6 {
      width: 88px;
      height: 74px;
    }

    &--7 {
      width: 57px;
      height: 64px;
    }
  }

  &-product__system {
    min-height: 70px;
    
    &--1 {
      width: 94px;
      height: 64px;
    }

    &--2 {
      width: 85px;
      height: 63px;
    }

    &--3 {
      width: 64px;
      height: 70px;
    }

    &--4 {
      width: 62px;
      height: 65px;
    }

    &--5 {
      width: 63px;
      height: 60px;
    }

    &--6 {
      width: 68px;
      height: 70px;
    }

    &--7 {
      width: 65px;
      height: 70px;
    }

    &--8 {
      width: 58px;
      height: 70px;
    }

    &--9 {
      width: 53px;
      height: 70px;
    }

    &--10 {
      width: 75px;
      height: 70px;
    }

    &--11 {
      width: 91px;
      height: 70px;
    }

    &--12 {
      width: 70px;
      height: 70px;
    }

    &--13 {
      width: 70px;
      height: 70px;
    }

    &--adv-32 {
      width: 60px;
      height: 60px;
    }
  }

  &-product__wework {
    
  }
}

.icon-png {
  @include bg--contain;
  width: 70px;
  min-width: 70px;
  height: 70px;
  z-index: 2;

  &-product__system {

    &--23 {
      background-image: url('../assets/icons/index-system/ic_system-23.png')
    }
    &--24 {
      background-image: url('../assets/icons/index-system/ic_system-24.png')
    }
    &--25 {
      background-image: url('../assets/icons/index-system/ic_system-25.png')
    }
    &--26 {
      width: 60px;
      min-width: 60px;
      background-image: url('../assets/icons/index-system/ic_system-26.png')
    }
    &--27 {
      background-image: url('../assets/icons/index-system/ic_system-27.png')
    }
    &--28 {
      background-image: url('../assets/icons/index-system/ic_system-28.png')
    }
    &--29 {
      background-image: url('../assets/icons/index-system/ic_system-29.png')
    }
    &--30 {
      width: 90px;
      background-image: url('../assets/icons/index-system/ic_system-30.png')
    }
  }

  &-product__type-work {
    &--1 {
      background-image: url('../assets/icons/products/product-type-work/ic_type-work-1.webp')
    }
    &--2 {
      background-image: url('../assets/icons/products/product-type-work/ic_type-work-2.webp')
    }
    &--3 {
      background-image: url('../assets/icons/products/product-type-work/ic_type-work-3.webp')
    }
    &--4 {
      background-image: url('../assets/icons/products/product-type-work/ic_type-work-4.webp')
    }
    &--5 {
      background-image: url('../assets/icons/products/product-type-work/ic_type-work-5.webp')
    }
  }
}

svg.icon {
  @include bg--contain;
  flex-shrink: 0;

  &-base {
    &--arrow {
      width: 14px;
      height: 14px;
    }
    
    &--phone {
      width: 20px;
      height: 20px;
    }

    &--check {
      width: 20px;
      height: 20px;
    }

    &--cross {
      width: 24px;
      height: 24px;
    }

    &--user {
      width: 16px;
      height: 14px;
    }
  }

  &-link {
    &--appstore {
      width: 130px;
      height: 50px;
    }

    &--playstore {
      width: 130px;
      height: 50px;
    }

    &--vk {
      width: 50px;
      height: 50px;
    }
  }

  &-menu {
    width: 28px;
    height: 28px;
  }

  &-submenu {
    &--office {
      width: 27px;
      height: 32px;
    }
    &--banks {
      width: 33px;
      height: 32px;
    }
    &--shop {
      width: 32px;
      height: 32px;
    }
    &--skud {
      width: 62px;
      height: 32px;
    }
    &--fog {
      width: 39px;
      height: 32px;
    }
    &--btn {
      width: 40px;
      height: 40px;
    }
    &--alarm {
      width: 39px;
      height: 32px;
    }
    &--btn_alarm {
      width: 39px;
      height: 32px;
    }
    &--fire {
      width: 40px;
      height: 40px;
    }
  }
}

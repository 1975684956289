.stock-list {
  display: flex;
  flex-wrap: wrap;
}

.stock-item__wrapper {
  width: calc(33% - 20px);
  margin: 0 10px 20px;

  @include for-768 {
    width: calc(50% - 10px);
    margin: 0 5px 10px;
  }

  @include for-480 {
    width: 100%;
    margin: 0 0 10px;
  }
}

.stock-item {
  max-width: 380px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  border: 1px solid $krona-gray--light;
  height: 100%;

  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
  }

  &__title {
    margin-bottom: 10px;
    font-size: 20px;
    text-transform: uppercase;
    @include PTSansNarrow-font;
    margin-bottom: 15px;
  }

  &__image {
    @include bg--cover;
    width: 100%;
    max-height: 150px;
    margin-bottom: 15px;
    overflow: hidden;

    &-img {
      object-fit: contain;
      width: 100%;
    }
  }

  &__btn {
    margin-top: auto;
  }
}

.stock-article {
  margin-bottom: 40px;

  &__image {
    &-img {
      max-width: 100%;
      margin-bottom: 20px;
    }
  }
}
.footer {
  border-top: 1px solid $krona-gray;
  padding: 40px 0;

  &-top {
    margin-bottom: 40px;
    display: flex;
    line-height: 20px;
    font-size: 14px;
  }

  &-links {
    display: flex;
    flex-direction: column;
    margin-right: auto;

    @include for-768 {
      margin-right: 20px;
      flex-shrink: 0;
    }

    &-item {
      margin-bottom: 10px;
    }
  }

  .footer-contacts {

    &-top {
      display: flex;
      justify-content: space-between;

      @include for-480 {
        flex-direction: column;
        justify-content: flex-start;
      }

      .text-bold {

        @include for-480 {
          order: 2;
        }
      }

      .footer-phone {

        @include for-480 {
          order: 1;
          margin-bottom: 20px;
        }
      }
    }
  }

  &-bottom {
    font-size: 10px;
    line-height: 14px;
  }

  .footer-phone {
    font-weight: bold;
    font-size: 20px;
  }
}
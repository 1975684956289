.main-products {
}

.product {
  display: flex;
  margin-bottom: 60px;

  @include for-1200 {
    flex-wrap: wrap;
  }

  &-content {
    width: 100%;

    &--left {
      max-width: 750px;
      margin-right: 10px;
      width: 100%;
      display: flex;
      flex-direction: column;

      @include for-1200 {
        // order: 2;
        margin-bottom: 30px;
        max-width: none;
        width: 100%;
      }
    }

    &--left-moved {
      width: 100%;
    }

    &--right {
      max-width: 460px;
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;

      @include for-1200 {
        // order: 1;
        margin: 0 auto 30px;
        width: 500px;
        width: 100%;
      }
    }
  
    &__top {
      border-bottom: 1px solid lighten($text-gray--light, 25);
      margin-bottom: 40px;

      &-list {
        display: flex;
        flex-wrap: wrap;

        &-item {
          padding: 10px 0;
        }
      }

      &-item {
        @include PTSansNarrow-font;
        margin-right: 15px;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
        white-space: nowrap;
  
        &--active {
          color: $krona-green;
          padding-bottom: 9px;
          border-bottom: 3px solid $krona-green;
        }
      }
    }

    &__image {
      @include bg--contain;
      width: 100%;
      height: 250px;
      margin-bottom: 20px;
      // background-image: url('../assets/images/complect-1.png'); 

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__switch {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      flex-wrap: wrap;

      &-text {
        margin-bottom: 10px;
        text-align: center;
        width: 100%;
        font-weight: bold;
      }

      &-item {
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background-color: #dce0e8;
        margin: 0 3px;
        cursor: pointer;
        transition-duration: .3s;

        &:hover {
          box-shadow: 0 2px 20px rgba(140, 154, 185, 0.25);
          transition-duration: .3s;
        }

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 30px;
          height: 30px;
          border-radius: 15px;
        }
        
        &--active {
          background-color: #4fd262;
        }
        
        &--white {
          &::before {
            background-color: white;
          }
        }
        
        &--black {
          &::before {
            background-color: black;
          }
        }
      }
    }
  
    &__desc {
      font-size: 14px;
      text-align: center;
      color: $text-gray--light;
      margin-bottom: 40px;
    }
  
    &__text {
      font-size: 14px;
      margin: 0 auto 40px;
      max-width: 800px;

      @include for-1200 {
        text-align: center;
      }
    }
  
    &__features {
      display: flex;
      flex-wrap: wrap;

      @include for-1200 {
        justify-content: center;
        max-width: 760px;
        margin: 0 auto;
      }
  
      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 95px;
        margin: 0 5px 20px;
      }
      
      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        width: 80px;
        min-width: 80px;
        margin-bottom: 10px;
        position: relative;

        &--image {
          z-index: 2;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &--hexagon {
          @include bg--contain;
          width: 80px;
          height: 80px;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1;

          &-gray {
            background-image: url('../assets/icons/hexagon--gray.svg');
          }
  
          &-white {
            background-image: url('../assets/icons/hexagon--white.svg');
          }
        }
      }
      
      &-title {
        font-size: 11px;
        text-align: center;
      }

      &-text {
        font-size: 14px;
        max-height: 100px;
        margin-right: 20px;
      }

      &-info {
        height: 130px;
        background-color: white;
      }
    }

    &__features-info {
      display: flex;
      align-items: center;
      border: 1px solid $krona-gray;
      border-radius: 3px;
      padding: 10px;
      width: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      left: 0;

      .product-content__features-icon {
        margin-bottom: 0;
        margin-right: 10px;
      }

      .product-content__features-icon--hexagon {
        background-image: url('../assets/icons/hexagon--green.svg');
      }

      .icon {
        fill: white;
      }
    }
  
    &__seven {
      display: flex;
      width: 100%;
      @include PTSansNarrow-font;
      margin-top: 20px;

      @include for-768 {
        flex-wrap: wrap;
      }

      &-block {
        padding: 15px 15px 0;
        position: relative;
        height: 200px;

        .icon {
          position: absolute;
          right: 20px;
          bottom: 20px;
        }

        &:nth-child(1) {
          background-color: #f9f9fa;
        }

        &:nth-child(2) {
          background-color: #dce0e8;
        }

        &:nth-child(3) {
          background-color: #4fd262;
          color: white;
        }

        &:nth-child(4) {
          background-color: #89959e;
          color: white;
        }

        &:nth-child(5) {
          background-color: #dce0e8;
        }

        &:nth-child(6) {
          background-color: #acd659;
          color: white;
        }

        &--1 {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 400px;
          background-color: white !important;

          &__num {
            font-weight: 900;
            margin-right: 50px;
            font-size: 288px;
            height: 290px;
            position: relative;

            span {
              font-size: 50px;
              font-weight: 700;
              text-transform: uppercase;
              display: block;
              position: absolute;
              bottom: 85px;
              right: -120px;
              transform: rotate(90deg);
            }
          }
        }
  
        &--2 {
          background-color: #acd659 !important;
          height: 200px;

          &__text {
            font-size: 16px;
            line-height: 18px;
            font-weight: bold;
          }
        }
      }

      &-title {
        font-size: 16px;
        line-height: 18px;
        font-weight: bold;
      }

      &--side {
        display: flex;
        min-width: 50%;
        height: 600px;

        &-left {
          background-color: rgba(255, 0, 0, 0.173);
          flex-direction: column;

          .product-content__seven-block {
            width: 100%;
          }
        }
  
        &-right {
          background-color: rgba(0, 68, 255, 0.173);
          display: flex;
          flex-wrap: wrap;

          .product-content__seven-block {
            width: 50%;
          }
        }
      }
    }

    &__bottom {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;
      margin-top: 20px;
  
      &-link {
        box-shadow: 0 0 20px rgb(0 0 0 / 20%);
        transition-duration: .3s;
        background-color: $text-gray--dark;
        color: white;
        @include PTSansNarrow-font;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 13px;
        padding: 13px 20px 10px 35px;
        position: relative;
        cursor: pointer;
  
        &:hover {
          background-color: $text-gray--blue;
          transition-duration: .3s;
        }
  
        &:nth-child(1) {
          &::before {
            content: '\003C';
            position: absolute;
            top: 12px;
            left: 15px;
          }
        }
  
        &:nth-child(2) {
          padding: 13px 35px 10px 20px;
          &::before {
            content: '\003E';
            position: absolute;
            top: 12px;
            right: 15px;
          }
        }
      }
    }
  }

  &-dops {
    max-width: 460px;
    width: 100%;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    margin: 20px auto;

    &__more {
      @extend .link--green;
      @extend .link;
      margin: 20px 0 0 0;
      display: none;
      cursor: pointer;

      // @include for-1200 {
      //   display: block;
      // }
    }

    &__btn {
      padding: 13px 35px 10px 20px;
      @include PTSansNarrow-font;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 13px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon-base--arrow {
        margin-left: 10px;

        fill: white;
      }
    }

    &__top {
      padding: 30px;
      background-color: $krona-gray--light;

      @include for-768 {
        padding: 20px;
      }

      @include for-480 {
        padding: 15px;
      }
    }

    &__title {
      font-weight: 700;
      margin-bottom: 10px;
    }

    &__subtitle {
      font-size: 13px;
      margin-bottom: 20px;
    }

    &__prices {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      &-item {

        &:last-child {
          .products-dops-prices-item-title {
            text-align: right;
          }
        }
      }

      &-title {
        font-size: 9px;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: -.03em;
      }

      &-price {
        font-size: 36px;
        font-weight: 700;

        @include for-768 {
          font-size: 30px;
        }

        @include for-480 {
          font-size: 24px;
        }
      }
    }

    &__list {
      padding: 30px;

      @include for-768 {
        padding: 20px;
      }

      @include for-480 {
        padding: 15px;
      }

      &-top {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 7px;

        &-title {
          font-size: 9px;
          margin-right: 5px;
          max-width: 45px;
          width: 100%;
          font-weight: 300;
          text-align: right;
          text-transform: uppercase;

          &:last-child {
            margin-right: 0;
          }

          &--setup {
            max-width: 50px;
          }
        }
      }

      &-title {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      ul {
        padding: 0;

        li {
          list-style: none;
          font-size: 12px;
          display: flex;
          align-items: center;
          margin-bottom: 7px;
        }
      }
      
      &-label {
        display: inline-block;
        position: relative;
        border: 1px solid #b1bbcc;
        border-radius: 1px;
        box-shadow: none;
        vertical-align: -4px;
        width: 20px;
        height: 20px;
        margin: 0 5px 0 0;
        background: white;
        cursor: pointer;
        flex-shrink: 0;
      }

      &-checkbox {
        display: none;
      }

      &-check {
        width: 12px;
        height: 8px;
        margin: 3px 0 0 3px;
        border-left: 3px solid #4fd262;
        border-bottom: 3px solid #4fd262;
        transform: rotate(-50deg);
        display: none;
      }

      /* Изменение внешнего вида флажка при выборе */
      .product-dops__list-checkbox:checked + .product-dops__list-check {
        display: block;
      }

      &-name {
        margin-right: auto;
      }

      &-price {
        margin-right: 5px;
        max-width: 45px;
        width: 100%;
        text-align: right;

        &:last-child {
          margin-right: 0;
        }

        &--setup {
          max-width: 50px;
        }
      }
    }

    &__list--hidden {
      height: 0;
      overflow: hidden;
      padding: 0;
    }
  }

  &-dops--sticky {
    top: 100px;
    position: sticky;
    margin: 0;
  }
}

.product-content--left {
  .index-system {
    padding: 20px 0;
    margin-bottom: 20px;
  
    .index-block-top {
      margin-bottom: 30px;
      margin-left: 30px;
  
      &::before {
        height: 30px;
        margin-right: 20px;
      }
  
      &-title {
        font-size: 26px;
      }
  
      &-subtitle {
        font-size: 16px;
      }
    }
  
    .index-system__content {
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      padding-left: 10px;
  
      &-item {
        width: calc(33% - 20px);
        margin: 0 10px;
      }
  
      @include for-768 {
        flex-wrap: nowrap;
  
        .index-system__content-item {
          width: 200px;
        }
      }
    }
  }
}

.index-system--wework {

  .index-system__content {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    

    &-item {
      width: calc(25% - 20px);
      margin: 0 10px;
    }

    @include for-980 {
      flex-wrap: nowrap;
    }
  }

}

.product {
  display: flex;

  &-content {
  
    &__features {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
  
      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 95px;
        margin: 0 5px 20px;
      }
      
      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        width: 80px;
        margin-bottom: 10px;
        position: relative;

        &--image {
          z-index: 2;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        } 

        &--hexagon {
          @include bg--contain;
          width: 80px;
          height: 80px;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1;

          &-gray {
            background-image: url('../assets/icons/hexagon--gray.svg');
          }
  
          &-white {
            background-image: url('../assets/icons/hexagon--white.svg');
          }
        }
      }
      
      &-title {
        font-size: 11px;
        text-align: center;
      }
    }

    &__bottom {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;
      margin-top: 20px;
  
      &-link {
        box-shadow: 0 0 20px rgb(0 0 0 / 20%);
        transition-duration: .3s;
        background-color: $text-gray--dark;
        color: white;
        @include PTSansNarrow-font;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 13px;
        padding: 13px 20px 10px 35px;
        position: relative;
        cursor: pointer;
  
        &:hover {
          background-color: $text-gray--blue;
          transition-duration: .3s;
        }
  
        &:nth-child(1) {
          &::before {
            content: '\003C';
            position: absolute;
            top: 12px;
            left: 15px;
          }
        }
  
        &:nth-child(2) {
          padding: 13px 35px 10px 20px;
          &::before {
            content: '\003E';
            position: absolute;
            top: 12px;
            right: 15px;
          }
        }
      }
    }
  }
}

.product--hexagray {
  display: flex;

  &-content {
  
    &__features {
      display: flex;
      flex-wrap: wrap;
  
      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 95px;
        margin: 0 5px 20px;
      }
      
      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        width: 80px;
        margin-bottom: 10px;
        position: relative;

        &--image {
          z-index: 2;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        } 

        &--hexagon {
          @include bg--contain;
          width: 80px;
          height: 80px;
          background-image: url('../assets/icons/hexagon--gray.svg');
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1;
        }
      }
      
      &-title {
        font-size: 11px;
        text-align: center;
      }
    }

    &__bottom {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;
      margin-top: 20px;
  
      &-link {
        box-shadow: 0 0 20px rgb(0 0 0 / 20%);
        transition-duration: .3s;
        background-color: $text-gray--dark;
        color: white;
        @include PTSansNarrow-font;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 13px;
        padding: 13px 20px 10px 35px;
        position: relative;
        cursor: pointer;
  
        &:hover {
          background-color: $text-gray--blue;
          transition-duration: .3s;
        }
  
        &:nth-child(1) {
          &::before {
            content: '\003C';
            position: absolute;
            top: 12px;
            left: 15px;
          }
        }
  
        &:nth-child(2) {
          padding: 13px 35px 10px 20px;
          &::before {
            content: '\003E';
            position: absolute;
            top: 12px;
            right: 15px;
          }
        }
      }
    }
  }
}

.product-content__type-work {
  background-color: #f9f9fa;
  padding: 60px 0;

  &-title {
    margin-bottom: 40px;
    font-size: 24px;
    text-align: center;
    font-weight: bold;
  }

  .hexa-features {
    display: flex;
    justify-content: center;

    .product-content__features-icon {
      width: 140px;
      height: 140px;
    }

    .product-content__features-icon--hexagon {
      width: 140px;
      height: 140px;
    }

    .product-content__features-item {
      width: 200px;
    }

    .product-content__features-title {
      font-size: 15px;
    }

    .product-content__features-info {
      width: 100%;
      height: 100%;
      padding: 10px 30px;
    }

    &__info {
      .icon-png {
        width: 90px;
        min-width: 90px;
        height: 90px;
      }

      .product-content__features-text {
        font-size: 16px;
      }

      &-cross {
        width: 20px;
        height: 20px;
        top: 15px;
        right: 15px;
      }
    }
  }
}

.product-content__tech-skud {
  @include bg--cover;
  background-image: url('../assets/images/call-action-techskud.webp');
  padding: 50px 0 150px;
  color: white;
  margin-bottom: 60px;

  &-title {
    font-size: 36px;
    margin-bottom: 30px;
    font-weight: bold;
    text-align: center;

    @include for-768 {
      font-size: 30px;
    }

    @include for-480 {
      font-size: 24px;
    }
  }

  &-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &-item {
    width: 285px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    &-image {
      @include bg--contain;
      width: 82px;
      height: 82px;
      margin-bottom: 10px;

      &--1 {
        background-image: url('../assets/icons/products/product-tech-skud/ic_tech-skud-1.png')
      }

      &--2 {
        background-image: url('../assets/icons/products/product-tech-skud/ic_tech-skud-2.png')
      }

      &--3 {
        background-image: url('../assets/icons/products/product-tech-skud/ic_tech-skud-3.png')
      }

      &--4 {
        background-image: url('../assets/icons/products/product-tech-skud/ic_tech-skud-4.png')
      }
    }

    &-title {
      text-align: center;
      color: white;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 20px;
    }
  }

  .hexa-features__info--block {
    width: 100%;
    margin-top: 50px;
    position: relative;
  }

  .hexa-features__info {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
}

.smart-house {
  position: relative;
  max-width: 1366px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  &__info {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, .7);
    padding: 20px;
    font-size: 14px;
    line-height: 1.4;
    top: 0;
    transition: .3s;

    @include for-768 {
      display: none;
    }

    &-cross {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 14px;
      height: 14px;
      cursor: pointer;
      @include bg--contain;
      background-image: url('../assets/icons/cross-white.svg');
    }
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    transform: translate(-50%, -50%);
    padding: 20px 5px;
    min-width: 170px;
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    transition: .3s ease;
    backface-visibility: hidden;
    will-change: transform;
    background-color: rgba(79, 210, 98, .9);
  }

  &__cover {
    right: 0;
    height: 100%;
    left: 0;
    width: 100%;
    top: 0;
    position: absolute;
    z-index: 2;
    transform: scale(0);
    transition: .3s;
    background-color: rgba(79, 210, 98, .9);
  }

  &__block {
    position: relative;
    width: 25%;
    height: 219px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include bg--cover;

    @include for-768 {
      width: 50%;
    }

    &--vertical {
      height: 438px;
      flex: 0 0 auto;

      @include for-768 {
        height: 219px;
      }
    }

    &--square {
      position: relative;
      flex: 0 0 auto;
    }

    &--horizontal {
      position: absolute;
      left: 25%;
      top: 219px;
      height: 219px;
      width: 50%;

      @include for-768 {
        position: relative;
        left: 0;
        top: 0;
        height: 219px;
        width: 100%;
      }
    }

    &--big {
      height: 498px;
      width: 50%;

      @include for-768 {
        height: 219px;
        width: 100%;
      }

      .smart-house__title {
        font-size: 25px;
      }

      .smart-house__info {
        font-size: 16px;
      }
    }

    &--1 {
      background-image: url('../assets/images/smart-house/smart-house--1.webp');

      .smart-house__info {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &--2 {
      background-image: url('../assets/images/smart-house/smart-house--2.webp');

      .smart-house__info {
        top: 0;
        left: 0;
        width: 200%;
        height: 100%;
      }
    }

    &--3 {
      background-image: url('../assets/images/smart-house/smart-house--3.webp');

      .smart-house__info {
        top: 0;
        right: 0;
        width: 200%;
        height: 100%;
      }
    }

    &--4 {
      background-image: url('../assets/images/smart-house/smart-house--4.webp');

      .smart-house__info {
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }

    &--5 {
      background-image: url('../assets/images/smart-house/smart-house--5.webp');

      .smart-house__info {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &--6 {
      background-image: url('../assets/images/smart-house/smart-house--6.webp');

      .smart-house__info {
        top: 0;
        left: 0;
        width: 200%;
        height: 100%;
      }
    }

    &--7 {
      background-image: url('../assets/images/smart-house/smart-house--7.webp');

      .smart-house__info {
        top: 0;
        left: 0;
        width: 200%;
        height: 100%;
      }
    }

    &--8 {
      background-image: url('../assets/images/smart-house/smart-house--8.webp');

      .smart-house__info {
        top: 0;
        right: 0;
        width: 200%;
        height: 100%;
      }
    }

    &--9 {
      background-image: url('../assets/images/smart-house/smart-house--9.webp');

      .smart-house__info {
        top: 0;
        right: 0;
        width: 200%;
        height: 100%;
      }
    }

    &--10 {
      background-image: url('../assets/images/smart-house/smart-house--10.webp');

      .smart-house__info {
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }

    &--11 {
      background-image: url('../assets/images/smart-house/smart-house--11.webp');

      .smart-house__info {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__block--active {
    .smart-house__cover {
      transform: scale(1);
      transition: .3s;
    }
    
    .smart-house__title {
      background-color: transparent;
    }

    .smart-house__info {
      transition: .3s;
      z-index: 4;
      opacity: 1;
    }
  }

  &__features {
    display: flex;
    width: 100%;
    margin: 30px auto;

    @include for-480 {
      flex-wrap: wrap;
    }

    &-item {
      width: 33.3%;
      display: flex;
      align-items: center;
      font-weight: bold;

      @include for-768 {
        font-size: 14px;
      }

      @include for-480 {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }

  &__icon {
    @include bg--contain;
    width: 82px;
    height: 82px;
    margin-right: 10px;
    flex-shrink: 0;

    @include for-980 {
      width: 60px;
      height: 60px;
    }

    &--1 {
      background-image: url('../assets/icons/components/smart-house/ic_smart-house--1.webp')
    }

    &--2 {
      background-image: url('../assets/icons/components/smart-house/ic_smart-house--2.webp')
    }

    &--3 {
      background-image: url('../assets/icons/components/smart-house/ic_smart-house--3.webp')
    }
  }

  &__solo {
    width: 100%;
    position: relative;

    &-img {
      width: 100%;
    }
    
    &-icon {
      @include bg--contain;
      width: 50px;
      height: 50px;
      background-color: rgba(79, 210, 98);
      border-radius: 50%;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;

      @include for-768 {
        width: 30px;
        height: 30px;
      }

      &:after {
        content: '';
        width: 65px;
        height: 65px;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(79, 210, 98, .3);

        @include for-768 {
          width: 40px;
          height: 40px;
        }
      }

      &--1 {
        left: 87%;
        top: 23%;
        background-image: url('../assets/icons/components/smart-house/ic_smart-house__solo--1.webp')
      }

      &--2 {
        left: 83.6% !important;
        top: 52% !important;
        background-image: url('../assets/icons/components/smart-house/ic_smart-house__solo--2.webp')
      }

      &--3 {
        left: 68.6% !important;
        top: 17% !important;
        background-image: url('../assets/icons/components/smart-house/ic_smart-house__solo--3.webp')
      }

      &--4 {
        left: 69.9% !important;
        top: 57% !important;
        background-image: url('../assets/icons/components/smart-house/ic_smart-house__solo--4.webp')
      }

      &--5 {
        left: 54% !important;
        top: 71% !important;
        background-image: url('../assets/icons/components/smart-house/ic_smart-house__solo--5.webp')
      }

      &--6 {
        left: 53% !important;
        top: 39% !important;
        background-image: url('../assets/icons/components/smart-house/ic_smart-house__solo--6.webp')
      }

      &--7 {
        left: 37% !important;
        top: 37% !important;
        background-image: url('../assets/icons/components/smart-house/ic_smart-house__solo--7.webp')
      }

      &--8 {
        left: 18.6% !important;
        top: 56% !important;
        background-image: url('../assets/icons/components/smart-house/ic_smart-house__solo--8.webp')
      }
    }
  }
}

.mobile-app {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @include for-980 {
    flex-wrap: wrap;
    justify-content: center;
  }

  @include for-480 {
    flex-direction: column;
  }

  &__block {
    height: 370px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    &:nth-child(1) {
      width: 345px;

      .mobile-app__title,
      .mobile-app__text  {
        text-align: right;
      }

      @include for-980 {
        order: 3;
        width: calc(50% - 20px);
        margin-right: 15px;
        height: auto;
        align-items: flex-end;
      }

      @include for-480 {
        order: 2;
        width: 100%;
        margin: 0 auto 20px;
        align-items: center;

        .mobile-app__title,
        .mobile-app__text {
          text-align: center;
        }
      }
    }

    &:nth-child(2) {
      width: 170px;
      flex-shrink: 0;

      @include for-980 {
        order: 1;
        width: calc(50% - 20px);
        margin-right: 15px;
        align-items: flex-end;
      }

      @include for-480 {
        order: 1;
        width: 100%;
        margin: 0 auto 20px;

        .mobile-app__image {
          margin: 0 auto;
        }
      }
    }

    &:nth-child(3) {
      width: 170px;
      flex-shrink: 0;

      @include for-980 {
        order: 2;
        width: calc(50% - 20px);
        margin-left: 15px;
        align-items: flex-start;
      }

      @include for-480 {
        order: 3;
        width: 100%;
        margin: 0 auto 20px;

        .mobile-app__image {
          margin: 0 auto;
        }
      }
    }

    &:nth-child(4) {
      width: 345px;
      align-items: flex-start;

      .mobile-app__title {
        text-align: left;
      }

      .mobile-app__text {
        text-align: left;
      }

      @include for-980 {
        order: 4;
        width: calc(50% - 20px);
        margin-left: 15px;
        height: auto;
        align-items: flex-start;
      }

      @include for-480 {
        order: 4;
        width: 100%;
        margin: 0 auto 20px;
        align-items: center;

        .mobile-app__title,
        .mobile-app__text {
          text-align: center;
        }
      }
    }
  }

  &__title {
    font-size: 28px;
    margin-bottom: 20px;
  }

  &__image {
    @include bg--contain;
    width: 100%;
    height: 100%;
    max-width: 170px;

    &--1 {
      background-image: url('../assets/images/mobile-app/mobile-app--1.webp')
    }

    &--2 {
      background-image: url('../assets/images/mobile-app/mobile-app--2.webp')
    }
  }
}




.smart-house__block--active.smart-house__block--1 {
  .smart-house__info {
    left: 100%;
  }
}
.smart-house__block--active.smart-house__block--2,
.smart-house__block--active.smart-house__block--3 {
  .smart-house__info {
    top: 100%;
  }
}
.smart-house__block--active.smart-house__block--4 {
  .smart-house__info {
    right: 100%;
  }
}
.smart-house__block--active.smart-house__block--5,
.smart-house__block--active.smart-house__block--6,
.smart-house__block--active.smart-house__block--7,
.smart-house__block--active.smart-house__block--8,
.smart-house__block--active.smart-house__block--9 {
  .smart-house__info {
      top: -100%;
  }
}
.smart-house__block--active.smart-house__block--10 {
  .smart-house__info {
    right: -100%;
  }
}
.smart-house__block--active.smart-house__block--11 {
  .smart-house__info {
    left: -100%;
  }
}



.product-content__type-work.product-content__type-work--eto {
  .product-content__type-work-title {
    font-size: 34px;
  }

  .product-content__features-item {
    width: calc(33% - 10px);

    @include for-768 {
      width: calc(50% - 10px);
    }

    @include for-480 {
      width: calc(100% - 10px);
    }
  }

  .product-content__features-icon {
    width: 200px;
    height: 200px;

    &--hexagon {
      width: 200px;
      height: 200px;
    }

    @include for-768 {
      width: 180px;
      height: 180px;

      &--hexagon {
        width: 180px;
        height: 180px;
      }
    }

    @include for-480 {
      width: 160px;
      height: 160px;

      &--hexagon {
        width: 160px;
        height: 160px;
      }
    }
  }

  .product-content__features-title {
    font-size: 18px;
    max-width: 270px;
    margin-top: 20px;
  }
}

.main-products {
  .index-block-top {
    margin-left: 60px;
  }

  .index-system__content {
    padding-left: 40px;

    @include for-980 {
      flex-wrap: nowrap;
      padding-left: 0;
    }
  }

  .index-about__text {
    padding-left: 60px;
  }
}
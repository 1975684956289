.crumbs {
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-top: 20px;
  margin-bottom: 30px;

  &-item {
    color: $text-gray--blue;

    &--link {
      color: $text-gray--light;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-arrow {
    margin: 0 5px;
    color: $text-gray--light;

    &--active {
      color: $text-gray--blue;
    }
  }
}
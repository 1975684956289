.index {
  position: relative;
}

.index-bg {
  display: block;
  min-height: 670px;
  background: #fff;
  background-image: url('../assets/images/index/bg--main.png');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.index-first {
  position: relative;
  z-index: 10;
  display: flex;
  color: white;
  margin-bottom: 20px;
  margin-top: 80px;
  align-items: center;

  @include for-980 {
    flex-direction: column;
  }

  @include for-768 {
    margin-top: 50px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    text-align: left;
    height: 300px;
    
    @include for-980 {
      order: 1;
      flex-wrap: nowrap;
      flex-direction: column;
      height: auto;
      width: 100%;
      padding-left: 0;
      align-items: center;
      max-width: 680px;
      margin: 0 auto 40px;
      align-items: flex-start;
      height: auto;
    }
  }

  &__title {
    font-size: 30px;
    font-weight: bold;
    padding-bottom: 10px;
    margin-bottom: auto;
    text-transform: uppercase;

    @include for-980 {
      font-size: 40px;
      padding-bottom: 40px;
    }

    @include for-480 {
      font-size: 30px;
    }
  }

  &__list {
    font-size: 21px;
    line-height: 24px;
    font-weight: 500;
    padding-left: 20px;
    padding-bottom: 10px;
    margin-bottom: auto;

    @include for-980 {
      font-size: 28px;
      line-height: 32px;
      padding-bottom: 40px;
    }

    @include for-480 {
      font-size: 20px;
      line-height: 24px;
    }

    &-item {
      list-style: disc;
      margin-bottom: 5px;
    }
  }

  &__btn {
    max-width: 360px;
    font-size: 24px;
    padding: 15px 10px;

    @include for-980 {
      padding: 15px 30px;
    }

    @include for-480 {
      font-size: 20px;
      max-width: 320px;
    }
  }

  &__image {
    max-width: 680px;
    width: 100%;
    height: 380px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.12);
    border-radius: 10px;
    overflow: hidden;
    flex-shrink: 0;
    
    @include for-1200 {
      max-width: 600px;
    }

    @include for-980 {
      max-width: 680px;
      height: 265px;
      order: 2;
      margin: 0 auto;
    }

    &-img {
      object-fit: cover;
      width: calc(100% + 10px);
      height: 100%;
      margin-left: -5px;
    }
  }
}

.index-block {
  &-top {
    position: relative;
    margin-bottom: 30px;
    margin-left: 120px;

    @include for-980 {
      margin-left: 60px;
    }

    &--left {
      margin-right: auto;
    }

    &--center {
      margin: 0 auto 30px;

      &:after {
        position: absolute;
        content: '';
        left: 100%;
        top: 0px;
        width: 8px;
        height: 40px;
        margin-left: 40px;
        background-color: $krona-green;
        transform: skew(-30deg);
      }
    }

    &:before {
      position: absolute;
      content: '';
      right: 100%;
      top: 0px;
      width: 8px;
      height: 40px;
      margin-right: 40px;
      background-color: $krona-green;
      transform: skew(-30deg);
    }

    &-title {
      font-size: 36px;
      margin-bottom: 10px;
      font-weight: 700;

      @include for-768 {
        font-size: 26px;
      }
    }
  
    &-subtitle {
      font-size: 24px;
      font-weight: 300;
      opacity: 0.7;

      @include for-768 {
        font-size: 18px;
      }
    }
  }
}

.index-tarif {
  margin-bottom: 20px;

  &__list {
    display: flex;
    margin: 0 auto;
    max-width: 1000px;

    @include for-768 {
      flex-wrap: wrap;
    }
  }

  &__list-item {
    border-radius: 5px;
    margin: 0 10px 10px;
    padding: 25px 20px;
    border: 1px solid #eee;
    display: flex;
    flex-direction: column;
    width: calc(33.3% - 20px);

    @include for-768 {
      width: calc(50% - 20px);
    }

    @include for-480 {
      width: calc(100% - 20px);
    }
  }

  &__list-title {
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__list-feature {
    padding-left: 15px;
    margin-bottom: auto;

    &-item {
      list-style: disc;
      margin-bottom: 5px;
      font-size: 14px;
    }
  }

  &__list-tarif {
    margin-top: 20px;
    margin-bottom: 20px;

    &-item {
      margin-bottom: 10px;
    }

    &-price {
      font-weight: 700;
    }
  }

  &__btn {
    width: 100%;
    box-shadow: none;
  }

  &__description {
    max-width: 980px;
    margin: 0 auto;
    padding-top: 20px;
  }
}

.index-about {
  display: flex;
  justify-content: flex-start;
  margin: 50px 0;

  @include for-768 {
    flex-direction: column;
  }

  &__info {
    max-width: 50%;
    margin-right: 10px;
    overflow: hidden;

    @include for-768 {
      max-width: 100% !important;
      width: 100%;
    }
  }

  &__text {
    max-height: 270px;
    padding-left: 120px;
    padding-right: 10px;

    @include for-980 {
      padding-left: 0;
    }

    ul {
      margin-left: 40px;
      li {
        list-style: disc;
      }
    }
  }

  &__image {
    width: 100%;
    height: 400px;

    @include for-768 {
      margin: 20px auto;
    }

    &-img {
      @include bg--cover;
      width: 100%;
      height: 500px;

      &--index {
        height: 400px;
        background-image: url('../assets/images/block-about/index-content-right.webp');
      }

      &--skud {
        height: 500px;
        background-image: url('../assets/images/block-about/skud-content-right.webp');
      }

      &--fog {
        height: 500px;
        background-image: url('../assets/images/block-about/fog-content-right.webp');
      }

      &--alarm-button {
        height: 500px;
        background-image: url('../assets/images/block-about/alarm_button-content-right.webp');
      }

      &--alarm-security {
        height: 500px;
        background-image: url('../assets/images/block-about/alarm_security-content-right.webp');
      }

      &--fire {
        height: 500px;
        background-image: url('../assets/images/block-about/fire-content-right.webp');
      }

      &--intercoms_start {
        height: 500px;
        background-image: url('../assets/images/block-about/intercoms_start-content-right.webp');
      }
    }
  }

  &--gray {
    .index-about__info {
      max-width: 50%;
      background-color: #f9f9fa;
      margin-right: 0;
      padding: 20px 10px 20px 20px;
      height: 500px;
      max-height: 500px;

      @include for-768 {
        height: auto;
      }
    }

    .index-about__text {
      max-height: 310px;

      @include for-1200 {
        max-height: 260px;
      }
    }
  }
}

.index-app {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__content {
    display: flex;

    @include for-980 {
      flex-direction: column;
    }

    @include for-768 {
      width: 100%;
    }
  }

  &__features {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    width: 530px;

    @include for-1200 {
      width: 480px;
    }
    
    @include for-980 {
      margin-top: 20px;
      order: 2;
    }
    
    @include for-768 {
      width: 100%;
      max-width: 480px;      
    }
    
    @include for-480 {
      margin-top: 15px;  
    }

    &-item {
      padding: 20px 25px;
      height: 180px;
      width: calc(50% - 20px);
      margin: 0 10px 20px;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;
      }

      @include for-768 {
        padding: 20px 20px 20px 30px;
        height: 160px;
      }

      @include for-480 {
        padding: 10px;
      }
    }

    &-icon {
      width: 70px;
      height: 70px;
      margin-bottom: 10px;
      flex-shrink: 0;
      @include bg--contain;

      &--1 {
        background-image: url('../assets/icons/index-app/ic_app-1.svg');
      }

      &--2 {
        width: 90px;
        background-image: url('../assets/icons/index-app/ic_app-2.svg');
      }

      &--3 {
        background-image: url('../assets/icons/index-app/ic_app-3.svg');
      }

      &--4 {
        width: 60px;
        background-image: url('../assets/icons/index-app/ic_app-4.svg');
      }
    }

    &-name {
      color: $text-gray--blue;
      font-size: 24px;
      line-height: 30px;
      font-weight: 300;

      @include for-768 {
        font-size: 18px;
        line-height: 24px;
      }

      @include for-480 {
        font-size: 16px;
        line-height: 18px;
      }
    }

    &-text {
      width: 100%;
      display: none;
      font-size: 14px;
    }

    &-item--active {
      padding: 20px;
      flex-direction: row;
      flex-wrap: wrap;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;

      @include for-480 {
        padding: 10px;
      }
      
      .index-app__features-icon {
        width: 35px;
        height: 35px;
        margin-right: 10px;

        &--2 {
          width: 45px;
        }
  
        &--4 {
          width: 30px;
        }
      }

      .index-app__features-name {
        font-size: 18px;
        line-height: 18px;

        @include for-480 {
          font-size: 13px;
          line-height: 15px;
        }
      }

      .index-app__features-text {
        display: block;

        @include for-480 {
          font-size: 12px;
        }
      }
    }
  }

  &-image {
    @include bg--contain;
    width: 300px;
    height: 400px;
    margin-left: 50px;
    background-color: $krona-green;
  }

  &__mockup {
    margin-left: 20px;
    width: 500px;
    height: 460px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &-links {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;

      .link {
        margin: 5px;
        cursor: pointer;

        .icon {
          transition-duration: .3s;
          width: 160px;
          height: 50px;

          @include for-400 {
            width: 140px;
          }
        }

        &:hover {
          .icon {
            fill: $krona-green--dark;
            transition-duration: .3s;
          }
        }
      }
    }
    
    @include for-980 {
      order: 1;
      margin-left: 0;
    }
    
    @include for-768 {
      width: 100%;
      overflow: hidden;
    }

    &-circle {
      position: absolute;
      z-index: 1;
      width: 500px;
      height: 500px;
      @include bg--contain;
      background-image: url('../assets/images/index/app-circle.webp');

      @include for-980 {
        height: 460px;
      }
    }

    &-device {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 2;
      width: 500px;
      height: 570px;
      @include bg--contain;
      background-image: url('../assets/images/index/app-phone.png');

      @include for-980 {
        height: 460px;
      }

      @include for-480 {
        width: 300px;
      }
    }
  }
}

.index-defend--wrapper {
  // @include for-980 {
  //   display: none;
  // }
}

.index-defend {
  padding: 80px 0;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @include for-768 {
      flex-wrap: wrap;
    }

    &-item {
      width: 190px;
      height: 200px;
      padding: 15px 0 0 20px;
      background-color: $krona-gray--light;
      border: 1px solid white;
      transition: .3s;
      @include bg--contain;
      background-position: right bottom;
      cursor: pointer;

      @include for-768 {
        width: 170px;
      }

      &--1 {
        background-size: 140px;
        background-image: url('../assets/images/index/feat-icon-1.png');

        &:hover {
          background-image: url('../assets/images/index/feat-icon-1--hover.png');
        }
      }

      &--2 {
        background-size: 105px;
        background-image: url('../assets/images/index/feat-icon-2.png');

        &:hover {
          background-image: url('../assets/images/index/feat-icon-2--hover.png');
        }
      }

      &--3 {
        background-size: 100px;
        background-image: url('../assets/images/index/feat-icon-3.png');

        &:hover {
          background-image: url('../assets/images/index/feat-icon-3--hover.png');
        }
      }

      &--4 {
        background-size: 115px;
        background-image: url('../assets/images/index/feat-icon-4.png');

        &:hover {
          background-image: url('../assets/images/index/feat-icon-4--hover.png');
        }
      }

      &--5 {
        background-size: 130px;
        background-image: url('../assets/images/index/feat-icon-5.png');

        &:hover {
          background-image: url('../assets/images/index/feat-icon-5--hover.png');
        }
      }
    }

    &-title {
      @include PTSansNarrow-font;
      font-size: 16px;
      color: $text-gray--blue;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  &__hover {
    transition: .3s;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background-color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    max-width: 950px;
    height: 400px;
    opacity: 0;
    z-index: -1;
    display: flex;

    &-image {
      min-width: 20%;
      height: 100%;
      @include bg--cover;

      @include for-768 {
        min-width: 35%;
      }

      @include for-480 {
        display: none;
      }
      
      &--1 {
        background-image: url('../assets/images/index/feat-1.webp');
      }
      
      &--2 {
        background-image: url('../assets/images/index/feat-2.webp');
      }
      
      &--3 {
        background-image: url('../assets/images/index/feat-3.webp');
      }
      
      &--4 {
        background-image: url('../assets/images/index/feat-4.webp');
      }
      
      &--5 {
        background-image: url('../assets/images/index/feat-5.webp');
      }
    }

    &-text {
      @include Helvetica-font;
      font-weight: 300;
      width: 60%;
      height: 100%;
      padding: 50px 40px 40px;
      overflow: auto;
      font-size: 14px;
      line-height: 24px;

      @include for-980 {
        padding: 20px;
      }

      @include for-768 {
        min-width: 65%;
        width: 100%;
      }

      @include for-480 {
        min-width: 100%;
        width: 100%;
        padding: 10px;
      }
    }

    &-cross {
    }

    .index-defend__content-item {
      background-color: white;
      min-width: 20%;

      @include for-768 {
        display: none;
      }
    }
  }
}

.index-eto {
  padding: 50px 0;
  background-color: $krona-gray--light;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;

    &-icon {
      @include bg--contain;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;

      &--1 {
        width: 63px;
        height: 64px;
        background-image: url('../assets/icons/index-eto/ic_eto-1.svg');

        @include for-480 {
          width: 53px;
          height: 54px;
        }
      }

      &--2 {
        width: 61px;
        height: 63px;
        background-image: url('../assets/icons/index-eto/ic_eto-2.svg');

        @include for-480 {
          width: 51px;
          height: 53px;
        }
      }

      &--3 {
        width: 50px;
        height: 62px;
        background-image: url('../assets/icons/index-eto/ic_eto-3.svg');

        @include for-480 {
          width: 40px;
          height: 52px;
        }
      }
    }

    // @include for-768 {
    //   flex-direction: column;
    // }

    // &-item {
    //   width: calc(33% - 60px);
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   flex-direction: column;

    //   @include for-980 {
    //     width: 33.3%;
    //   }

    //   @include for-768 {
    //     width: 100%;
    //     margin-bottom: 40px;
    //   }

    //   &:last-child {
    //     margin-bottom: 20px;
    //   }
    // }

    // &-image {
    //   position: relative;
    //   width: 260px;
    //   height: 190px;
    //   margin-bottom: 20px;

    //   @include for-480 {
    //     width: 100%;
    //     height: 150px;
    //   }
    // }

    // &-hexagon {
    //   @include bg--cover;
    //   width: 240px;
    //   height: 220px;
    //   background-image: url('../assets/icons/index-eto/ic_eto-hexagon.svg');
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   z-index: 1;

    //   @include for-480 {
    //     width: 180px;
    //     height: 150px;
    //   }
    // }

    // &-text {
    //   font-size: 18px;
    //   text-align: center;

    //   @include for-768 {
    //     font-size: 16px;
    //   }
    // }
  }
}

.index-system {
  padding: 50px 0;

  &__content {
    display: flex;
    flex-wrap: wrap;
    padding-left: 90px;

    @include for-980 {
      overflow-x: scroll;
      justify-content: flex-start;
      flex-wrap: nowrap;
      padding-left: 0;
    }

    &-item {
      position: relative;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      padding: 40px 10px 10px;
      flex-shrink: 0;
      width: calc(33% - 40px);
      margin: 0 20px;
    }

    &-num {
      font-size: 150px;
      color: $krona-gray;
      position: absolute;
      top: -10px;
      right: -15px;
      font-weight: bold;
      line-height: 1;
      z-index: -1;
    }

    &-info {
      margin-top: 40px;

      &-title {
        font-size: 17px;
        font-weight: bold;
        @include PTSansNarrow-font;
        margin-bottom: 8px;
      }

      &-text {
        font-size: 12px;
        max-width: 200px;
      }
    }
  }
}

.index-partners {
  padding: 50px 0;

  &__content {
    display: flex;
    flex-wrap: wrap;
    padding-left: 120px;
    align-items: center;

    @include for-980 {
      flex-wrap: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
      padding-left: 0;
    }

    &-item {
      min-width: 133px;
      width: 133px;
      height: 128px;
      border: 1px solid #f9f9fa;
      @include bg--contain;
      margin: 0px -1px -1px 0;

      &--1 {
        background-image: url('../assets/images/index/logo-1.webp');
      }

      &--2 {
        background-image: url('../assets/images/index/logo-2.webp');
      }

      &--3 {
        background-image: url('../assets/images/index/logo-3.webp');
      }

      &--4 {
        background-image: url('../assets/images/index/logo-4.webp');
      }

      &--5 {
        background-image: url('../assets/images/index/logo-5.webp');
      }

      &--6 {
        background-image: url('../assets/images/index/logo-6.webp');
      }

      &--7 {
        background-image: url('../assets/images/index/logo-7.webp');
      }

      &--8 {
        background-image: url('../assets/images/index/logo-8.webp');
      }

      &--9 {
        background-image: url('../assets/images/index/logo-9.webp');
      }

      &--10 {
        background-image: url('../assets/images/index/logo-10.webp');
      }

      &--11 {
        background-image: url('../assets/images/index/logo-11.webp');
      }

      &--12 {
        background-image: url('../assets/images/index/logo-12.webp');
      }

      &--13 {
        background-image: url('../assets/images/index/logo-13.webp');
      }

      &--14 {
        background-image: url('../assets/images/index/logo-14.webp');
      }

      &--15 {
        background-image: url('../assets/images/index/logo-15.webp');
      }

      &--16 {
        background-image: url('../assets/images/index/logo-16.webp');
      }

      &--17 {
        background-image: url('../assets/images/index/logo-17.webp');
      }

      &--18 {
        background-image: url('../assets/images/index/logo-18.webp');
      }

      &--19 {
        background-image: url('../assets/images/index/logo-19.webp');
      }

      &--20 {
        background-image: url('../assets/images/index/logo-20.webp');
      }
    }
  }
}

.hexa-features {
  position: relative;

  &__item {
    cursor: pointer;
  }

  &__info {
    position: absolute;

    &--show {
      opacity: 1;
      z-index: 5;
    }

    &--hide {
      opacity: 0;
      z-index: -1;
    }

    &-cross {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 14px;
      height: 14px;
      cursor: pointer;
      @include bg--contain;
      background-image: url('../assets/icons/cross-gray.svg');
    }
  }
}

.scrollbar-y {
  overflow-y: scroll;
    
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-clip: padding-box;
    background: rgba(0, 0, 0, .2);
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, .4);
    }
  }
}

.doc-list {
  display: flex;
  flex-wrap: wrap;
}

.doc-item {
  width: 33%; 
  padding: 5px;
  margin-bottom: 20px;

  &__image {
    width: 50px;
    height: 60px;
    @include bg--contain;
    background-image: url('../assets/icons/block-docs/ic_doc--green.webp');
    margin: 0 auto 15px;
  }

  &__title {
    text-align: center;
    margin-bottom: auto;
    font-size: 15px;
    text-decoration: underline;
    margin-bottom: 10px;
  }

  &__info {
    text-align: center;
  }

  &__link {
    max-width: 240px;
    margin: 0 auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
    transition-duration: .3s;

    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, .2);
      transition-duration: .3s;

      .doc-item__title {
        color: $krona-green;
      }
    }
  }
}

.pay {
  display: flex;
  flex-direction: column;
  @include PTSansNarrow-font;

  &__row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &__title {
    width: 100%;
    padding: 10px 15px;
    background-color: $krona-green--dark;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    color: white;
  }

  &__item {
    height: 200px;
    width: 20%;
    border: 1px solid white;
    background-color: $krona-gray--light;
    display: flex;
    padding: 20px;
    @include bg--contain;

    &-title {
      font-weight: bold;
    }

    &--1 {
      background-image: url('../assets/icons/block-pay/pay-1.webp');
    }

    &--2 {
      background-image: url('../assets/icons/block-pay/pay-2.webp');
    }

    &--3 {
      background-image: url('../assets/icons/block-pay/pay-3.webp');
    }

    &--4 {
      background-image: url('../assets/icons/block-pay/pay-4.webp');
    }

    &--5 {
      background-image: url('../assets/icons/block-pay/pay-5.webp');
    }

    &--6 {
      width: 50%;
      background-position: 100%;
      background-image: url('../assets/icons/block-pay/pay-6.webp');
    }
  }
}

.main-clients {
  .page-title {
    margin-bottom: 60px;
  }
  
  .subs-section {
    padding: 30px 0;
  }
}

.main-index {
  .index-block {
    padding-top: 60px;
  }
}
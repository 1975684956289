body {
  @include Helvetica-font;

  background-color: white;
  color: $text-gray--blue;
  font-size: 17px;
  overflow-x: hidden;
  margin: 0 auto;
  min-height: 100vh;
}

.main {
  min-height: 100vh;
  padding-top: 154px;

  @include for-980 {
    padding-top: 84px;
  }

  &--mob {
    min-height: auto;
  }
}

a {
  cursor: pointer;
  color: $text-gray--blue;
  text-decoration: none;
}

*,
::after,
::before {
  box-sizing: border-box
}

.d-flex {
  display: flex;
}

@media (prefers-reduced-motion:no-preference) {
  :root {
    scroll-behavior: smooth
  }
}

.btn {
  background-color: $krona-green;
  color: white;
  box-shadow: 0 0 20px rgb(0 0 0 / 20%);
  cursor: pointer;
  transition-duration: 0.3s;
  padding: 11px 20px 10px;
  text-align: center;
  border: none;

  &:hover {
    background-color: $krona-green--dark;
    transition-duration: 0.3s;
  }
}

input, button {
  outline: none;
  border: none;
}

.link {
  color: $krona-green;
  transition-duration: .3s;

  &:hover {
    color: $krona-green--dark;
    text-decoration: underline;
    transition-duration: .3s;
  }
}

.link--green {
  color: $krona-green !important;

  &:hover {
    color: $krona-green--dark !important;
  }
}

.text-bold {
  font-weight: bold;
}

.page-title {
  font-size: 46px;
  margin-bottom: 30px;

  @include for-768 {
    font-size: 36px;
  }

  @include for-480 {
    font-size: 30px;
  }
}

.page-date {
  display: inline-block;
  font-size: 12px;
  color: $text-gray--light;
  margin-bottom: 20px;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

.no-scroll {
  overflow: hidden;
}

.d-none {
  display: none !important;
}

.w-100 {
  width: 100%;
}

.pointer-event--none {
  pointer-events: none;
}

.mob {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0 100px;

  h1 {
    text-align: center;
  }
}
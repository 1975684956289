
.section-partners {
  .brands-wrapper {
    display: flex;
    height: 450px;
    margin: 0 auto;
    max-width: 1166px;
    overflow: hidden;
    position: relative;
    transform: translate3d(0, 0, 0);

    &::after {
      background: linear-gradient(270deg,
          white 0%,
          rgba(255, 255, 255, 0) 100%);
      content: '';
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 80px;
    }

    &::before {
      background: linear-gradient(90deg, white 0%, rgba(255, 255, 255, 0) 100%);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 80px;
      z-index: 1;
    }

    .brands-container {
      animation: slide 1286s alternate infinite linear;
      background: url('../assets/images/index/clients-logo.jpg') 0 0 repeat-x;
      display: flex;
      height: 450px;
      min-width: 99999px;
      transform: translate3d(0, 0, 0);
      flex-wrap: wrap;
    }
  }
}

@keyframes slide {
  100% {
    transform: translateX(-100%);
  }
}
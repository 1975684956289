.main-contacts {

  .map {
    width: 100%;
    height: 400px;
    background-color: $krona-gray;
    margin-top: -100px;
    z-index: 1;
    position: relative;
    margin-bottom: 40px;

    @include for-768 {
      height: 300px;
      margin-top: 30px;
    }

    &-img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.contacts-block {
  position: relative;
  font-size: 13px;
  line-height: 1.5;

  .text-bold {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .contacts-block__info {
    z-index: 10;
    padding: 30px 30px 15px;
    max-width: 440px;
    width: 100%;
    box-shadow: 0 0 20px rgb(0 0 0 / 10%);
    font-size: 13px;
    position: relative;
    z-index: 10;
    background-color: white;

    @include for-768 {
      padding: 15px;
    }

    &-cols {
      display: flex;
    }

    &-col {
      width: 50%;
      display: flex;
      flex-direction: column;
    }

    &-link {
      margin-bottom: 10px;
      cursor: pointer;
    }

    &-text {
      max-height: 300px;
      overflow: scroll;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      z-index: 100;
      padding: 15px 30px 10px;
      background-color: $krona-green;
      height: auto;
      color: white;
      // display: none;
      top: 0;
      opacity: 0;
      z-index: -1;
      transition-duration: .3s;

      .btn {
        background-color: white;
        color: $krona-green;
        font-weight: bold;
        @include PTSansNarrow-font;
        margin-bottom: 10px;
        display: inline-block;
        box-shadow: none;

        &:hover {
          background-color: $krona-gray;
        }
      }

      h2 {
        margin-top: 0;
        margin-bottom: 10px;
      }

      &-cross {
        @extend .modal__cross;
      }

      &--active {
        top: 100%;
        opacity: 1;
        z-index: 1;
        transition-duration: .3s;
      }
    }
  }
}

.contacts-disclaimer {
  max-width: 760px;
  margin: 0 auto;
  padding-bottom: 40px;

  @include for-768 {
    font-size: 14px;
  }
}
.main-blog {

}


.main-blog-item {
  font-size: 14px;

  .page-title {
    margin-bottom: 0;
  }
}

.article-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.article-item {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 0 15px 30px;
  background-color: $krona-gray--light;
  transition-duration: .3s;

  &:hover {
    transition-duration: .3s;
    box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  }

  &-image {
    @include bg--cover;
    background-color: $krona-green;
    width: 100%;
    height: 210px;
  }

  &-title {
    font-size: 14px;
    border: 10px solid transparent;
    min-height: 70px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.article-text {
  line-height: 1.7;
  margin-bottom: 30px;
}

.article-recomandations {
  display: flex;
  margin: 0 -20px 20px;
  flex-wrap: wrap;

  &-title {
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    @include PTSansNarrow-font;
    text-transform: uppercase;
    width: 100%;
    margin: 0 20px 20px;
  }

  &-item {
    margin: 0 20px 20px;
    width: calc(50% - 40px);
    height: 150px;
    padding: 30px;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    font-weight: bold;
    @include PTSansNarrow-font;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    &-title {
      font-size: 18px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    &-price {
      font-size: 24px;
      color: $krona-green;
    }

    &-image {
      @include bg--contain;
      background-color: $krona-green;
      position: absolute;
      width: 150px;
      height: 150px;
      top: 0;
      right: 0;
    }
  }
}
.form-callback {
  width: 100%;
  padding: 60px 0 40px;
  @include bg--cover;
  background-image: url('../assets/images/index/call-action-home.webp');

  &--product {
    background-image: url('../assets/images/call-action-skud.webp');
  }

  .fc-title {
    text-align: center;
    margin-bottom: 60px;

    span {
      font-size: 30px;
      position: relative;
      font-weight: bold;
      color: white;

      &::before {
        content: "";
        position: absolute;
        width: 8px;
        height: 40px;
        background-color: #fff;
        opacity: .5;
        transform: skew(-30deg);
        left: -30px;
      }
  
      &::after {
        content: "";
        position: absolute;
        width: 8px;
        height: 40px;
        background-color: #fff;
        opacity: .5;
        transform: skew(-30deg);
        right: -30px;
      }
    }
  }

  .fc-subtitle {
    text-align: center;
    margin-bottom: 20px;
    color: white;
  }

  .fc-form {
    display: flex;
    flex-direction: column;

    .fc-form-block {
      display: flex;
      margin-bottom: 20px;
      justify-content: center;

      @include for-768 {
        flex-wrap: wrap;
      }

      &:nth-child(2) {
        flex-direction: column;
      }

      .form-control {
        margin: 0 auto;
      }
    }

    .fc-input {
      width: 260px;
      border-radius: 0;
      padding: 11px 20px;
      margin: 0 15px 15px;
      border: none;
      font-size: 15px;
      box-shadow: 0 0 10px rgb(0 0 0 / 20%);
      border-radius: 4px;
      border: 1px solid transparent;

      &--unvalid {
        border: 1px solid rgb(186, 61, 61);
      }
    }

    .fc-submit {
      @include btn--standart;
      @include PTSansNarrow-font;
      font-weight: bold;
      background-color: $krona-green;
      padding: 11px 20px 10px;
      transition-duration: .3s;
      color: white;
      margin: 20px auto;
      border-radius: 4px;

      &:hover {
        background-color: $krona-green--dark;
        transition-duration: .3s;
      }

      &:disabled {
        opacity: .75;
        pointer-events: none;
      }
    }

    .fc-agreement {
      color: white;
      text-align: center;
    }

    .fc-loader {
      margin: 10px auto;
      text-align: center;
      color: white;
    }
  }

  .fc-message {
    text-align: center;
    max-width: 260px;
    width: 100%;
    margin: 0 auto 20px;
    border-radius: 4px;
    background-color: white;
    padding: 8px;
    display: none;

    &--success {
      color: $krona-green;
      border: 1px solid $krona-green;
      display: block;
    }

    &--error {
      color: rgb(241, 62, 62);
      border: 1px solid rgb(241, 62, 62);
      display: block;
    }
  }

  .fc-checkbox {
    &--label {
      color: white;
    }
  }
}

#fc-form--layout {

  .fc-form-block {
    margin-bottom: 0;
  }
}